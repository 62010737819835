import React from "react";
import "./LanguageSelector.scss";
import { useTranslation } from "react-i18next";

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  return (
    <div className={"lang-selector"}>
      <div className="sl-nav">
        <ul>
          <li>
            <span className={"current-language"}>
              {i18n.language}
              <div className="arrow-down"></div>
            </span>
            <div className="triangle"></div>
            <ul>
              <li>
                <span
                  onClick={() => changeLanguage("de")}
                  className={"language de"}
                >
                  Deutsch
                </span>
              </li>
              <li>
                <span
                  onClick={() => changeLanguage("lv")}
                  className={"language lv"}
                >
                  Latviešu
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
