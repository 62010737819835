import React from "react";
import "./ImageSlider.scss";

export default function ImageSlider(props) {
    function getRandomInt(max) {
        return Math.floor(Math.random() * max) + 1;
    }
    const maxImageNumber = 3;

    let sliderImage = props.image;
    if (props.image === "random") {
        sliderImage = "image-slider-" + getRandomInt(maxImageNumber);
    }
    return (
        <div className={"image-slider-container"}>
            <div className={"image-slider"}>
                <div className={`slide ${sliderImage}`}></div>
            </div>
            <h1 className={"main-title"}>{props.title}</h1>
        </div>
    );
}
