import React from "react";
import "./Card.scss";
import IlzeImage from "../../assets/images/Ilze_Schorderet.jpg";

export default function Card() {
    return (
        <div className={"card-container"}>
            <div className={"card-image"}>
                <img src={IlzeImage} alt={"Ilze Schorderet"} />
            </div>
            <div className={"card-text-content"}>
                <div className={"image-text"}>
                    lic. phil. <span>Ilze Schorderet</span>
                </div>
                <div className={"card-text"}>
                    <a
                        href={"mailto:ilscho@vtxnet.ch"}
                        title={"ilscho@vtxnet.ch"}
                    >
                        ilscho@vtxnet.ch
                    </a>
                    <a
                        href={"mailto:schorderet@inbox.lv"}
                        title={"schorderet@inbox.lv"}
                    >
                        Schorderet@inbox.lv
                    </a>
                    <br/>
                    078 648 86 37
                </div>
            </div>
        </div>
    );
}
