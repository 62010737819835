import React from "react";

const LogoFr = () => {
    return (
        <div className="LogoFr">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.42 59.19">
                <path
                    d="M64.14,43.77a3.78,3.78,0,0,1-1.64-1.53,3.5,3.5,0,0,1-.34-1.35A9.73,9.73,0,0,1,63,37.53q.84-2.12,2.25-4.85c1-1.83,2-3.73,3.23-5.71-.89-.07-1.74-.11-2.54-.14s-1.83,0-3.09,0h-.69a4,4,0,0,1,1.91-2.34,6.65,6.65,0,0,1,3.07-.68,11.66,11.66,0,0,1,1.53.1l1.65.18q2.16-3.34,4.24-6.31c1.39-2,2.7-3.77,4-5.36s2.36-2.91,3.33-3.93c1.38-1.48,2.38-2.23,3-2.23a2.5,2.5,0,0,1,1.35.52A11.45,11.45,0,0,1,87.77,8a6,6,0,0,0-1.1,1.9Q86,11.46,85.26,13.8t-1.51,5.13Q83,21.72,82.4,24.6t-1,5.5a34.5,34.5,0,0,0-.36,4.62,8.24,8.24,0,0,0,.34,2.76,1.2,1.2,0,0,0,1.16.9c.66,0,1.48-.54,2.45-1.62a24.73,24.73,0,0,0,3.27-5.29l.77.88a19.13,19.13,0,0,1-3.46,6.27,6,6,0,0,1-4.53,2.46,3,3,0,0,1-2.92-1.93,13.06,13.06,0,0,1-.86-5.27c0-.71,0-1.51.12-2.41s.23-2,.43-3.34c-1.26-.22-2.37-.39-3.35-.51l-2.79-.38q-2.07,4-4,8.17T64.14,43.77ZM78.27,25.85c.38-1.82.81-3.67,1.31-5.55s1-3.66,1.59-5.38,1.11-3.26,1.66-4.62A21.39,21.39,0,0,0,80,13.5q-1.59,2.1-3.33,4.92T73.07,24.6c.86.18,1.72.39,2.58.6Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M90.35,41.12A2.74,2.74,0,0,1,88,39.66,6.18,6.18,0,0,1,87,36.3a14.53,14.53,0,0,1,.39-3.07,23.71,23.71,0,0,1,1-3.43A16,16,0,0,1,89.73,27c.44-.74.85-1.11,1.22-1.11a2.09,2.09,0,0,1,.92.35,4.37,4.37,0,0,1,1.1.85,16.92,16.92,0,0,0-1.14,2.28,27.67,27.67,0,0,0-1.18,3.3,11.62,11.62,0,0,0-.52,3.2c0,.12,0,.42.07.88a4.28,4.28,0,0,0,.32,1.3.91.91,0,0,0,.9.6A2.3,2.3,0,0,0,93,37.8a14.2,14.2,0,0,0,1.72-2.22c.59-.92,1.16-1.9,1.72-2.95s1.06-2.07,1.5-3.06.81-1.83,1.1-2.51a6.75,6.75,0,0,1,1,.23,4.19,4.19,0,0,1,1.09.49,1,1,0,0,1,.48.86,6.88,6.88,0,0,1-.41,1.13c-.27.67-.59,1.47-1,2.4S99.58,34,99.31,35a9.79,9.79,0,0,0-.41,2.58,2.51,2.51,0,0,0,.24,1.13.91.91,0,0,0,.92.49,3.93,3.93,0,0,0,2.15-.88,13.35,13.35,0,0,0,2.58-2.55,18.85,18.85,0,0,0,2.36-4l.64.56a18,18,0,0,1-2.44,4.5A13.42,13.42,0,0,1,102.12,40a6,6,0,0,1-3.35,1.13,2.53,2.53,0,0,1-1.87-.62,3.3,3.3,0,0,1-.84-1.63,11.38,11.38,0,0,1-.25-2v-.93c-.49.84-1,1.65-1.59,2.44a10.61,10.61,0,0,1-1.83,2A3.16,3.16,0,0,1,90.35,41.12Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M111.06,42.1a5.79,5.79,0,0,1-3.46-1,5.28,5.28,0,0,1-1.87-2.42,8.34,8.34,0,0,1-.56-3,5.09,5.09,0,0,1,.35-2.09,2.55,2.55,0,0,1,.86-1.09,5.2,5.2,0,0,1,1-.53,2,2,0,0,0,.67-.42,35,35,0,0,0,2.21-2.81,18.63,18.63,0,0,0,2-3.55v-.47a4.45,4.45,0,0,1,.58-2.62,1.68,1.68,0,0,1,1.35-.77.93.93,0,0,1,.75.28,1.23,1.23,0,0,1,.28.65,2.34,2.34,0,0,1-.1.65,3.51,3.51,0,0,0-.11,1,9.66,9.66,0,0,0,.62,3.18c.41,1.2.83,2.46,1.25,3.79a13.78,13.78,0,0,1,.62,4.08c0,.37,0,.71,0,1s-.08.61-.13.89a6.31,6.31,0,0,0,2.62-1.61,12.47,12.47,0,0,0,2.45-3.64l.51.42a10.43,10.43,0,0,1-2.36,4.25,6.58,6.58,0,0,1-3.61,2,5.23,5.23,0,0,1-2.25,2.83A6.84,6.84,0,0,1,111.06,42.1Zm-.3-2a2.92,2.92,0,0,0,1.59-.47,3.25,3.25,0,0,0,1.16-1.34,8.53,8.53,0,0,1-3.18-1.49,9.61,9.61,0,0,1-2.19-2.18c-.4.09-.6.51-.6,1.25a5.51,5.51,0,0,0,.81,2.88A2.65,2.65,0,0,0,110.76,40.06Zm3.09-3.21a3.22,3.22,0,0,0,.11-.76c0-.3,0-.52,0-.68a20.55,20.55,0,0,0-.51-4.13c-.32-1.36-.63-2.75-1-4.18-.51,1-1.12,2-1.8,3.11a19.93,19.93,0,0,1-1.94,2.7,8.57,8.57,0,0,0,2,2.37A8,8,0,0,0,113.85,36.85Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M126.1,41.77a5.47,5.47,0,0,1-4.23-1.6,5.67,5.67,0,0,1-1.49-4,11.3,11.3,0,0,1,1-4.34A17.93,17.93,0,0,1,124,27.5a14.74,14.74,0,0,1,3.61-3.27A7.32,7.32,0,0,1,131.51,23a3.94,3.94,0,0,1,2.13.63,2.71,2.71,0,0,1,1,2.48,6.16,6.16,0,0,1-.94,3.3,10.24,10.24,0,0,1-2.54,2.74,15.18,15.18,0,0,1-3.5,2,15.52,15.52,0,0,1-3.85,1,2.28,2.28,0,0,0-.06.39,6,6,0,0,0,0,.68q0,.23.06.81a3.9,3.9,0,0,0,.37,1.23,2.79,2.79,0,0,0,1,1.11,3.34,3.34,0,0,0,1.89.47,7.12,7.12,0,0,0,3.64-1.09,15,15,0,0,0,3.43-2.9,17.56,17.56,0,0,0,2.69-4l.86.74a17.41,17.41,0,0,1-3,4.87,13.69,13.69,0,0,1-4,3.25A9.54,9.54,0,0,1,126.1,41.77Zm-1.81-8.4a7.78,7.78,0,0,0,2.5-.83,14.51,14.51,0,0,0,2.64-1.77,10.35,10.35,0,0,0,2.08-2.34,4.7,4.7,0,0,0,.82-2.58,1,1,0,0,0-.17-.69.72.72,0,0,0-.56-.19,3.5,3.5,0,0,0-2.06.77,11.41,11.41,0,0,0-2.11,2,17.1,17.1,0,0,0-1.85,2.72A18,18,0,0,0,124.29,33.37Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M149.39,41.5a3.27,3.27,0,0,1-2.32-.93,6.93,6.93,0,0,1-1.59-2.32,14.6,14.6,0,0,1-.9-2.86,12.82,12.82,0,0,1-.3-2.53q.6-.13,1.5-.42a11.3,11.3,0,0,0,1.85-.76,5.34,5.34,0,0,0,1.57-1.21,2.59,2.59,0,0,0,.62-1.74,1.9,1.9,0,0,0-.49-1.35,1.87,1.87,0,0,0-1.4-.51,3.63,3.63,0,0,0-2.28.86,9.66,9.66,0,0,0-2,2.25A23.54,23.54,0,0,0,142,33c-.5,1.08-.95,2.14-1.34,3.16s-.7,1.9-1,2.64a6.15,6.15,0,0,1-.9,1.88,1.58,1.58,0,0,1-1.25.49,1.68,1.68,0,0,1-.71-.19,1.56,1.56,0,0,1-.69-.92,7.51,7.51,0,0,1-.28-2.37,30.16,30.16,0,0,1,.73-6.22,57.39,57.39,0,0,1,2-6.89q1.23-3.51,2.68-6.71a63.72,63.72,0,0,1,3-5.76,26.81,26.81,0,0,1,2.81-4c.88-1,1.6-1.48,2.17-1.48s.9.31,1.23,1a5.24,5.24,0,0,1,.49,2.44,11.08,11.08,0,0,1-.64,3.5,20.06,20.06,0,0,1-1.83,3.9,22.58,22.58,0,0,1-2.81,3.74,18.11,18.11,0,0,1-3.61,3,38.5,38.5,0,0,0-1.85,6,39.22,39.22,0,0,0-.82,5.22q.78-1.91,1.77-3.9a29.81,29.81,0,0,1,2.19-3.74,12.17,12.17,0,0,1,2.6-2.8,4.82,4.82,0,0,1,3-1.07,4.09,4.09,0,0,1,3,1.3,4.43,4.43,0,0,1,1.29,3.25,4.19,4.19,0,0,1-.69,2.41,6.22,6.22,0,0,1-1.69,1.7,8,8,0,0,1-2,1,7.55,7.55,0,0,1-1.77.38c.09.4.21.91.37,1.53a10.74,10.74,0,0,0,.62,1.81,4.8,4.8,0,0,0,1,1.48,1.91,1.91,0,0,0,1.42.61,3.34,3.34,0,0,0,2.21-1.07,14.4,14.4,0,0,0,2.24-2.83,25.34,25.34,0,0,0,1.95-3.9l.6.79a20.86,20.86,0,0,1-2,4.45,13.46,13.46,0,0,1-2.81,3.46A4.88,4.88,0,0,1,149.39,41.5ZM143.24,21a26.43,26.43,0,0,0,2.45-2.77,20.07,20.07,0,0,0,2.22-3.52,8.81,8.81,0,0,0,1-3.6V11a5,5,0,0,0-1.35,1.6,38.52,38.52,0,0,0-2,3.6C144.73,17.6,144,19.21,143.24,21Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M159.32,41.12a2.92,2.92,0,0,1-2.82-1.46,7.13,7.13,0,0,1-.79-3.46,22,22,0,0,1,.77-5.33,61.26,61.26,0,0,1,2-6.43c.85-2.25,1.78-4.44,2.79-6.6s2-4.09,3.08-5.82a31.88,31.88,0,0,1,2.85-4.16c.88-1,1.59-1.55,2.13-1.55a1.13,1.13,0,0,1,.95.62,6.18,6.18,0,0,1,.62,1.49,6,6,0,0,1,.24,1.6,12.67,12.67,0,0,1-.58,3.27A37.13,37.13,0,0,1,169,17.73a50.84,50.84,0,0,1-2.49,4.94,42.58,42.58,0,0,1-3.18,4.81,25.09,25.09,0,0,1-3.72,3.94c-.11.78-.22,1.54-.32,2.3a16,16,0,0,0-.15,2.07,4.39,4.39,0,0,0,.52,2.43A1.54,1.54,0,0,0,161,39a3.33,3.33,0,0,0,2.21-1.09,14.26,14.26,0,0,0,2.19-2.79,27.12,27.12,0,0,0,1.83-3.5l1,.6a22.81,22.81,0,0,1-4.13,6.52A6.56,6.56,0,0,1,159.32,41.12Zm1-12.58a37.6,37.6,0,0,0,2.77-3.73q1.31-2,2.41-4.11c.73-1.39,1.36-2.74,1.91-4a36.21,36.21,0,0,0,1.27-3.51,11.32,11.32,0,0,0,.49-2.48v-.14c-.23,0-.6.35-1.1,1.05a32.47,32.47,0,0,0-1.78,2.85q-1,1.82-2.13,4.13t-2.1,4.9Q161.07,26,160.35,28.54Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M169.58,41.12a3.38,3.38,0,0,1-2.83-1.2A4.62,4.62,0,0,1,165.8,37a13.47,13.47,0,0,1,.35-2.69,29.16,29.16,0,0,1,1-3.41,27.76,27.76,0,0,1,1.44-3.37A11.31,11.31,0,0,1,170.32,25a2.66,2.66,0,0,1,1.89-1,1.28,1.28,0,0,1,.9.35,1.25,1.25,0,0,1,.39,1,4.35,4.35,0,0,1-.67,1.81c-.45.81-1,1.74-1.55,2.79a27.12,27.12,0,0,0-1.54,3.27,8.73,8.73,0,0,0-.67,3.23,2.82,2.82,0,0,0,.49,2,2,2,0,0,0,1.44.49,4.92,4.92,0,0,0,3.18-1.58,17.92,17.92,0,0,0,3.57-5.52l.64.74a17,17,0,0,1-3.82,6.36A7,7,0,0,1,169.58,41.12Zm5.08-20.93a2.68,2.68,0,0,1-1.49-.44,1.54,1.54,0,0,1-.66-1.37,2.3,2.3,0,0,1,1.11-1.91,4,4,0,0,1,2.32-.78,2.25,2.25,0,0,1,1.32.37,1.52,1.52,0,0,1,.53,1.34,2.57,2.57,0,0,1-1,1.88A3.09,3.09,0,0,1,174.66,20.19Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M181.66,42.1a5.79,5.79,0,0,1-3.46-1,5.28,5.28,0,0,1-1.87-2.42,8.34,8.34,0,0,1-.56-3,5.09,5.09,0,0,1,.35-2.09,2.55,2.55,0,0,1,.86-1.09,5.2,5.2,0,0,1,1-.53,2.15,2.15,0,0,0,.67-.42,35,35,0,0,0,2.21-2.81,18.63,18.63,0,0,0,2-3.55v-.47a4.45,4.45,0,0,1,.58-2.62,1.66,1.66,0,0,1,1.35-.77,1,1,0,0,1,.75.28,1.23,1.23,0,0,1,.28.65,2.06,2.06,0,0,1-.11.65,4,4,0,0,0-.1,1,9.66,9.66,0,0,0,.62,3.18c.41,1.2.83,2.46,1.25,3.79A13.78,13.78,0,0,1,188,35c0,.37,0,.71,0,1s-.08.61-.13.89a6.31,6.31,0,0,0,2.62-1.61,12.47,12.47,0,0,0,2.45-3.64l.51.42a10.43,10.43,0,0,1-2.36,4.25,6.58,6.58,0,0,1-3.61,2,5.2,5.2,0,0,1-2.26,2.83A6.78,6.78,0,0,1,181.66,42.1Zm-.3-2a2.92,2.92,0,0,0,1.59-.47,3.25,3.25,0,0,0,1.16-1.34,8.46,8.46,0,0,1-3.18-1.49,9.61,9.61,0,0,1-2.19-2.18c-.4.09-.6.51-.6,1.25a5.51,5.51,0,0,0,.81,2.88A2.65,2.65,0,0,0,181.36,40.06Zm3.09-3.21a3.84,3.84,0,0,0,.11-.76c0-.3,0-.52,0-.68a20.55,20.55,0,0,0-.51-4.13c-.32-1.36-.63-2.75-.95-4.18-.52,1-1.12,2-1.8,3.11a19.93,19.93,0,0,1-1.94,2.7,8.57,8.57,0,0,0,2,2.37A8,8,0,0,0,184.45,36.85Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M4,20.65H22.28l7.31,7.89H11.32L4,20.65Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M4,40.38l7.3-7.89H29.59l-7.31,7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,32.49H51.5l7.31,7.89H40.55l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M40.55.92V20.65l-7.31,7.89V8.81L40.55.92Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M29.59,32.49V52.22l-7.31,7.89V40.38l7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,28.54l7.31-7.89H58.81L51.5,28.54Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M22.28.92l7.31,7.89V28.54l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,32.49l7.31,7.89V60.11l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M44,47.34h4.16l.48,1.35-.32.12a2.28,2.28,0,0,0-.26-.52,1.53,1.53,0,0,0-.35-.32,1.1,1.1,0,0,0-.4-.18,5.47,5.47,0,0,0-.74,0H45.28v1.88h.62a.93.93,0,0,0,.52-.12.67.67,0,0,0,.24-.29,1.44,1.44,0,0,0,.05-.45v-.08h.36v2.25h-.36v-.2a1.14,1.14,0,0,0,0-.37.55.55,0,0,0-.14-.21.54.54,0,0,0-.22-.14,1.78,1.78,0,0,0-.41,0h-.62v1.67a1.59,1.59,0,0,0,0,.31.24.24,0,0,0,.08.09,1.53,1.53,0,0,0,.3,0h.77a2.72,2.72,0,0,0,1-.15,1.83,1.83,0,0,0,.64-.48,2.75,2.75,0,0,0,.41-.7l.33.12-.57,1.6H44v-.3h.15a.89.89,0,0,0,.32,0,.29.29,0,0,0,.14-.15.73.73,0,0,0,0-.32V48.12a.62.62,0,0,0-.05-.32.3.3,0,0,0-.16-.12,1.66,1.66,0,0,0-.44,0Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M53.16,51.44l.24.19a2,2,0,0,1-1.66.92A1.73,1.73,0,0,1,50.47,52a2,2,0,0,1-.28-2.29,1.7,1.7,0,0,1,.67-.66,2,2,0,0,1,1-.24,1.8,1.8,0,0,1,1.06.31.85.85,0,0,1,.42.63.33.33,0,0,1-.32.35.28.28,0,0,1-.13,0,.42.42,0,0,1-.13-.11.92.92,0,0,1-.11-.2,1.2,1.2,0,0,0-.15-.29.89.89,0,0,0-.28-.23.78.78,0,0,0-.37-.09,1,1,0,0,0-.58.18,1.08,1.08,0,0,0-.42.51,1.78,1.78,0,0,0-.16.8,1.7,1.7,0,0,0,.38,1.15,1.07,1.07,0,0,0,.8.36,1.29,1.29,0,0,0,.43-.07,1.65,1.65,0,0,0,.42-.21A3.47,3.47,0,0,0,53.16,51.44Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M56.32,48.83a1.83,1.83,0,0,1,.93.23,1.58,1.58,0,0,1,.63.65,2.25,2.25,0,0,1,0,2,1.63,1.63,0,0,1-.64.66,1.89,1.89,0,0,1-1,.22A1.6,1.6,0,0,1,54.94,52a2,2,0,0,1-.45-1.31,2,2,0,0,1,.24-1,1.6,1.6,0,0,1,.64-.65A1.89,1.89,0,0,1,56.32,48.83Zm0,.36a.91.91,0,0,0-.71.33,1.76,1.76,0,0,0-.37,1.2,1.83,1.83,0,0,0,.32,1.15.92.92,0,0,0,.76.35,1,1,0,0,0,.56-.16,1.17,1.17,0,0,0,.39-.53,2.19,2.19,0,0,0,.15-.85,2.16,2.16,0,0,0-.16-.86,1,1,0,0,0-.37-.46A1,1,0,0,0,56.29,49.19Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M59.27,47.69v-.3a2.39,2.39,0,0,0,1-.31h.27V51.6a1.27,1.27,0,0,0,0,.36.34.34,0,0,0,.14.15,1,1,0,0,0,.36,0h.11v.3H59.27v-.3h.19a.49.49,0,0,0,.27-.06.31.31,0,0,0,.13-.15,1.06,1.06,0,0,0,0-.38V47.69Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M65.35,51.52l.23.19a2.67,2.67,0,0,1-.52.5,1.53,1.53,0,0,1-.47.27,1.66,1.66,0,0,1-.53.07,1.73,1.73,0,0,1-.91-.22,1.84,1.84,0,0,1-.67-.66,2,2,0,0,1-.23-1,2,2,0,0,1,.23-1,1.55,1.55,0,0,1,.62-.65,1.79,1.79,0,0,1,.93-.24,1.59,1.59,0,0,1,.68.13,1.21,1.21,0,0,1,.5.37,1.57,1.57,0,0,1,.29.56,2.84,2.84,0,0,1,.06.49H63c0,.12,0,.2,0,.26a2.15,2.15,0,0,0,.16.83,1.16,1.16,0,0,0,.43.53,1.06,1.06,0,0,0,.61.17A1.2,1.2,0,0,0,64.7,52,2.74,2.74,0,0,0,65.35,51.52ZM64.88,50a1.46,1.46,0,0,0-.16-.5.69.69,0,0,0-.28-.25.87.87,0,0,0-.42-.1.91.91,0,0,0-.43.1.88.88,0,0,0-.34.26A1.4,1.4,0,0,0,63,50Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M72.09,47.7v-.31a3,3,0,0,0,.45-.09,3.75,3.75,0,0,0,.52-.22h.28v4.45a3.06,3.06,0,0,0,0,.38.34.34,0,0,0,.13.19.66.66,0,0,0,.34.06H74v.3l-1.28.06v-.58a2.42,2.42,0,0,1-.69.47,1.48,1.48,0,0,1-.64.14,1.38,1.38,0,0,1-1.1-.53,1.94,1.94,0,0,1-.44-1.29,2.14,2.14,0,0,1,.23-1,1.71,1.71,0,0,1,.62-.68,1.52,1.52,0,0,1,.83-.24,1.54,1.54,0,0,1,.65.13,1.6,1.6,0,0,1,.54.36V47.7Zm.61,3.88V50.19a.82.82,0,0,0-.48-.82,1.1,1.1,0,0,0-.52-.14,1,1,0,0,0-.59.18,1.1,1.1,0,0,0-.42.5,1.9,1.9,0,0,0-.15.8,1.81,1.81,0,0,0,.14.76,1.18,1.18,0,0,0,.38.49.84.84,0,0,0,.49.16A1.24,1.24,0,0,0,72.1,52,2.24,2.24,0,0,0,72.7,51.58Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M78.24,51.52l.24.19a3.39,3.39,0,0,1-.52.5,1.69,1.69,0,0,1-.48.27,1.64,1.64,0,0,1-.52.07,1.77,1.77,0,0,1-.92-.22,1.84,1.84,0,0,1-.67-.66,2,2,0,0,1-.23-1,2,2,0,0,1,.23-1,1.69,1.69,0,0,1,.62-.65,1.79,1.79,0,0,1,.93-.24,1.63,1.63,0,0,1,.69.13,1.25,1.25,0,0,1,.49.37,1.57,1.57,0,0,1,.29.56,1.68,1.68,0,0,1,.06.49H75.86c0,.12,0,.2,0,.26a2,2,0,0,0,.17.83,1.15,1.15,0,0,0,.42.53,1.06,1.06,0,0,0,.61.17,1.2,1.2,0,0,0,.55-.13A2.56,2.56,0,0,0,78.24,51.52ZM77.77,50a1.76,1.76,0,0,0-.15-.5.79.79,0,0,0-.29-.25.82.82,0,0,0-.42-.1.91.91,0,0,0-.43.1.88.88,0,0,0-.34.26,1.6,1.6,0,0,0-.23.49Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M82.65,47.69v-.3a2.35,2.35,0,0,0,1-.31h.28V51.6a1.27,1.27,0,0,0,0,.36.29.29,0,0,0,.14.15.88.88,0,0,0,.35,0h.12v.3H82.65v-.3h.19a.49.49,0,0,0,.27-.06.37.37,0,0,0,.13-.15,1.06,1.06,0,0,0,0-.38V47.69Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M88.23,50.34v-.4a.68.68,0,0,0-.23-.58,1,1,0,0,0-.65-.18,1.36,1.36,0,0,0-.36,0,.57.57,0,0,0-.24.14.43.43,0,0,0-.14.19l-.09.32c-.06.22-.17.33-.34.33a.33.33,0,0,1-.24-.1.37.37,0,0,1-.09-.26.8.8,0,0,1,.18-.48,1.31,1.31,0,0,1,.55-.38,2.14,2.14,0,0,1,.87-.16,2,2,0,0,1,.81.15,1.05,1.05,0,0,1,.49.41,1.57,1.57,0,0,1,.11.69v1.41a1.75,1.75,0,0,0,0,.39.39.39,0,0,0,.14.24.4.4,0,0,0,.26.08,1.34,1.34,0,0,0,.4-.08v.32a1.9,1.9,0,0,1-.54.09,1.12,1.12,0,0,1-.44-.08.7.7,0,0,1-.44-.55,3.45,3.45,0,0,1-.8.49,2,2,0,0,1-.72.14,1.23,1.23,0,0,1-.82-.28.83.83,0,0,1-.31-.65.8.8,0,0,1,.11-.42,1.19,1.19,0,0,1,.35-.36,2.61,2.61,0,0,1,.75-.27A11.47,11.47,0,0,1,88.23,50.34Zm0,.32c-.56.08-.92.14-1,.17a1.9,1.9,0,0,0-.57.2.72.72,0,0,0-.25.24.5.5,0,0,0-.07.27.6.6,0,0,0,.08.28.58.58,0,0,0,.23.21.71.71,0,0,0,.32.07,1.44,1.44,0,0,0,.54-.11,3.85,3.85,0,0,0,.77-.44Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M90.59,49.45v-.31a2,2,0,0,0,.49-.09,2.2,2.2,0,0,0,.46-.22h.27v.7a3.53,3.53,0,0,1,.82-.55,1.73,1.73,0,0,1,.7-.15,1.47,1.47,0,0,1,.53.1,1,1,0,0,1,.37.28.81.81,0,0,1,.2.39,2.07,2.07,0,0,1,0,.41v1.62a1,1,0,0,0,0,.37.3.3,0,0,0,.13.12,1.74,1.74,0,0,0,.45,0v.3H93.2v-.3h.1a.91.91,0,0,0,.36,0A.32.32,0,0,0,93.8,52a1.06,1.06,0,0,0,0-.38V50.24a1.57,1.57,0,0,0-.07-.58.57.57,0,0,0-.23-.26.76.76,0,0,0-.41-.1,1.6,1.6,0,0,0-.64.15,2.38,2.38,0,0,0-.65.45v1.69a1.13,1.13,0,0,0,0,.39.32.32,0,0,0,.14.14,1,1,0,0,0,.35,0h.11v.3h-1.9v-.3h.1a1,1,0,0,0,.37,0,.28.28,0,0,0,.14-.16,2.57,2.57,0,0,0,0-.41V49.45Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M99,49.21a1.17,1.17,0,0,1,.33-.28.62.62,0,0,1,.32-.1.35.35,0,0,1,.22.08.25.25,0,0,1,.09.2.27.27,0,0,1-.08.19.28.28,0,0,1-.21.08h-.14l-.17,0a.34.34,0,0,0-.19.08,1,1,0,0,1,.23.65A1.15,1.15,0,0,1,99,51a1.56,1.56,0,0,1-1.08.37,1.86,1.86,0,0,1-.72-.15l-.28.21a.24.24,0,0,0,0,.13.22.22,0,0,0,.11.18,1.56,1.56,0,0,0,.55.15L98.6,52a4.5,4.5,0,0,1,.7.18,1.15,1.15,0,0,1,.52.34A.76.76,0,0,1,100,53a.9.9,0,0,1-.45.78,3.18,3.18,0,0,1-1.69.37,3.06,3.06,0,0,1-1-.13,1.23,1.23,0,0,1-.57-.35.5.5,0,0,1-.15-.34.81.81,0,0,1,.15-.45,3.28,3.28,0,0,1,.69-.61,1.4,1.4,0,0,1-.5-.21.42.42,0,0,1-.19-.34.52.52,0,0,1,0-.18.77.77,0,0,1,.12-.17l.28-.23.19-.13a1.34,1.34,0,0,1-.4-.43,1,1,0,0,1-.14-.52,1.09,1.09,0,0,1,.36-.81,1.64,1.64,0,0,1,1.18-.43,1.72,1.72,0,0,1,.58.09A1.68,1.68,0,0,1,99,49.21Zm-1.71,3.15a2.13,2.13,0,0,0-.46.49.65.65,0,0,0-.12.36.45.45,0,0,0,.11.28.88.88,0,0,0,.4.23,2.33,2.33,0,0,0,.7.09,3,3,0,0,0,.86-.11,1.1,1.1,0,0,0,.51-.28.45.45,0,0,0,.12-.31.35.35,0,0,0-.1-.26.85.85,0,0,0-.35-.19,3.26,3.26,0,0,0-.48-.11Zm.59-3.19a.82.82,0,0,0-.61.26.9.9,0,0,0-.25.65,1,1,0,0,0,.11.46.85.85,0,0,0,.32.33.86.86,0,0,0,.44.12.85.85,0,0,0,.43-.12.78.78,0,0,0,.32-.33.9.9,0,0,0,.11-.46.88.88,0,0,0-.24-.65A.83.83,0,0,0,97.91,49.17Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M100.69,48.92l1.39-.05v2.36a1.37,1.37,0,0,0,.08.5.66.66,0,0,0,.27.28.78.78,0,0,0,.41.1,1.67,1.67,0,0,0,.67-.16,2.62,2.62,0,0,0,.74-.47V49.77a.87.87,0,0,0,0-.35.27.27,0,0,0-.14-.14.85.85,0,0,0-.32-.05h-.27v-.31l1.39-.05V51.6a1.07,1.07,0,0,0,0,.37.3.3,0,0,0,.13.14.88.88,0,0,0,.35,0h.11v.3l-1.21.07v-.68a4,4,0,0,1-.88.55,2,2,0,0,1-.76.15,1.28,1.28,0,0,1-.5-.08,1,1,0,0,1-.37-.23.85.85,0,0,1-.24-.37,1.18,1.18,0,0,1-.09-.46V49.74a.76.76,0,0,0,0-.31.31.31,0,0,0-.13-.15.6.6,0,0,0-.26-.05h-.33Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M109.78,51.52l.23.19a3,3,0,0,1-.52.5,1.53,1.53,0,0,1-.47.27,1.66,1.66,0,0,1-.53.07,1.73,1.73,0,0,1-.91-.22,1.84,1.84,0,0,1-.67-.66,2,2,0,0,1-.23-1,2,2,0,0,1,.23-1,1.55,1.55,0,0,1,.62-.65,1.79,1.79,0,0,1,.93-.24,1.59,1.59,0,0,1,.68.13,1.21,1.21,0,0,1,.5.37,1.57,1.57,0,0,1,.29.56,2.84,2.84,0,0,1,.06.49h-2.6c0,.12,0,.2,0,.26a2.15,2.15,0,0,0,.16.83A1.16,1.16,0,0,0,108,52a1.06,1.06,0,0,0,.61.17,1.2,1.2,0,0,0,.55-.13A2.74,2.74,0,0,0,109.78,51.52ZM109.31,50a1.46,1.46,0,0,0-.16-.5.69.69,0,0,0-.28-.25.87.87,0,0,0-.42-.1.91.91,0,0,0-.43.1.88.88,0,0,0-.34.26,1.4,1.4,0,0,0-.23.49Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M117.37,51.52l.23.19a2.67,2.67,0,0,1-.52.5,1.53,1.53,0,0,1-.47.27,1.66,1.66,0,0,1-.53.07,1.75,1.75,0,0,1-.91-.22,1.84,1.84,0,0,1-.67-.66,2,2,0,0,1-.23-1,2,2,0,0,1,.23-1,1.55,1.55,0,0,1,.62-.65,1.79,1.79,0,0,1,.93-.24,1.59,1.59,0,0,1,.68.13,1.21,1.21,0,0,1,.5.37,1.57,1.57,0,0,1,.29.56,2.84,2.84,0,0,1,.06.49H115c0,.12,0,.2,0,.26a2.15,2.15,0,0,0,.16.83,1.16,1.16,0,0,0,.43.53,1.06,1.06,0,0,0,.61.17,1.2,1.2,0,0,0,.55-.13A2.56,2.56,0,0,0,117.37,51.52ZM116.9,50a1.46,1.46,0,0,0-.16-.5.69.69,0,0,0-.28-.25.84.84,0,0,0-.42-.1.91.91,0,0,0-.43.1.88.88,0,0,0-.34.26A1.6,1.6,0,0,0,115,50Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M119.75,47.67H120v1.25h1.09v.37H120V51.5a1,1,0,0,0,0,.41.42.42,0,0,0,.16.19.43.43,0,0,0,.27.08,1.32,1.32,0,0,0,.77-.31v.36a1.75,1.75,0,0,1-1,.32,1.07,1.07,0,0,1-.51-.12.75.75,0,0,1-.31-.3,1.52,1.52,0,0,1-.08-.58V49.29h-.64V49a1.35,1.35,0,0,0,.5-.22,1,1,0,0,0,.3-.38A2.37,2.37,0,0,0,119.75,47.67Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M128.56,51.44l.23.19a2,2,0,0,1-1.65.92,1.71,1.71,0,0,1-1.27-.53,1.85,1.85,0,0,1-.52-1.34,1.89,1.89,0,0,1,.24-1,1.64,1.64,0,0,1,.67-.66,2,2,0,0,1,1-.24,1.76,1.76,0,0,1,1,.31.84.84,0,0,1,.43.63.36.36,0,0,1-.1.26.33.33,0,0,1-.23.09l-.12,0a.33.33,0,0,1-.13-.11.92.92,0,0,1-.11-.2,3.21,3.21,0,0,0-.15-.29.89.89,0,0,0-.28-.23.82.82,0,0,0-.38-.09,1,1,0,0,0-.57.18,1.16,1.16,0,0,0-.43.51,1.94,1.94,0,0,0-.15.8,1.7,1.7,0,0,0,.38,1.15,1.05,1.05,0,0,0,.8.36,1.29,1.29,0,0,0,.43-.07,1.59,1.59,0,0,0,.41-.21A3.36,3.36,0,0,0,128.56,51.44Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M129.66,48.92l1.4-.05v2.36a1.19,1.19,0,0,0,.08.5.66.66,0,0,0,.27.28.76.76,0,0,0,.41.1,1.67,1.67,0,0,0,.67-.16,2.62,2.62,0,0,0,.74-.47V49.77a.87.87,0,0,0,0-.35.27.27,0,0,0-.14-.14.85.85,0,0,0-.32-.05h-.27v-.31l1.39-.05V51.6a1.07,1.07,0,0,0,0,.37.3.3,0,0,0,.13.14.88.88,0,0,0,.35,0h.11v.3l-1.21.07v-.68a4,4,0,0,1-.88.55,2.06,2.06,0,0,1-.76.15,1.28,1.28,0,0,1-.5-.08,1,1,0,0,1-.37-.23.85.85,0,0,1-.24-.37,1.18,1.18,0,0,1-.09-.46V49.74a.76.76,0,0,0,0-.31.37.37,0,0,0-.13-.15.63.63,0,0,0-.26-.05h-.34Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M135.57,47.69v-.3a2.39,2.39,0,0,0,1-.31h.27V51.6a.9.9,0,0,0,0,.36.28.28,0,0,0,.13.15,1,1,0,0,0,.36,0h.11v.3h-1.89v-.3h.19a.55.55,0,0,0,.28-.06.35.35,0,0,0,.12-.15,1.06,1.06,0,0,0,0-.38V47.69Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M139.48,47.67h.26v1.25h1.09v.37h-1.09V51.5a1,1,0,0,0,0,.41.39.39,0,0,0,.17.19.4.4,0,0,0,.26.08,1.3,1.3,0,0,0,.77-.31v.36a1.75,1.75,0,0,1-1,.32,1.07,1.07,0,0,1-.51-.12.75.75,0,0,1-.31-.3,1.52,1.52,0,0,1-.08-.58V49.29h-.64V49a1.35,1.35,0,0,0,.5-.22,1.08,1.08,0,0,0,.31-.38A2.78,2.78,0,0,0,139.48,47.67Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M141.8,48.92l1.4-.05v2.36a1.19,1.19,0,0,0,.08.5.66.66,0,0,0,.27.28.76.76,0,0,0,.41.1,1.67,1.67,0,0,0,.67-.16,2.77,2.77,0,0,0,.74-.47V49.77a.87.87,0,0,0,0-.35.32.32,0,0,0-.14-.14.85.85,0,0,0-.32-.05h-.27v-.31l1.39-.05V51.6a1.07,1.07,0,0,0,0,.37.3.3,0,0,0,.13.14.88.88,0,0,0,.35,0h.11v.3l-1.21.07v-.68a4,4,0,0,1-.88.55,2.06,2.06,0,0,1-.76.15,1.28,1.28,0,0,1-.5-.08,1,1,0,0,1-.37-.23.85.85,0,0,1-.24-.37,1.18,1.18,0,0,1-.09-.46V49.74a.76.76,0,0,0,0-.31.37.37,0,0,0-.13-.15.63.63,0,0,0-.26-.05h-.34Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M147.75,49.45v-.31a1.71,1.71,0,0,0,.48-.09,1.92,1.92,0,0,0,.47-.22H149v.7a1.94,1.94,0,0,1,.6-.51,1.41,1.41,0,0,1,.62-.19.69.69,0,0,1,.43.13.38.38,0,0,1,.16.31.33.33,0,0,1-.1.24.4.4,0,0,1-.26.1l-.11,0-.14-.08a.63.63,0,0,0-.34-.13.74.74,0,0,0-.4.15,2.17,2.17,0,0,0-.44.39v1.69a1,1,0,0,0,0,.3.29.29,0,0,0,.08.14.41.41,0,0,0,.13.08.94.94,0,0,0,.28,0h.13v.3h-1.9v-.3h.16a.85.85,0,0,0,.29,0,.36.36,0,0,0,.14-.16.88.88,0,0,0,0-.35V49.45Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M154.84,51.52l.24.19a3,3,0,0,1-.52.5,1.69,1.69,0,0,1-.48.27,1.64,1.64,0,0,1-.52.07,1.77,1.77,0,0,1-.92-.22,1.74,1.74,0,0,1-.66-.66,1.85,1.85,0,0,1-.23-1,2,2,0,0,1,.22-1,1.69,1.69,0,0,1,.62-.65,1.8,1.8,0,0,1,.94-.24,1.62,1.62,0,0,1,.68.13,1.18,1.18,0,0,1,.49.37,1.57,1.57,0,0,1,.29.56,1.68,1.68,0,0,1,.06.49h-2.59c0,.12,0,.2,0,.26a2,2,0,0,0,.16.83A1.16,1.16,0,0,0,153,52a1,1,0,0,0,.6.17,1.2,1.2,0,0,0,.55-.13A2.56,2.56,0,0,0,154.84,51.52ZM154.37,50a1.29,1.29,0,0,0-.15-.5.79.79,0,0,0-.29-.25.82.82,0,0,0-.42-.1.94.94,0,0,0-.43.1.85.85,0,0,0-.33.26,1.59,1.59,0,0,0-.24.49Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M159.25,47.69v-.3a2.35,2.35,0,0,0,1-.31h.28V51.6a1.27,1.27,0,0,0,0,.36.29.29,0,0,0,.14.15.88.88,0,0,0,.35,0h.12v.3h-1.89v-.3h.19a.49.49,0,0,0,.27-.06.31.31,0,0,0,.13-.15,1.06,1.06,0,0,0,0-.38V47.69Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M165.33,51.52l.23.19a3,3,0,0,1-.52.5,1.53,1.53,0,0,1-.47.27,1.7,1.7,0,0,1-.53.07,1.79,1.79,0,0,1-.92-.22,1.9,1.9,0,0,1-.66-.66,2,2,0,0,1-.23-1,2,2,0,0,1,.22-1,1.64,1.64,0,0,1,.63-.65,1.79,1.79,0,0,1,.93-.24,1.59,1.59,0,0,1,.68.13,1.12,1.12,0,0,1,.49.37,1.45,1.45,0,0,1,.3.56,2.84,2.84,0,0,1,.06.49h-2.6c0,.12,0,.2,0,.26a2,2,0,0,0,.16.83,1.16,1.16,0,0,0,.43.53,1,1,0,0,0,.6.17,1.24,1.24,0,0,0,.56-.13A2.74,2.74,0,0,0,165.33,51.52ZM164.86,50a1.46,1.46,0,0,0-.16-.5.76.76,0,0,0-.28-.25.87.87,0,0,0-.42-.1,1,1,0,0,0-.77.36A1.4,1.4,0,0,0,163,50Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M167.7,47.67H168v1.25h1.09v.37H168V51.5a1.28,1.28,0,0,0,0,.41.44.44,0,0,0,.17.19.43.43,0,0,0,.27.08,1.32,1.32,0,0,0,.77-.31v.36a1.78,1.78,0,0,1-1,.32,1.06,1.06,0,0,1-.5-.12.69.69,0,0,1-.31-.3,1.34,1.34,0,0,1-.08-.58V49.29h-.65V49a1.22,1.22,0,0,0,.5-.22,1,1,0,0,0,.31-.38A2.42,2.42,0,0,0,167.7,47.67Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M171.2,47.67h.26v1.25h1.09v.37h-1.09V51.5a1,1,0,0,0,0,.41.39.39,0,0,0,.17.19.4.4,0,0,0,.26.08,1.3,1.3,0,0,0,.77-.31v.36a1.75,1.75,0,0,1-1,.32,1.07,1.07,0,0,1-.51-.12.75.75,0,0,1-.31-.3,1.52,1.52,0,0,1-.08-.58V49.29h-.64V49a1.28,1.28,0,0,0,.5-.22,1,1,0,0,0,.31-.38A2.78,2.78,0,0,0,171.2,47.67Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M175.58,48.83a1.83,1.83,0,0,1,.93.23,1.56,1.56,0,0,1,.62.65,2.2,2.2,0,0,1,0,2,1.63,1.63,0,0,1-.64.66,2,2,0,0,1-1,.22A1.6,1.6,0,0,1,174.2,52a2,2,0,0,1-.45-1.31,2.07,2.07,0,0,1,.23-1,1.63,1.63,0,0,1,.65-.65A1.86,1.86,0,0,1,175.58,48.83Zm0,.36a.91.91,0,0,0-.71.33,1.71,1.71,0,0,0-.37,1.2,1.9,1.9,0,0,0,.31,1.15,1,1,0,0,0,.77.35,1,1,0,0,0,.56-.16,1.17,1.17,0,0,0,.39-.53,2.19,2.19,0,0,0,.15-.85,2.16,2.16,0,0,0-.16-.86,1,1,0,0,0-.38-.46A1,1,0,0,0,175.55,49.19Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M178.58,49.45v-.31a2,2,0,0,0,.48-.09,2,2,0,0,0,.47-.22h.26v.7a3.77,3.77,0,0,1,.83-.55,1.73,1.73,0,0,1,.7-.15,1.47,1.47,0,0,1,.53.1,1,1,0,0,1,.37.28.91.91,0,0,1,.2.39,2.07,2.07,0,0,1,0,.41v1.62a1,1,0,0,0,0,.37.3.3,0,0,0,.13.12,1.74,1.74,0,0,0,.45,0v.3h-1.88v-.3h.1a1,1,0,0,0,.36,0,.32.32,0,0,0,.14-.14,1.37,1.37,0,0,0,0-.38V50.24a1.57,1.57,0,0,0-.07-.58.63.63,0,0,0-.23-.26.76.76,0,0,0-.41-.1,1.6,1.6,0,0,0-.64.15,2.38,2.38,0,0,0-.65.45v1.69a1.13,1.13,0,0,0,0,.39.27.27,0,0,0,.14.14,1,1,0,0,0,.35,0h.11v.3h-1.9v-.3h.1a1,1,0,0,0,.37,0,.32.32,0,0,0,.14-.16,2.57,2.57,0,0,0,0-.41V49.45Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M187.32,51.52l.23.19a3,3,0,0,1-.52.5,1.65,1.65,0,0,1-.47.27,1.7,1.7,0,0,1-.53.07,1.79,1.79,0,0,1-.92-.22,1.82,1.82,0,0,1-.66-.66,1.85,1.85,0,0,1-.23-1,2,2,0,0,1,.22-1,1.64,1.64,0,0,1,.63-.65,1.79,1.79,0,0,1,.93-.24,1.59,1.59,0,0,1,.68.13,1.18,1.18,0,0,1,.49.37,1.45,1.45,0,0,1,.3.56,2.84,2.84,0,0,1,.06.49h-2.6c0,.12,0,.2,0,.26a2,2,0,0,0,.16.83,1.16,1.16,0,0,0,.43.53,1,1,0,0,0,.6.17,1.24,1.24,0,0,0,.56-.13A3,3,0,0,0,187.32,51.52ZM186.85,50a1.46,1.46,0,0,0-.16-.5.72.72,0,0,0-.29-.25.8.8,0,0,0-.41-.1,1,1,0,0,0-.44.1.93.93,0,0,0-.33.26A1.4,1.4,0,0,0,185,50Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M191.21,48.92h.26l.32,1.12-.33.12a1.67,1.67,0,0,0-.55-.72,1.24,1.24,0,0,0-.73-.25.76.76,0,0,0-.46.13.43.43,0,0,0-.17.32.37.37,0,0,0,.13.29,1.64,1.64,0,0,0,.56.28l.71.26a2.06,2.06,0,0,1,.83.46.87.87,0,0,1,.22.57,1,1,0,0,1-.16.51,1.16,1.16,0,0,1-.47.4,1.75,1.75,0,0,1-.7.14,1.65,1.65,0,0,1-1.06-.35l-.08.26h-.26l-.46-1.2.33-.13a3,3,0,0,0,.63.75,1.47,1.47,0,0,0,.9.31.79.79,0,0,0,.53-.17.5.5,0,0,0,.2-.38.43.43,0,0,0-.13-.31,1.17,1.17,0,0,0-.5-.28l-.85-.3a1.92,1.92,0,0,1-.76-.44.76.76,0,0,1-.21-.54.83.83,0,0,1,.34-.65,1.36,1.36,0,0,1,.89-.29,1.59,1.59,0,0,1,.5.08,1.29,1.29,0,0,1,.43.23Z"
                    transform="translate(-4.02 -0.92)"
                />
            </svg>
        </div>
    );
};
export default LogoFr;
