import React, { useEffect } from "react";
import "./Aktuell.scss";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function Aktuell() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={"page-aktuell"}>
            <div className={"aktuell-container"}>
                <div className={"aktuell-content"}>
                    <h1 className={"title"}>
                        {t("page-aktuell.title-aktuell")}
                    </h1>
                    <p>
                        Facebook: <a href="https://www.facebook.com/Auseklis-Latvian-language-school-Sprachkurse-lettische-Sprache-Kultur-108158179203743/" target="_blank">Auseklis-Latvian language school</a>
                    </p>
                    <h1 className={"title"}>
                        {t("page-aktuell.subtitle")}
                    </h1>
                    {i18n.language === "de" ? (
                        <>
                    <p>
                        <Trans i18nKey="page-aktuell.p1">
                            AKTUELL: Lernen Sie im Frühjahr 2021 Lettisch im Online-Unterricht. Wir haben ein spezielles Angebot für Sie. Sie können jederzeit einsteigen: Anfrage per <a href={'mailto:mailto:ilscho@vtxnet.ch?subject=Auseklis'}>E-Mail</a>.
                        </Trans>
                    </p>

                    <p>
                        <Trans i18nKey="page-aktuell.p2">
                            Lettisch-Kurse für Erwachsene gibt es ab Herbst 2021 weiterhin in Zürich und Basel. Anfrage per <a href={'mailto:ilscho@vtxnet.ch?subject=Kurse%20in%20lettischer%20Kultur'}>E-Mail</a>.
                        </Trans>
                    </p>
                    <p>
                        <Trans i18nKey="page-aktuell.p3">Am 1. November 2016 durfte unsere Lehrerin Ilze bei der Premiere des Films von Viesturs Kairišs, “The Chronicles of Melanie” in Riga teilnehmen. Die Schweizer Schauspielerin Sabine Timoteo i besuchte unsere Schule Auseklis, um Lettisch- und Russisch-Kenntnisse für die Aussprache Ihrer Texte zu erwerben. Ihre Figur Melānija spricht im Film vier Sprachen! Lettisch, Russisch, Deutsch und die vierte Sprache: die Sprache des Herzens!"</Trans>
                    </p>
                    <p>
                        <Trans i18nKey="page-aktuell.p4">Im Februar 2017 publizierte die Zeitschrift \",Brīvā Latvija\", Nummer 6 (Jan./Feb. 2017) ein Interview mit Sprachcoach Ilze Schorderet zu Ihrer Arbeit mit Sabine Timoteo: Lesen Sie einen <a href={'https://www.auseklis.ch/docs/Interview_Sprachcoaching_Auseklis_Melanies_Chronik.pdf'} target={'_blank'}>Auszug des Interviews</a>."</Trans>
                    </p>
                    <p>
                        <Trans i18nKey="page-aktuell.p5">Am 4. November 2017 zeigten wir den Film in Gegenwart des Regisseurs Viesturs Kairiš und der Szenografin Ieva Jurjāne im Stadtkino Basel. Knapp 90 geladene Gäste waren dabei."</Trans>
                    </p>
                    <p>
                        <Trans i18nKey="page-aktuell.p6">Sie wollen das wunderschöne aber noch unbekannte Lettland kennen lernen? Auseklis bietet an verschiedenen Daten wieder eine intensive Einführung in Sitten, Geschichte und Kultur, für die allgemeine Bildung oder die Reisevorbereitung. Ihre Fragen zu Land und Leben werden nicht unbeantwortet bleiben. Anmeldung hier.Sie können auch einfach per <a href={'mailto:ilscho@vtxnet.ch?subject=Kurse%20in%20lettischer%20Kultur'}>E-Mail</a> anfragen. Geben Sie an, welche Themen Sie besonders interessieren. Folgende Kursinhalte werden angeboten:"</Trans>
                    </p>
                    <ul>
                        <li>
                            <Trans i18nKey="page-aktuell.li1" />
                        </li>
                        <li>
                            <Trans i18nKey="page-aktuell.li2" />
                        </li>
                        <li>
                            <Trans i18nKey="page-aktuell.li3" />
                        </li>
                        <li>
                            <Trans i18nKey="page-aktuell.li4" />
                        </li>
                        <li>
                            <Trans i18nKey="page-aktuell.li5" />
                        </li>
                        <li>
                            <Trans i18nKey="page-aktuell.li6" />
                        </li>
                    </ul>
                    <p>
                        <Trans i18nKey="page-aktuell.p7">Weit entfernt? Wenn Sie zu weit entfernt sind, um unsere Lettischkurse bei Auseklis in Zürich oder Basel zu besuchen, können Sie Unterricht und Beratung per Skype erhalten! Anfrage per <a href={'mailto:ilscho@vtxnet.ch?subject=Sprachkurse%20Auseklis'}>E-Mail</a>.</Trans>
                </p>
                <p>
                    <Trans i18nKey="page-aktuell.p8">Den Jahresbericht über die Aktivitäten von Auseklis im Jahr 2016 können Sie in lettischer Sprache hier lesen: <a href={'https://www.auseklis.ch/docs/2015_Jahresbericht.pdf'} target={'_blank'}>Download</a>.</Trans>
            </p>
            <p>
                <Trans i18nKey="page-aktuell.p9"><strong>AKTUELL auch 2017:</strong> Auseklis  bietet Privatstunden in Russisch an. Wir besitzen das von Russland anerkannte Diplom für Russisch als Fremdsprache höchster Stufe. Russisch ist eine Sprache, die man auch in Lettland hören kann. Für unsere Russisch-Privatstunden fragen Sie uns doch bitte unverbindlich per <a href={'mailto:ilscho@vtxnet.ch?subject=Anfrage%20Russisch-Privatstunden'} target={'_blank'}>E-Mail</a> an . </Trans>
        </p>
    <p>
        <Trans i18nKey="page-aktuell.p10">Auseklis bietet auch Übersetzungen an. Sie wollen offizielle lettische Dokumente und andere Texte verstehen, aus dem Deutschen in das Lettische oder umgekehrt übersetzen? Fragen Sie bei uns an! Wir übersetzen auch aus dem Französischen, Italienischen, Englischen und Russischen ins Lettische; sowie aus dem Lettischen ins Französische. Andere Zielsprachen (Englisch, Italienisch, Russisch) bieten wir auf Anfrge und in Zusammenarbeit mit externen Übersetzern an. Weitere Informationen finden Sie <a href={'https://www.auseklis.ch/Uebersetzung.htm'} target={'_blank'}>hier</a>.</Trans>
</p>
    <p>
        <Trans i18nKey="page-aktuell.p11">Auseklis  ist auch auf dem Social Web zu finden! Wenn Sie über unsere aktuellsten Angebote und Aktivitäten sowie über das lettische Kulturleben in Lettland und der Schweiz informiert sein wollen, können Sie auch unsere <a href={'http://www.facebook.com/pages/Auseklis-Latvian-language-school-Sprachkurse-lettische-Sprache-Kultur/108158179203743'} target={'_blank'}>Facebook-</a> und Twitter-Seiten verfolgen (<a href={'https://twitter.com/Auseklis_skola'} target={'_blank'}>twitter.com/Auseklis_skola</a>).</Trans>
</p>
    <p>
        <Trans i18nKey="page-aktuell.p12">Lieben Sie Poesie? - Der grosse lettische Dichter Janis Rokpelnis las auf Einladung unserer Schule und der Gesellschaft Schweiz-Lettland aus seinen Werken: Am 21. Nov. 2013 an der Univ. Bern und am 23. Nov. in unserer Schule Auseklis  in Zürich. Die Texte wurden in Lettisch gelesen mit französischer Übersetzung. Rokpelnis trat zusammen mit dem Genfer Dichter Laurent Cennamo auf. Dieses Projekt war eine Zusammenarbeit der Lettischschule Auseklis  mit der Gesellschaft Schweiz-Lettland, der Hull's School und der Alliance française in Zürich, der Fondation Jan Michalski und der Universität Bern. Mehr Informationen sind <a href={'https://www.auseklis.ch/poesie.htm'} target={'_blank'}>hier</a> zu finden.</Trans>
</p>
    <p>
        <Trans i18nKey="page-aktuell.p13">Die Rockband \"Brainstorm\", besuchte Auseklis am 8. Mai 2013! Wir zeigten den Konzertfilm \",Vēl viena klusā daba\", (\",Ein Stilleben mehr\",) kostenlos in Zürich.</Trans>
    </p>
    <p>
        <Trans i18nKey="page-aktuell.p14">Am 17. Februar 2013 traten in der Schule für lettische Sprache  und Kultur AuseklisLilija und Edgars Lipori (Lettland) auf. Sie führten ein Theaterstück über typische lettische Musikinstrumente auf: „Die Reise der Kokle“. Nach der Vorführung folgten lettische Tänze und Reigen für Gross und Klein! Edgars Lipors ist Puppenmeister in Lettland und Leiter des Männer-Ensembles „Vilki”. Lilija Lipore ist Choreografin. Der Anlass fand in der Hull School, Falkenstrasse 28a, 8008 Zürich, statt. Dieser Anlass wurde möglich dank der Unterstützung des Kulturministeriums der Republik Lettland und der Familie Hull </Trans>
    </p>
    <p>
        <Trans i18nKey="page-aktuell.p15">
            Am 18.02.2012 fand in Lettland eine Abstimmung über die Einführung von Russisch als offizielle Staatssprache in der Republik Lettland statt. Bei einer Annahme der Initiative wäre Russisch zu einer offiziellen Amtssprache der Europäischen Union geworden. Eine Politik, die von dem Kreml nahestehenden Kreisen aktiv verfolgt wird und aktuell das sonst verhältnissmässig gute Klima zwischen den verschiedenen Volksgruppen in Lettland gefährdet.  Über die aktuelle Situation erteilen wir der Presse gerne Auskunft. Kontaktieren Sie uns! Eine gute wissenschaftliche  Einführung in die Problematik finden Sie bei David Rupp (<a href={'http://www.ibidemverlag.de/product_info.php?info=p510_die-russlaendische-foederation-und-die-russischsprachige-minderheit-in-lettland.html'} target={'_blank'}>hier klicken</a>).
        </Trans>
    </p>
        <p>
            <Trans i18nKey="page-aktuell.p16">Erinnerung an Lettland: Am 11. November 2011zeigte die Lettisch-Schule Auseklis in Zusammenarbeit mit der Film GmbH München den  Film \",Das blaue vom Himmel\", (2011). Anwesend waren die Shooting Stars Niklaus Kohrt aus Berlin (ehem. Schauspielhaus Zürich) und Juta Vanaga. Fotos dazu finden Sie unter dem Datum 11. November auf unserer Fotogalerie (hier klicken). Bitte <a href={'https://www.auseklis.ch/fotos.htm'} target={'_blank'}>hier klicken</a> für die Einladung und das Filmplakat.</Trans>
    </p>
    <p>
        <Trans i18nKey="page-aktuell.p17">Lettisches Kino: Am 27. Mai 2010 um 19.00 zeigten wir im Rahmen der Lettisch-Schule Auseklis einen weiteren Film aus Lettland, \",Kleine Räuber\", (2009). Der Film ist auf Lettisch und hat englische Untertitel. Die abenteuerliche Komödie für die ganze Familie von Armands Zvirbulis erzählt die Geschichte von Kindern, die in Riga eine Bank überfallen wollen, um den Eltern zu helfen ! Einen Trailer (mit Untertiteln) finden Sie <a href={'http://www.youtube.com/watch?v=XrGr1SwzW2o'} target={'_blank'}>hier</a> .</Trans>
</p>
    <p>
        <Trans i18nKey="page-aktuell.p18">Lettisches Kino: Am 11. März 2010 um 19.00 zeigten wir im Rahmen der Lettisch-Schule Auseklis einen Kult-Film aus Lettland, „Vier weisse Hemden“ (1967). Der Film ist auf Lettisch und hat englische Untertitel. Der Raum der Schule befindet sich in der Hull`s School, Falkenstrasse 28A, 8008 Zürich. Auszug aus dem Film <a href={'http://www.youtube.com/watch?v=QAvuptcuCFk&feature=related'} target={'_blank'}>hier</a>.</Trans>
</p>
    <p>
        <Trans i18nKey="page-aktuell.p19">Lettischer Musikabend am 25. Februar 2010 von 20.00-24.00. Im Herbert, Bäderstrasse 11, 5400 Baden. Alle Musikfreunde waren herzlich eingeladen... Wir hörten Musik aus Lettland und wer mochte, konnte auch Karaoke singen.</Trans>
    </p>
    <p>
        <Trans i18nKey="page-aktuell.p20">Auseklis unterstützte die Kandidatur von Vaira Viķe-Freibergafür die Präsidentschaft des EU-Rates. Die Expräsidentin von Lettland hat am 17.10.09 Lugano besucht, wo sie einen Vortrag hielt. <a href={'http://www.studienstiftung.ch/d/data/262/Programm D_Homepage.pdf'} target={'_blank'}>http://www.studienstiftung.ch/d/data/262/Programm D_Homepage.pdf</a></Trans>
    </p>
    <p>
        <Trans i18nKey="page-aktuell.p21">Auseklis  feierteanlässlich des 90. Gründungstages der Republik Lettland am 18.November 2008!Wir haben eine Blumengirlande bei der Gedenktafel von Rainis und Aspazija in Zürich abgelegt. Danach, um 19 Uhr, folgte im Rahmen der Lettisch-Schule „Auseklis“ ein Film aus Lettland, \"Rīgas sargi\". Siehe auch weitere Bilder in der Sektion Fotos unter diesem Datum.</Trans>
    </p>
                    </>  ) : ("")}
                    {i18n.language === "fr" ? (
                        <>
                        <p>
                            <Trans i18nKey="page-aktuell.p1">
                                Apprenez le letton en ligne. Nous avons des offres taillées sur mesure pour vous. Vous pouvez commencer à tout moment : contactez-nous par <a href={'mailto:ilscho@vtxnet.ch?subject=Auseklis'}>courriel</a>.
                            </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p2">
                            Les cours de langue en présence pour adultes sont disponibles à Zurich et Bâle. Contact par <a href={'mailto:ilscho@vtxnet.ch?subject=Kurse%20in%20lettischer%20Kultur'}>courriel.</a>
                        </Trans>
                        </p>
                        </>
                    ) : ("")}
                    {i18n.language === "lv" ? (
                        <>
                            <p>
                                <Trans i18nKey="page-aktuell.p1">
                                    Apprenez le letton en ligne. Nous avons des offres taillées sur mesure pour vous. Vous pouvez commencer à tout moment : contactez-nous par <a href={'mailto:ilscho@vtxnet.ch?subject=Auseklis'}>courriel</a>.
                                </Trans>
                            </p>
                            <p>
                                <Trans i18nKey="page-aktuell.p2">
                                    Les cours de langue en présence pour adultes sont disponibles à Zurich et Bâle. Contact par <a href={'mailto:ilscho@vtxnet.ch?subject=Kurse%20in%20lettischer%20Kultur'}>courriel.</a>
                                </Trans>
                            </p>
                            <h1 className={"title"}>
                                {t("page-aktuell.subtitle")}
                            </h1>
                            <p>
                                <Trans i18nKey="page-aktuell.p3">Piedāvājam kursus un privātstundas tiešsaistē (internetā). Piedāvājums ir <a href={"https://www.auseklis.ch/docs/Auseklis.%20Corona%20LV%20pdf.pdf"} target={"_blank"}>šeit (PDF)</a>. </Trans>
                            </p>
                            <p>
                                <Trans i18nKey="page-aktuell.p4">2019. g. atskaite. Lasiet <a href={"https://www.auseklis.ch/docs/Gada-atskaite-2019.pdf"} target={"_blank"}>šeit (PDF)</a>. </Trans>
                            </p>
                            <p>
                                <Trans i18nKey="page-aktuell.p5">Kartiites2016. gada decembrī skolas  Auseklis bērni no 3 līdz 13 gadu vecumam piedalījās Latvijas institūta rīkotajā akcijā ZIEMASSVĒTKU kartītes Latvijas senioriem vispārējā tipa pansionātā MADLIENA! Vairāk par šo un citām aktualitātēm mūsu facebook lapā. </Trans>
                            </p>
                            <p>
                                <Trans i18nKey="page-aktuell.p6">Pieteikumi bērniem: visi pieteikumi bērniem 2017. gadam atrodas nodaļā \"Bērniem\": <a href={"https://www.auseklis.ch/beerniem.htm"} target={"_blank"}>spiest šeit</a>. Priecāsimies par Jūsu apmeklējumu. </Trans>
                            </p>
                            <p>
                                <Trans i18nKey="page-aktuell.p7">Ceļojums uz Tripiti: 2013. gada rudenī atzīmējām skolas pastāvēšanas piekto Tripitidzimšanas dienu. Šai piecgadei par godu 2014. gada jūnijā apgādā JUMAVA iznākusi Ilzes Šorderetas (Schorderet) tulkotā šveiciešu autora H.U. Stegera bērnu grāmata „Ceļojums uz Tripiti“! Grāmatas izdošanu atbalstīja Gunārs Štrekeizens un skola Auseklis. Grāmatu var iegādāties šeit... </Trans>
                            </p>
                            <p>
                                <Trans i18nKey="page-aktuell.p8">Mēs esam viena no vislētākajām valodas skolām cittautiešu bērniem Cīrihē. Lasiet vairāk par mūsu cenu politiku: <a href={"https://www.auseklis.ch/docs/Skolas%20Auseklis%20cenu%20politika.pdf"} target={"_blank"}>spiest šeit</a>. Kā cittautieši apgūst latviešu valodu Šveicē mūsu raksts „Latvians online“, 2013. g. maijs: <a href={"https://www.auseklis.ch/docs/Ilze_Schorderet_Mai_2013pdf.pdf"} target={"_blank"}>lasīt šeit</a>. </Trans>
                            </p>
                            <p>
                                <Trans i18nKey="page-aktuell.p9">
                                    Lasiet rakstu par mūsu skolu Auseklis  žurnālā Skolas vārds -"Par skolotājiem, kas kopj un uztur mūsu latvietību svešumā" 2013. g. novembris: <a href={"https://www.auseklis.ch/docs/2013%2011%2021%20Skolas_Vards_Nr_38_2013%20iss.pdf"} target={"_blank"}>lasīt šeit</a>. Pateiciibas raksts beerniemApsveicam!!! 2013. gada 15. jūnijā noslēguma nodarbībā Latviešu valodas un kultūras skolas Auseklis  skolēni saņēma Latvijas Republikas Izglītības un zinātnes ministrijas izsniegto Pateicības rakstu.
                                </Trans>
                            </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p10">Jaunums! Jaunumi par skolas Auseklis  darbību un par latviešu kultūru Latvijā un Šveicē tagad arī sociālajos tīklos. Lūdzu sekojiet mums Feisbukā (<a href={"http://www.facebook.com/pages/Auseklis-Latvian-language-school-Sprachkurse-lettische-Sprache-Kultur/108158179203743"} target={"_blank"}>spiežot šeit: Facebook</a>) vai Tviterī (<a href={"https://twitter.com/Auseklis_skola"} target={"_blank"}>spiežot šeit: twitter.com/Auseklis_skola</a>) un Draugu domubiedros (<a href={"http://www.draugiem.lv/group/16082085/auseklis/"} target={"_blank"}>spiežot šeit: draugiem.lv/.../auseklis/</a>). </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p11">Diploma Apsveicam!!! 2011. gada 27. augustā noslēguma nodarbībā Latviešu valodas un kultūras skolas Auseklis skolēni saņēma Latvijas Republikas Izglītības un zinātnes ministrijas izsniegto Pateicības rakstu. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p12">Ar 2009. gada jūliju Auseklis piedāvā arī tulkojumus. Ja Jūs vēlaties saprast oficiālus dokumentus vai citus tekstus vācu valodā, tos pārtulkot no latviešu valodas uz vācu, kā arī no vācu uz latviešu valodu, tad rakstiet mums! Mēs tulkojam tekstus latviešu valodā no franču valodas, tāpat arī no latviešu valodas franču valodā, kā arī itāļu, angļu und krievu valodām (<a href={"https://www.auseklis.ch/Tulkojumi.htm"} target={"_blank"}>spiest šeit</a>). Angļu, itāļu un krievu valodas tulkojumi tiek veikti sadarbībā ar eksterniem tulkiem (šis piedāvājums pēc pieprasījuma). </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p13">Latviešu folkloras mācības Cīrihē!  Jāņu nodarbībaIlga Reizniece bērniem no 6 līdz 18 gadiem un viņu vecākiem kopā ar folkloristi un mūziķi Ilgu Reiznieci 2016. g. 25. jūnijā! Nodarbību vadīja folkloras un mūzikas pedagoģe, vijolniece, postfolkloras grupas “Iļģi” soliste, grāmatu un dziesmu tekstu, kā arī tautasdziesmu autore Ilga Reizniece. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p14">Vai Jums patīk dzeja? Dzejas lasījumi notika 2013. g. 21. novembrī Bernes Universitātē un 23. novembrī Cīrihē, mūsu Latviešu valodas un kultūras skolāRokpelnis Auseklis. Teksti tika lasīti latviešu un franču valodā. Bernē un Cīrihē Jānis Rokpelnis dzeju lasīja kopā ar jauno dzejnieku no Ženēvas Lorenu Senamo (Laurent Cennamo). Projekta sadarbības partneri: Biedrība Šveice-Latvija, Hull skola Cīrihē, Alliance francaise Cīrihē, Jana Mihalska fonds (Fondation Jan Michalski) un Bernes Universitāte. Vairāk informācijas par visiem trim pasākumiem Šveicē: šeit. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p15">Grupas Prāta Vētra koncertfilmas \"Vēl viena klusā daba\" bezmaksas seanss arī Cīrihē. Filma tika demonstrēta 2013. g. 8. maijā plkst. 19.00 Latviešu valodas un kultūras skolā Auseklis. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p16">2013. gada martā Cīrihes latviešu un kultūras skolas Auseklis  skolēni pārstāvēja latviešu diasporas bērnus, piedaloties \"Bērnu un jauniešu žūrijas\" lielo lasīšanas svētku noslēgumā. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p17">KOKLĪTES CEĻOJUMS: 2013. g. 17. februārī, Cīrihē, LatviešuKoklītes ceļojums valodas un kultūras skolā Auseklis, viesojās Lilija un Edgars Lipori (Latvija) ar izrādi par latviešu tautas mūzikas instrumentiem. Pēc izrādes sekoja LUSTĪGI DANČI UN ROTAĻAS lieliem un maziem! Edgars Lipors ir Latvijas Leļļu teātra aktieris un Vīru kopas „Vilki” vadītājs. Lilija Lipore ir horeogrāfe. Pasākums notika: Hull School, Falkenstrasse 28a, 8008 Zürich. Pasākums nebūtu bijis iespējams bez Latvijas Republikas Kultūras ministrijas un Hull ģimenes atbalsta. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p18">Par, Latviju! 2011. gada 11. novembrī skola Auseklis  kopā ar film GmbH Minhenē demonstrēja spēlfilmu \"Das Blaue vom Himmel\" (2011). Vakara viesi bija Niklass Korts no Berlīnes un Juta Vanaga. Fotogrāfijas redzamas mūsu galerijā (<a href={"https://www.auseklis.ch/pics/Einladung_Auseklis_1.gif"} target={"_blank"}>spiest šeit</a>). Ielūgums un filmas plakāts atrodas te (<a href={"https://www.auseklis.ch/pics/Einladung_Auseklis_1.gif"} target={"_blank"}>spiest šeit</a>). </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p19">2011., g. 25. aprīlī latviešu bērni Tičino svinēja Lieldienas! Nodarbību vadīja divi zaķi ar pedagoģisko izglītību; tā notika Caslano. Bīja aicināti piedalīties: latviešu bērni no Šveices un Itālijas vecumā no 4 - 10 gadiem. Programmā: Lieldienu pasaku lasīšana, olu ripināšana, olu meklēšana, iešana rotaļās, šūpošanās, dažādi joki. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p20">Pateicoties Nacionālajam Kino centram, Latviešu valodas skolas Auseklis ietvaros demonstrējām mākslas filmu bērniem (un pieaugušajiem) Puika (1977.g., 84 min.) </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p21">09.04.2011., plkst: 12.15 – 13.45. Seanss notiek Falkenstrasse 28A, 8008 Zürich<br/>
                        - Scenārija autori- Imants Ziedonis, Aivars Freimanis<br/>
                        - Režisors inscenētājs- Aivars Freimanis<br/>
                        - Seansa mērķis: popularizēt latviešu valodu un kultūru. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p22">19. gadsimta beigas Latvijā. Mūsmājās kopā ar māti, vectēvu un vecomāti dzīvo mazais kalpa dēlēns Jancis, viņa pasaule nesniedzas tālāk par apvārsni un dzīve rit saskaņā ar dabas cikliem  - no ziemas caur pavasari, vasaru un rudeni līdz jauniem Jurģiem.“ Matīsa, K. (2005). Vecās labās... Latviešu kinoklasikas 50 spožākās pērles. Apgāds Atēna, 280. lpp. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p23">21.12.2010. Ziemassvētku svinības Cīrihē! Sākums pulksten 20.30. Hull School, Falkenstrasse 28A, 8008 Zürich. Katrs ir aicināts ņemt līdzi ēdamo, dzeramo un Ziemassvētku dziesmu vai tautasdziesmu.Uz tikšanos pie egles! </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p24">Pateicoties Nacionālajam Kino centram, Latviešu valodas skolas Auseklis ietvaros demonstrējām mākslas filmas Emīla nedarbi (1985.g., 70 min) un Limuzīns Jāņu nakts krāsā (1981.g., 83 min.), 25.11.2010. Ielūgums <a href={"https://www.auseklis.ch/docs/Ielugums%20kino%20rudens%202010.jpg"} target={"_blank"}>šeit</a>. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p25">Koris: 10. jūnijā 2010.g. latvieši Šveicē dibināja kori (Cīrihē un Bāzelē)! Kora organizatori: Raitis Grigalis- baritons, diriģents Bāzelē un Ilze Schorderet- latviešu skola Auseklis  Cīrihē. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p26">Latviešu kino- Skolas Auseklis ietvaros demonstrējām Armanda Zvirbuļa mākslas filmu visai ģimenei “Mazie laupītāji” (2009) ar subtitriem angļu valodā. Filmas fragments <a href={"http://www.youtube.com/watch?v=XrGr1SwzW2o"} target={"_blank"}>šeit</a>. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p27">Latviešu, mūzikas vakars- 25.02.2010. pulksten 20.00 - 24.00. Herbertā, Bäderstrasse 11, 5400 Bādenē. Bezmaksas ieeja ar dzērienu piemaksu 2,- CHF. Vakara gaitā skanēs latviešu mūzika, ja būs luste, varēs uzdziedāt latviešu karaoki. Bez pārsteiguma neiztiksim ;-). </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p28">Latviešu kino- Skolas Auseklis ietvaros demonstrējam Rolanda Kalniņa mākslas filmu “Četri balti krekli” (1967) ar subtitriem angļu valodā. 11.03.2010. pulksten 19.00. Telpa atrodas Hulls School, Falkenstrasse 28A, 8008 Zürich. Laipni aicināti! </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p29">Pirmajās Lieldienās Cīrihē (12.04.2009.)  lieli un mazi- visi, visi uz šūpolēm!!!<br/>
                        <br/>
                        Nāc, nākdama, Liela diena,<br/>
                        Visi bērni tevi gaida,<br/>
                        Visi bērni tevi gaida,<br/>
                        Aiz vārtiem sasēduši. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p30">Sestdien 21. martā skolā Auseklis  Latvijas animācijas filmas bērniem. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p31">2-4 gadīgu bērnu grupiņa- bērnu rīta pastāvīgie dalībnieki, skolas „Auseklis“ programmas ietvaros, pulksten 10.00-11.30 dažādu Latvijas animācijas filmu skatīšanās. Pēc filmām bērniem būs iespēja zīmēt un izkrāsot filmu varoņus. Viesos- animācijas eksperts Uldis Mākulis un bērnu grāmatu ilustratori Anete Melece un Rūdis Schorno. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p32">No 5 gadu vecuma, skola „Auseklis“ aicina piedalīties visiem latviešu bērniem. Latviešu rakstniekam Kārlim Skalbem šogad tiks svinēti 130 gadi. Pulksten 13.00 tiks demonstrēta pēc viņa pasakas motīviem veidotā Rozes Stiebras pilnmetrāžas filma „Kaķīša dzirnavas“ (filmas ilgums 60.min.). Pēc filmas noskatīšanās dažādas ar filmu saistītas aktivitātes. Viesos- animācijas eksperts Uldis Mākulis  un bērnu grāmatu ilustratori Anete Melece un Rūdis Schorno. Ieeja par ziedojumiem. </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p33">Visi latviešu valodas kursi un bērnu rīti latviešu bērniem no 2008.g. ceturtās oktobra nedēļas. Auseklis ir arī iespēja pie mums apmeklēt latviešu valodas privātstundas (Tālākā informācija: <a href={"http://www.youtube.com/watch?v=XrGr1SwzW2o"} target={"_blank"}>Pieteikums</a>). </Trans>
                        </p>
                        <p>
                        <Trans i18nKey="page-aktuell.p34">Auseklis  un Latvijas valsts 90. jubilejas svinības 18. novembrī! Šogad Latvijas valsts dzimšanas dienā svētku svinības sākas ar ziedu vītnes nolikšanu pie Raiņa un Aspazijas piemiņas plāksnes Cīrihē, pulksten 18.00. Skolas „Auseklis“ ietvaros demonstrējam mākslas filmu „Rīgas sargi“ pulksten 19.00. Filmas atkārtota demonstrēšana svētku nedēļā 22. novembrī pulksten 14.00  skolā „Auseklis“. Telpa atrodas Hull`s School,  Falkenstrasse 28A, 8008 Zürich."</Trans>
                        </p>

                        </>
                    ) : ("")}

</div>
            </div>
        </section>
    );
}
