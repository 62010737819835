import React from "react";

const LogoDe = () => {
    return (
        <div className="LogoDe">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.42 59.19">
                <path
                    d="M64.14,43.77a3.78,3.78,0,0,1-1.64-1.53,3.5,3.5,0,0,1-.34-1.35A9.73,9.73,0,0,1,63,37.53q.84-2.12,2.25-4.85c1-1.83,2-3.73,3.23-5.71-.89-.07-1.74-.11-2.54-.14s-1.83,0-3.09,0h-.69a4,4,0,0,1,1.91-2.34,6.65,6.65,0,0,1,3.07-.68,11.66,11.66,0,0,1,1.53.1l1.65.18q2.16-3.34,4.24-6.31c1.39-2,2.7-3.77,4-5.36s2.36-2.91,3.33-3.93c1.38-1.48,2.38-2.23,3-2.23a2.5,2.5,0,0,1,1.35.52A11.45,11.45,0,0,1,87.77,8a6,6,0,0,0-1.1,1.9Q86,11.46,85.26,13.8t-1.51,5.13Q83,21.72,82.4,24.6t-1,5.5a34.5,34.5,0,0,0-.36,4.62,8.24,8.24,0,0,0,.34,2.76,1.2,1.2,0,0,0,1.16.9c.66,0,1.48-.54,2.45-1.62a24.73,24.73,0,0,0,3.27-5.29l.77.88a19.13,19.13,0,0,1-3.46,6.27,6,6,0,0,1-4.53,2.46,3,3,0,0,1-2.92-1.93,13.06,13.06,0,0,1-.86-5.27c0-.71,0-1.51.12-2.41s.23-2,.43-3.34c-1.26-.22-2.37-.39-3.35-.51l-2.79-.38q-2.07,4-4,8.17T64.14,43.77ZM78.27,25.85c.38-1.82.81-3.67,1.31-5.55s1-3.66,1.59-5.38,1.11-3.26,1.66-4.62A21.39,21.39,0,0,0,80,13.5q-1.59,2.1-3.33,4.92T73.07,24.6c.86.18,1.72.39,2.58.6Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M90.35,41.12A2.74,2.74,0,0,1,88,39.66,6.18,6.18,0,0,1,87,36.3a14.53,14.53,0,0,1,.39-3.07,23.71,23.71,0,0,1,1-3.43A16,16,0,0,1,89.73,27c.44-.74.85-1.11,1.22-1.11a2.09,2.09,0,0,1,.92.35,4.37,4.37,0,0,1,1.1.85,16.92,16.92,0,0,0-1.14,2.28,27.67,27.67,0,0,0-1.18,3.3,11.62,11.62,0,0,0-.52,3.2c0,.12,0,.42.07.88a4.28,4.28,0,0,0,.32,1.3.91.91,0,0,0,.9.6A2.3,2.3,0,0,0,93,37.8a14.2,14.2,0,0,0,1.72-2.22c.59-.92,1.16-1.9,1.72-2.95s1.06-2.07,1.5-3.06.81-1.83,1.1-2.51a6.75,6.75,0,0,1,1,.23,4.19,4.19,0,0,1,1.09.49,1,1,0,0,1,.48.86,6.88,6.88,0,0,1-.41,1.13c-.27.67-.59,1.47-1,2.4S99.58,34,99.31,35a9.79,9.79,0,0,0-.41,2.58,2.51,2.51,0,0,0,.24,1.13.91.91,0,0,0,.92.49,3.93,3.93,0,0,0,2.15-.88,13.35,13.35,0,0,0,2.58-2.55,18.85,18.85,0,0,0,2.36-4l.64.56a18,18,0,0,1-2.44,4.5A13.42,13.42,0,0,1,102.12,40a6,6,0,0,1-3.35,1.13,2.53,2.53,0,0,1-1.87-.62,3.3,3.3,0,0,1-.84-1.63,11.38,11.38,0,0,1-.25-2v-.93c-.49.84-1,1.65-1.59,2.44a10.61,10.61,0,0,1-1.83,2A3.16,3.16,0,0,1,90.35,41.12Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M111.06,42.1a5.79,5.79,0,0,1-3.46-1,5.28,5.28,0,0,1-1.87-2.42,8.34,8.34,0,0,1-.56-3,5.09,5.09,0,0,1,.35-2.09,2.55,2.55,0,0,1,.86-1.09,5.2,5.2,0,0,1,1-.53,2,2,0,0,0,.67-.42,35,35,0,0,0,2.21-2.81,18.63,18.63,0,0,0,2-3.55v-.47a4.45,4.45,0,0,1,.58-2.62,1.68,1.68,0,0,1,1.35-.77.93.93,0,0,1,.75.28,1.23,1.23,0,0,1,.28.65,2.34,2.34,0,0,1-.1.65,3.51,3.51,0,0,0-.11,1,9.66,9.66,0,0,0,.62,3.18c.41,1.2.83,2.46,1.25,3.79a13.78,13.78,0,0,1,.62,4.08c0,.37,0,.71,0,1s-.08.61-.13.89a6.31,6.31,0,0,0,2.62-1.61,12.47,12.47,0,0,0,2.45-3.64l.51.42a10.43,10.43,0,0,1-2.36,4.25,6.58,6.58,0,0,1-3.61,2,5.23,5.23,0,0,1-2.25,2.83A6.84,6.84,0,0,1,111.06,42.1Zm-.3-2a2.92,2.92,0,0,0,1.59-.47,3.25,3.25,0,0,0,1.16-1.34,8.53,8.53,0,0,1-3.18-1.49,9.61,9.61,0,0,1-2.19-2.18c-.4.09-.6.51-.6,1.25a5.51,5.51,0,0,0,.81,2.88A2.65,2.65,0,0,0,110.76,40.06Zm3.09-3.21a3.22,3.22,0,0,0,.11-.76c0-.3,0-.52,0-.68a20.55,20.55,0,0,0-.51-4.13c-.32-1.36-.63-2.75-1-4.18-.51,1-1.12,2-1.8,3.11a19.93,19.93,0,0,1-1.94,2.7,8.57,8.57,0,0,0,2,2.37A8,8,0,0,0,113.85,36.85Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M126.1,41.77a5.47,5.47,0,0,1-4.23-1.6,5.67,5.67,0,0,1-1.49-4,11.3,11.3,0,0,1,1-4.34A17.93,17.93,0,0,1,124,27.5a14.74,14.74,0,0,1,3.61-3.27A7.32,7.32,0,0,1,131.51,23a3.94,3.94,0,0,1,2.13.63,2.71,2.71,0,0,1,1,2.48,6.16,6.16,0,0,1-.94,3.3,10.24,10.24,0,0,1-2.54,2.74,15.18,15.18,0,0,1-3.5,2,15.52,15.52,0,0,1-3.85,1,2.28,2.28,0,0,0-.06.39,6,6,0,0,0,0,.68q0,.23.06.81a3.9,3.9,0,0,0,.37,1.23,2.79,2.79,0,0,0,1,1.11,3.34,3.34,0,0,0,1.89.47,7.12,7.12,0,0,0,3.64-1.09,15,15,0,0,0,3.43-2.9,17.56,17.56,0,0,0,2.69-4l.86.74a17.41,17.41,0,0,1-3,4.87,13.69,13.69,0,0,1-4,3.25A9.54,9.54,0,0,1,126.1,41.77Zm-1.81-8.4a7.78,7.78,0,0,0,2.5-.83,14.51,14.51,0,0,0,2.64-1.77,10.35,10.35,0,0,0,2.08-2.34,4.7,4.7,0,0,0,.82-2.58,1,1,0,0,0-.17-.69.72.72,0,0,0-.56-.19,3.5,3.5,0,0,0-2.06.77,11.41,11.41,0,0,0-2.11,2,17.1,17.1,0,0,0-1.85,2.72A18,18,0,0,0,124.29,33.37Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M149.39,41.5a3.27,3.27,0,0,1-2.32-.93,6.93,6.93,0,0,1-1.59-2.32,14.6,14.6,0,0,1-.9-2.86,12.82,12.82,0,0,1-.3-2.53q.6-.13,1.5-.42a11.3,11.3,0,0,0,1.85-.76,5.34,5.34,0,0,0,1.57-1.21,2.59,2.59,0,0,0,.62-1.74,1.9,1.9,0,0,0-.49-1.35,1.87,1.87,0,0,0-1.4-.51,3.63,3.63,0,0,0-2.28.86,9.66,9.66,0,0,0-2,2.25A23.54,23.54,0,0,0,142,33c-.5,1.08-.95,2.14-1.34,3.16s-.7,1.9-1,2.64a6.15,6.15,0,0,1-.9,1.88,1.58,1.58,0,0,1-1.25.49,1.68,1.68,0,0,1-.71-.19,1.56,1.56,0,0,1-.69-.92,7.51,7.51,0,0,1-.28-2.37,30.16,30.16,0,0,1,.73-6.22,57.39,57.39,0,0,1,2-6.89q1.23-3.51,2.68-6.71a63.72,63.72,0,0,1,3-5.76,26.81,26.81,0,0,1,2.81-4c.88-1,1.6-1.48,2.17-1.48s.9.31,1.23,1a5.24,5.24,0,0,1,.49,2.44,11.08,11.08,0,0,1-.64,3.5,20.06,20.06,0,0,1-1.83,3.9,22.58,22.58,0,0,1-2.81,3.74,18.11,18.11,0,0,1-3.61,3,38.5,38.5,0,0,0-1.85,6,39.22,39.22,0,0,0-.82,5.22q.78-1.91,1.77-3.9a29.81,29.81,0,0,1,2.19-3.74,12.17,12.17,0,0,1,2.6-2.8,4.82,4.82,0,0,1,3-1.07,4.09,4.09,0,0,1,3,1.3,4.43,4.43,0,0,1,1.29,3.25,4.19,4.19,0,0,1-.69,2.41,6.22,6.22,0,0,1-1.69,1.7,8,8,0,0,1-2,1,7.55,7.55,0,0,1-1.77.38c.09.4.21.91.37,1.53a10.74,10.74,0,0,0,.62,1.81,4.8,4.8,0,0,0,1,1.48,1.91,1.91,0,0,0,1.42.61,3.34,3.34,0,0,0,2.21-1.07,14.4,14.4,0,0,0,2.24-2.83,25.34,25.34,0,0,0,1.95-3.9l.6.79a20.86,20.86,0,0,1-2,4.45,13.46,13.46,0,0,1-2.81,3.46A4.88,4.88,0,0,1,149.39,41.5ZM143.24,21a26.43,26.43,0,0,0,2.45-2.77,20.07,20.07,0,0,0,2.22-3.52,8.81,8.81,0,0,0,1-3.6V11a5,5,0,0,0-1.35,1.6,38.52,38.52,0,0,0-2,3.6C144.73,17.6,144,19.21,143.24,21Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M159.32,41.12a2.92,2.92,0,0,1-2.82-1.46,7.13,7.13,0,0,1-.79-3.46,22,22,0,0,1,.77-5.33,61.26,61.26,0,0,1,2-6.43c.85-2.25,1.78-4.44,2.79-6.6s2-4.09,3.08-5.82a31.88,31.88,0,0,1,2.85-4.16c.88-1,1.59-1.55,2.13-1.55a1.13,1.13,0,0,1,.95.62,6.18,6.18,0,0,1,.62,1.49,6,6,0,0,1,.24,1.6,12.67,12.67,0,0,1-.58,3.27A37.13,37.13,0,0,1,169,17.73a50.84,50.84,0,0,1-2.49,4.94,42.58,42.58,0,0,1-3.18,4.81,25.09,25.09,0,0,1-3.72,3.94c-.11.78-.22,1.54-.32,2.3a16,16,0,0,0-.15,2.07,4.39,4.39,0,0,0,.52,2.43A1.54,1.54,0,0,0,161,39a3.33,3.33,0,0,0,2.21-1.09,14.26,14.26,0,0,0,2.19-2.79,27.12,27.12,0,0,0,1.83-3.5l1,.6a22.81,22.81,0,0,1-4.13,6.52A6.56,6.56,0,0,1,159.32,41.12Zm1-12.58a37.6,37.6,0,0,0,2.77-3.73q1.31-2,2.41-4.11c.73-1.39,1.36-2.74,1.91-4a36.21,36.21,0,0,0,1.27-3.51,11.32,11.32,0,0,0,.49-2.48v-.14c-.23,0-.6.35-1.1,1.05a32.47,32.47,0,0,0-1.78,2.85q-1,1.82-2.13,4.13t-2.1,4.9Q161.07,26,160.35,28.54Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M169.58,41.12a3.38,3.38,0,0,1-2.83-1.2A4.62,4.62,0,0,1,165.8,37a13.47,13.47,0,0,1,.35-2.69,29.16,29.16,0,0,1,1-3.41,27.76,27.76,0,0,1,1.44-3.37A11.31,11.31,0,0,1,170.32,25a2.66,2.66,0,0,1,1.89-1,1.28,1.28,0,0,1,.9.35,1.25,1.25,0,0,1,.39,1,4.35,4.35,0,0,1-.67,1.81c-.45.81-1,1.74-1.55,2.79a27.12,27.12,0,0,0-1.54,3.27,8.73,8.73,0,0,0-.67,3.23,2.82,2.82,0,0,0,.49,2,2,2,0,0,0,1.44.49,4.92,4.92,0,0,0,3.18-1.58,17.92,17.92,0,0,0,3.57-5.52l.64.74a17,17,0,0,1-3.82,6.36A7,7,0,0,1,169.58,41.12Zm5.08-20.93a2.68,2.68,0,0,1-1.49-.44,1.54,1.54,0,0,1-.66-1.37,2.3,2.3,0,0,1,1.11-1.91,4,4,0,0,1,2.32-.78,2.25,2.25,0,0,1,1.32.37,1.52,1.52,0,0,1,.53,1.34,2.57,2.57,0,0,1-1,1.88A3.09,3.09,0,0,1,174.66,20.19Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M181.66,42.1a5.79,5.79,0,0,1-3.46-1,5.28,5.28,0,0,1-1.87-2.42,8.34,8.34,0,0,1-.56-3,5.09,5.09,0,0,1,.35-2.09,2.55,2.55,0,0,1,.86-1.09,5.2,5.2,0,0,1,1-.53,2.15,2.15,0,0,0,.67-.42,35,35,0,0,0,2.21-2.81,18.63,18.63,0,0,0,2-3.55v-.47a4.45,4.45,0,0,1,.58-2.62,1.66,1.66,0,0,1,1.35-.77,1,1,0,0,1,.75.28,1.23,1.23,0,0,1,.28.65,2.06,2.06,0,0,1-.11.65,4,4,0,0,0-.1,1,9.66,9.66,0,0,0,.62,3.18c.41,1.2.83,2.46,1.25,3.79A13.78,13.78,0,0,1,188,35c0,.37,0,.71,0,1s-.08.61-.13.89a6.31,6.31,0,0,0,2.62-1.61,12.47,12.47,0,0,0,2.45-3.64l.51.42a10.43,10.43,0,0,1-2.36,4.25,6.58,6.58,0,0,1-3.61,2,5.2,5.2,0,0,1-2.26,2.83A6.78,6.78,0,0,1,181.66,42.1Zm-.3-2a2.92,2.92,0,0,0,1.59-.47,3.25,3.25,0,0,0,1.16-1.34,8.46,8.46,0,0,1-3.18-1.49,9.61,9.61,0,0,1-2.19-2.18c-.4.09-.6.51-.6,1.25a5.51,5.51,0,0,0,.81,2.88A2.65,2.65,0,0,0,181.36,40.06Zm3.09-3.21a3.84,3.84,0,0,0,.11-.76c0-.3,0-.52,0-.68a20.55,20.55,0,0,0-.51-4.13c-.32-1.36-.63-2.75-.95-4.18-.52,1-1.12,2-1.8,3.11a19.93,19.93,0,0,1-1.94,2.7,8.57,8.57,0,0,0,2,2.37A8,8,0,0,0,184.45,36.85Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M4,20.65H22.28l7.31,7.89H11.32L4,20.65Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M4,40.38l7.3-7.89H29.59l-7.31,7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,32.49H51.5l7.31,7.89H40.55l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M40.55.92V20.65l-7.31,7.89V8.81L40.55.92Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M29.59,32.49V52.22l-7.31,7.89V40.38l7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,28.54l7.31-7.89H58.81L51.5,28.54Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M22.28.92l7.31,7.89V28.54l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,32.49l7.31,7.89V60.11l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M47.28,47.28h.27l.39,1.59-.34.1a2.67,2.67,0,0,0-.87-1,1.59,1.59,0,0,0-1-.33,1.41,1.41,0,0,0-.59.12,1,1,0,0,0-.39.3.68.68,0,0,0-.12.38.58.58,0,0,0,.1.33.76.76,0,0,0,.3.29,3.22,3.22,0,0,0,.67.26l1,.29a3.89,3.89,0,0,1,.52.2,2.12,2.12,0,0,1,.53.31,1.28,1.28,0,0,1,.4.92,1.39,1.39,0,0,1-.54,1.08,2,2,0,0,1-1.39.46,2.15,2.15,0,0,1-.79-.13,2.56,2.56,0,0,1-.75-.45l-.17.49h-.26l-.35-1.85.35-.07a2.65,2.65,0,0,0,.5.87,2.17,2.17,0,0,0,.69.54,1.79,1.79,0,0,0,.77.19,1.44,1.44,0,0,0,.94-.33.8.8,0,0,0,.33-.63.73.73,0,0,0-.24-.56,2.53,2.53,0,0,0-.87-.4l-.93-.26a2.44,2.44,0,0,1-1.1-.58,1.17,1.17,0,0,1-.32-.81,1.12,1.12,0,0,1,.21-.65,1.53,1.53,0,0,1,.64-.51,2.12,2.12,0,0,1,.94-.2,2.48,2.48,0,0,1,.82.14,1.63,1.63,0,0,1,.51.3Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M52,51.41l.24.19a2,2,0,0,1-1.67.93A1.71,1.71,0,0,1,49.3,52a1.85,1.85,0,0,1-.52-1.34,2,2,0,0,1,.24-1,1.64,1.64,0,0,1,.67-.66,2,2,0,0,1,1-.24,1.76,1.76,0,0,1,1.07.31.85.85,0,0,1,.42.63.35.35,0,0,1-.1.27.33.33,0,0,1-.22.09.2.2,0,0,1-.13,0,.31.31,0,0,1-.13-.1.85.85,0,0,1-.11-.21,2,2,0,0,0-.16-.29.7.7,0,0,0-.28-.23.79.79,0,0,0-.38-.09,1,1,0,0,0-.58.18,1.08,1.08,0,0,0-.42.51,1.86,1.86,0,0,0-.16.81,1.66,1.66,0,0,0,.39,1.16,1,1,0,0,0,.8.37,1.75,1.75,0,0,0,.44-.07,1.49,1.49,0,0,0,.41-.22A2.83,2.83,0,0,0,52,51.41Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M52.7,47.64v-.32a3.68,3.68,0,0,0,.56-.11,2.62,2.62,0,0,0,.42-.2H54v2.45a3.27,3.27,0,0,1,.8-.52,1.77,1.77,0,0,1,.71-.16,1.13,1.13,0,0,1,.82.3.9.9,0,0,1,.32.72v1.82a.85.85,0,0,0,0,.35.21.21,0,0,0,.14.12,1.37,1.37,0,0,0,.45,0v.31H55.33v-.31h.14a1,1,0,0,0,.36,0A.28.28,0,0,0,56,52a1.23,1.23,0,0,0,0-.41V50.28a3.12,3.12,0,0,0,0-.55.61.61,0,0,0-.23-.34.7.7,0,0,0-.44-.13,1.53,1.53,0,0,0-.64.15,3.11,3.11,0,0,0-.69.45v1.86A.72.72,0,0,0,54,52a.25.25,0,0,0,.12.11,1.24,1.24,0,0,0,.34,0h.16v.31H52.7v-.31h.11a.84.84,0,0,0,.4-.07.19.19,0,0,0,.09-.13,2.56,2.56,0,0,0,0-.53V47.64Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M57.52,48.87l1.4-.06V51.2a1.18,1.18,0,0,0,.09.5.61.61,0,0,0,.27.28.82.82,0,0,0,.42.11,1.85,1.85,0,0,0,.67-.16,3.15,3.15,0,0,0,.75-.48V49.73a.9.9,0,0,0,0-.36.29.29,0,0,0-.15-.14.78.78,0,0,0-.32,0h-.27v-.31l1.4-.06v2.77a1,1,0,0,0,0,.36.28.28,0,0,0,.13.15.9.9,0,0,0,.35,0h.11v.31l-1.23.06v-.68a4.14,4.14,0,0,1-.88.56,2.07,2.07,0,0,1-.77.15,1.5,1.5,0,0,1-.5-.08,1,1,0,0,1-.62-.61,1.18,1.18,0,0,1-.09-.46V49.69a.76.76,0,0,0,0-.31.31.31,0,0,0-.13-.15.6.6,0,0,0-.26,0h-.34Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M62.85,47.63v-.31a2.34,2.34,0,0,0,1-.31h.28v4.56a.9.9,0,0,0,0,.36.29.29,0,0,0,.14.15.88.88,0,0,0,.35.05h.12v.31H62.85v-.31h.2a.62.62,0,0,0,.27-.05.34.34,0,0,0,.13-.16,1.37,1.37,0,0,0,0-.38V47.63Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M68.35,51.49l.24.19a3.52,3.52,0,0,1-.52.51,1.64,1.64,0,0,1-.48.26,1.69,1.69,0,0,1-.53.08,1.74,1.74,0,0,1-1.6-.89,2,2,0,0,1-.23-1,2.08,2.08,0,0,1,.22-1,1.64,1.64,0,0,1,.63-.65,1.85,1.85,0,0,1,.94-.24,1.78,1.78,0,0,1,.69.12,1.33,1.33,0,0,1,.5.38,1.72,1.72,0,0,1,.3.57,2,2,0,0,1,0,.48H66c0,.13,0,.22,0,.28a2,2,0,0,0,.16.83,1.25,1.25,0,0,0,.43.54,1.14,1.14,0,0,0,.61.17A1.25,1.25,0,0,0,67.7,52,2.56,2.56,0,0,0,68.35,51.49ZM67.88,50a1.32,1.32,0,0,0-.16-.5.67.67,0,0,0-.29-.26.82.82,0,0,0-.42-.1.91.91,0,0,0-.43.1.88.88,0,0,0-.34.26A1.66,1.66,0,0,0,66,50Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M71.56,48.87h.63v-.56a1.27,1.27,0,0,1,.38-.94,1.4,1.4,0,0,1,1-.36,1.06,1.06,0,0,1,.64.17.48.48,0,0,1,.23.37.29.29,0,0,1-.09.22.32.32,0,0,1-.23.09.46.46,0,0,1-.21,0,1.46,1.46,0,0,1-.28-.22.53.53,0,0,0-.37-.19.37.37,0,0,0-.22.08.4.4,0,0,0-.15.22,1.28,1.28,0,0,0-.06.45v.71h.7v.38h-.7v2.4a1.11,1.11,0,0,0,0,.33.22.22,0,0,0,.11.12,1.23,1.23,0,0,0,.35,0h.15v.31H71.56v-.31h.12a1,1,0,0,0,.37,0,.19.19,0,0,0,.11-.12.87.87,0,0,0,0-.38V49.25h-.63Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M73.82,48.87l1.41-.06V51.2a1.18,1.18,0,0,0,.09.5.55.55,0,0,0,.26.28.82.82,0,0,0,.42.11,1.85,1.85,0,0,0,.67-.16,3.15,3.15,0,0,0,.75-.48V49.73a.9.9,0,0,0,0-.36.24.24,0,0,0-.14-.14.87.87,0,0,0-.33,0h-.27v-.31l1.41-.06v2.77a1,1,0,0,0,0,.36.28.28,0,0,0,.13.15.94.94,0,0,0,.35,0h.12v.31l-1.23.06v-.68a4.17,4.17,0,0,1-.89.56,2.06,2.06,0,0,1-.76.15,1.56,1.56,0,0,1-.51-.08,1.1,1.1,0,0,1-.37-.23,1,1,0,0,1-.25-.38,1.39,1.39,0,0,1-.08-.46V49.69a1,1,0,0,0,0-.31.33.33,0,0,0-.14-.15.58.58,0,0,0-.25,0h-.35Zm1.89-1.53a.44.44,0,0,1,.45.45.42.42,0,0,1-.13.32.44.44,0,0,1-.64,0,.42.42,0,0,1-.13-.32.44.44,0,0,1,.13-.32A.42.42,0,0,1,75.71,47.34Zm1.24,0a.44.44,0,0,1,.32.13.48.48,0,0,1,.13.32.46.46,0,0,1-.13.32.45.45,0,0,1-.32.14.42.42,0,0,1-.32-.14.43.43,0,0,1-.14-.32.45.45,0,0,1,.14-.32A.41.41,0,0,1,77,47.34Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M79.2,49.4v-.31a2.27,2.27,0,0,0,.48-.09,2.44,2.44,0,0,0,.48-.22h.27v.7A2,2,0,0,1,81,49a1.32,1.32,0,0,1,.63-.19.63.63,0,0,1,.43.13.38.38,0,0,1,.16.31.33.33,0,0,1-.1.24.35.35,0,0,1-.26.1l-.11,0-.14-.08a.66.66,0,0,0-.34-.13.74.74,0,0,0-.41.15,2.21,2.21,0,0,0-.44.4v1.7a1.7,1.7,0,0,0,0,.3.32.32,0,0,0,.08.14.25.25,0,0,0,.14.08.9.9,0,0,0,.28,0h.13v.31H79.19v-.31h.16a.68.68,0,0,0,.3,0,.4.4,0,0,0,.14-.15,1,1,0,0,0,0-.36V49.4Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M85,47.63v-.31a2.39,2.39,0,0,0,1-.31h.28v4.56a1.27,1.27,0,0,0,0,.36.34.34,0,0,0,.14.15,1,1,0,0,0,.36.05h.12v.31H85v-.31h.19a.67.67,0,0,0,.28-.05.34.34,0,0,0,.13-.16,1.51,1.51,0,0,0,0-.38V47.63Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M90.45,51.49l.24.19a3.55,3.55,0,0,1-.53.51,1.52,1.52,0,0,1-.48.26,1.64,1.64,0,0,1-.53.08,1.77,1.77,0,0,1-.92-.22,1.73,1.73,0,0,1-.67-.67,1.88,1.88,0,0,1-.23-1,2.08,2.08,0,0,1,.22-1,1.64,1.64,0,0,1,.63-.65,1.83,1.83,0,0,1,.94-.24,1.78,1.78,0,0,1,.69.12,1.33,1.33,0,0,1,.5.38,1.54,1.54,0,0,1,.29.57,1.56,1.56,0,0,1,.06.48H88c0,.13,0,.22,0,.28a2,2,0,0,0,.17.83,1.18,1.18,0,0,0,.43.54,1.14,1.14,0,0,0,.61.17A1.25,1.25,0,0,0,89.8,52,2.74,2.74,0,0,0,90.45,51.49ZM90,50a1.32,1.32,0,0,0-.16-.5.67.67,0,0,0-.29-.26.82.82,0,0,0-.42-.1,1,1,0,0,0-.44.1,1,1,0,0,0-.34.26,1.67,1.67,0,0,0-.23.5Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M92.22,47.61h.26v1.26h1.1v.37h-1.1v2.23a1.28,1.28,0,0,0,0,.41.41.41,0,0,0,.17.2.5.5,0,0,0,.27.08,1.39,1.39,0,0,0,.78-.32v.36a1.75,1.75,0,0,1-1,.33,1,1,0,0,1-.51-.13.73.73,0,0,1-.31-.29,1.62,1.62,0,0,1-.08-.59V49.24h-.65v-.31a1.22,1.22,0,0,0,.5-.22,1,1,0,0,0,.31-.39A2.37,2.37,0,0,0,92.22,47.61Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M95.11,47.61h.27v1.26h1.1v.37h-1.1v2.23a1,1,0,0,0,.05.41.41.41,0,0,0,.17.2.5.5,0,0,0,.27.08,1.34,1.34,0,0,0,.77-.32v.36a1.72,1.72,0,0,1-1,.33,1,1,0,0,1-.51-.13.68.68,0,0,1-.31-.29,1.42,1.42,0,0,1-.08-.59V49.24h-.65v-.31a1.28,1.28,0,0,0,.5-.22,1.1,1.1,0,0,0,.31-.39A2.42,2.42,0,0,0,95.11,47.61Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M97,49.4v-.31a2.83,2.83,0,0,0,.52-.1,4.42,4.42,0,0,0,.48-.21h.27v3a.47.47,0,0,0,0,.23.24.24,0,0,0,.13.11.63.63,0,0,0,.29,0h.17v.31H97v-.31h.16a.85.85,0,0,0,.33,0,.25.25,0,0,0,.12-.13,1.4,1.4,0,0,0,0-.38V49.4Zm.94-2.29a.45.45,0,0,1,.31.12.44.44,0,0,1,.12.3.41.41,0,0,1-.43.43.42.42,0,0,1-.3-.12.42.42,0,0,1,0-.61A.42.42,0,0,1,97.94,47.11Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M101.83,48.87h.26l.33,1.13-.33.11a1.66,1.66,0,0,0-.56-.71,1.22,1.22,0,0,0-.73-.27.77.77,0,0,0-.47.14.4.4,0,0,0-.17.32.39.39,0,0,0,.13.3,1.64,1.64,0,0,0,.56.28l.72.26a2.11,2.11,0,0,1,.84.46.87.87,0,0,1,.21.57.91.91,0,0,1-.16.52,1.1,1.1,0,0,1-.47.4,1.58,1.58,0,0,1-.71.15,1.67,1.67,0,0,1-1.07-.36l-.07.27h-.27l-.46-1.21.33-.13a3,3,0,0,0,.64.75,1.48,1.48,0,0,0,.91.32.84.84,0,0,0,.53-.17.51.51,0,0,0,.21-.39.52.52,0,0,0-.13-.32,1.42,1.42,0,0,0-.52-.27l-.85-.31a1.92,1.92,0,0,1-.77-.44.8.8,0,0,1-.21-.54.85.85,0,0,1,.35-.66,1.31,1.31,0,0,1,.89-.29,1.55,1.55,0,0,1,.5.07,1.47,1.47,0,0,1,.44.24Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M106.56,51.41l.23.19a2,2,0,0,1-1.67.93,1.72,1.72,0,0,1-1.28-.54,1.85,1.85,0,0,1-.52-1.34,2,2,0,0,1,.24-1,1.66,1.66,0,0,1,.68-.66,2,2,0,0,1,1-.24,1.75,1.75,0,0,1,1.06.31.85.85,0,0,1,.42.63.38.38,0,0,1-.09.27.35.35,0,0,1-.23.09.2.2,0,0,1-.13,0,.38.38,0,0,1-.13-.1.85.85,0,0,1-.11-.21,2.31,2.31,0,0,0-.15-.29.73.73,0,0,0-.29-.23.75.75,0,0,0-.37-.09,1,1,0,0,0-.59.18,1.14,1.14,0,0,0-.42.51,1.86,1.86,0,0,0-.16.81,1.66,1.66,0,0,0,.39,1.16,1,1,0,0,0,.8.37,1.68,1.68,0,0,0,.44-.07,1.36,1.36,0,0,0,.41-.22A2.77,2.77,0,0,0,106.56,51.41Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M107.24,47.64v-.32a3.44,3.44,0,0,0,.56-.11,2.62,2.62,0,0,0,.42-.2h.29v2.45a3.24,3.24,0,0,1,.79-.52,1.78,1.78,0,0,1,.72-.16,1.17,1.17,0,0,1,.82.3.93.93,0,0,1,.32.72v1.82a.9.9,0,0,0,0,.35.24.24,0,0,0,.14.12,1.45,1.45,0,0,0,.45,0v.31h-1.92v-.31H110a.93.93,0,0,0,.36,0,.24.24,0,0,0,.12-.14,1.23,1.23,0,0,0,0-.41V50.28a3.12,3.12,0,0,0,0-.55.57.57,0,0,0-.23-.34.68.68,0,0,0-.44-.13,1.6,1.6,0,0,0-.64.15,2.85,2.85,0,0,0-.68.45v1.86a.78.78,0,0,0,0,.27.24.24,0,0,0,.13.11,1.2,1.2,0,0,0,.34,0h.15v.31h-1.92v-.31h.12a.77.77,0,0,0,.39-.07.17.17,0,0,0,.09-.13,2.56,2.56,0,0,0,0-.53V47.64Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M115.43,51.49l.24.19a3.52,3.52,0,0,1-.52.51,1.64,1.64,0,0,1-.48.26,1.69,1.69,0,0,1-.53.08,1.74,1.74,0,0,1-1.6-.89,2,2,0,0,1-.23-1,2.08,2.08,0,0,1,.22-1,1.6,1.6,0,0,1,.64-.65,2,2,0,0,1,1.62-.12,1.33,1.33,0,0,1,.5.38,1.54,1.54,0,0,1,.29.57,1.56,1.56,0,0,1,.06.48H113c0,.13,0,.22,0,.28a2,2,0,0,0,.17.83,1.25,1.25,0,0,0,.43.54,1.14,1.14,0,0,0,.61.17,1.25,1.25,0,0,0,.56-.14A2.56,2.56,0,0,0,115.43,51.49ZM115,50a1.32,1.32,0,0,0-.16-.5.67.67,0,0,0-.29-.26.82.82,0,0,0-.42-.1.91.91,0,0,0-.43.1.88.88,0,0,0-.34.26,1.66,1.66,0,0,0-.24.5Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M122.23,47.28h.26l.4,1.59-.35.1a2.57,2.57,0,0,0-.86-1,1.61,1.61,0,0,0-1-.33,1.38,1.38,0,0,0-.59.12,1,1,0,0,0-.39.3.7.7,0,0,0-.13.38.67.67,0,0,0,.1.33A.87.87,0,0,0,120,49a3.08,3.08,0,0,0,.66.26l1,.29a3.89,3.89,0,0,1,.52.2,2,2,0,0,1,.52.31,1.12,1.12,0,0,1,.3.41,1.23,1.23,0,0,1,.11.51,1.39,1.39,0,0,1-.54,1.08,2,2,0,0,1-1.39.46,2.15,2.15,0,0,1-.79-.13,2.48,2.48,0,0,1-.76-.45l-.17.49h-.26l-.34-1.85.34-.07a2.85,2.85,0,0,0,.51.87,2.06,2.06,0,0,0,.69.54,1.79,1.79,0,0,0,.77.19,1.39,1.39,0,0,0,.93-.33.78.78,0,0,0,.34-.63.73.73,0,0,0-.24-.56,2.71,2.71,0,0,0-.88-.4l-.92-.26a2.39,2.39,0,0,1-1.1-.58,1.17,1.17,0,0,1-.32-.81,1.12,1.12,0,0,1,.21-.65,1.53,1.53,0,0,1,.64-.51,2.09,2.09,0,0,1,.94-.2,2.52,2.52,0,0,1,.82.14,1.63,1.63,0,0,1,.51.3Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M123.65,49.4v-.31a1.49,1.49,0,0,0,.49-.07,2.09,2.09,0,0,0,.47-.24h.29v.62a3.72,3.72,0,0,1,.77-.5,1.71,1.71,0,0,1,.62-.12,1.4,1.4,0,0,1,1.08.49,1.89,1.89,0,0,1,.45,1.3,2,2,0,0,1-.51,1.44,1.52,1.52,0,0,1-1.19.52,1.77,1.77,0,0,1-.69-.14,2.29,2.29,0,0,1-.53-.34v1.22a1.56,1.56,0,0,0,0,.32.24.24,0,0,0,.07.12.35.35,0,0,0,.14.06h.43v.31h-1.91v-.31h.17a.79.79,0,0,0,.33,0,.19.19,0,0,0,.11-.12,1.49,1.49,0,0,0,0-.38V49.4Zm1.25.38v1.27a1.81,1.81,0,0,0,0,.38.8.8,0,0,0,.18.34,1,1,0,0,0,.34.26,1.06,1.06,0,0,0,.46.1,1.15,1.15,0,0,0,.84-.36,1.54,1.54,0,0,0,.35-1.1,1.71,1.71,0,0,0-.29-1.06.88.88,0,0,0-.69-.38,1.36,1.36,0,0,0-.56.14A3.31,3.31,0,0,0,124.9,49.78Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M128.4,49.4v-.31a2.16,2.16,0,0,0,.48-.09,2.44,2.44,0,0,0,.48-.22h.28v.7a1.85,1.85,0,0,1,.61-.51,1.29,1.29,0,0,1,.63-.19.65.65,0,0,1,.43.13.38.38,0,0,1,.16.31.34.34,0,0,1-.11.24.33.33,0,0,1-.25.1l-.12,0-.14-.08a.63.63,0,0,0-.34-.13.77.77,0,0,0-.41.15,3,3,0,0,0-.44.4v1.7a1,1,0,0,0,0,.3.25.25,0,0,0,.08.14.22.22,0,0,0,.14.08.85.85,0,0,0,.28,0h.12v.31H128.4v-.31h.16a.6.6,0,0,0,.29,0,.43.43,0,0,0,.15-.15,1,1,0,0,0,0-.36V49.4Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M134.42,50.3v-.41a.66.66,0,0,0-.23-.58,1,1,0,0,0-.65-.18,1.3,1.3,0,0,0-.36,0,.61.61,0,0,0-.25.14.56.56,0,0,0-.14.2s0,.13-.09.31-.17.33-.34.33a.32.32,0,0,1-.24-.09.4.4,0,0,1-.09-.27.8.8,0,0,1,.18-.48,1.32,1.32,0,0,1,.55-.39,2.35,2.35,0,0,1,.88-.15,2.14,2.14,0,0,1,.81.14,1.05,1.05,0,0,1,.5.42,1.57,1.57,0,0,1,.11.69v1.43a2.75,2.75,0,0,0,0,.39.41.41,0,0,0,.14.24.46.46,0,0,0,.27.08,1.34,1.34,0,0,0,.4-.08v.33a2.35,2.35,0,0,1-.55.08,1.16,1.16,0,0,1-.44-.07.73.73,0,0,1-.3-.22.71.71,0,0,1-.14-.33,3.27,3.27,0,0,1-.81.48,1.84,1.84,0,0,1-.72.14,1.24,1.24,0,0,1-.83-.27.84.84,0,0,1-.32-.65.8.8,0,0,1,.12-.43.92.92,0,0,1,.35-.36,2.31,2.31,0,0,1,.75-.28A11.68,11.68,0,0,1,134.42,50.3Zm0,.32c-.57.08-.93.14-1.06.17a1.93,1.93,0,0,0-.58.21.64.64,0,0,0-.25.24.54.54,0,0,0,0,.55.55.55,0,0,0,.24.21.69.69,0,0,0,.32.08,1.47,1.47,0,0,0,.54-.12,3.66,3.66,0,0,0,.78-.44Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M139.45,51.41l.23.19a2,2,0,0,1-1.67.93,1.72,1.72,0,0,1-1.28-.54,1.85,1.85,0,0,1-.52-1.34,2,2,0,0,1,.24-1,1.7,1.7,0,0,1,.67-.66,2,2,0,0,1,1-.24,1.75,1.75,0,0,1,1.06.31.85.85,0,0,1,.42.63.35.35,0,0,1-.1.27.31.31,0,0,1-.22.09.2.2,0,0,1-.13,0,.38.38,0,0,1-.13-.1.85.85,0,0,1-.11-.21,2.31,2.31,0,0,0-.15-.29.73.73,0,0,0-.29-.23.75.75,0,0,0-.37-.09,1,1,0,0,0-.59.18,1.08,1.08,0,0,0-.42.51,1.86,1.86,0,0,0-.16.81,1.66,1.66,0,0,0,.39,1.16,1,1,0,0,0,.8.37,1.68,1.68,0,0,0,.44-.07,1.36,1.36,0,0,0,.41-.22A2.77,2.77,0,0,0,139.45,51.41Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M140.13,47.64v-.32a3.44,3.44,0,0,0,.56-.11,2.62,2.62,0,0,0,.42-.2h.29v2.45a3.24,3.24,0,0,1,.79-.52,1.78,1.78,0,0,1,.72-.16,1.17,1.17,0,0,1,.82.3.93.93,0,0,1,.32.72v1.82a.9.9,0,0,0,0,.35.28.28,0,0,0,.14.12,1.45,1.45,0,0,0,.45,0v.31h-1.92v-.31h.15a.9.9,0,0,0,.35,0,.24.24,0,0,0,.12-.14,1.23,1.23,0,0,0,0-.41V50.28a3.12,3.12,0,0,0,0-.55.6.6,0,0,0-.22-.34.73.73,0,0,0-.45-.13,1.6,1.6,0,0,0-.64.15,2.85,2.85,0,0,0-.68.45v1.86a.56.56,0,0,0,0,.27.19.19,0,0,0,.12.11,1.2,1.2,0,0,0,.34,0h.15v.31h-1.92v-.31h.12a.77.77,0,0,0,.39-.07.2.2,0,0,0,.1-.13,4.5,4.5,0,0,0,0-.53V47.64Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M148.32,51.49l.24.19a3.52,3.52,0,0,1-.52.51,1.64,1.64,0,0,1-.48.26,1.69,1.69,0,0,1-.53.08,1.74,1.74,0,0,1-1.6-.89,2,2,0,0,1-.23-1,2.08,2.08,0,0,1,.22-1,1.64,1.64,0,0,1,.63-.65,1.85,1.85,0,0,1,.94-.24,1.78,1.78,0,0,1,.69.12,1.33,1.33,0,0,1,.5.38,1.54,1.54,0,0,1,.29.57,1.56,1.56,0,0,1,.06.48h-2.61c0,.13,0,.22,0,.28a2,2,0,0,0,.17.83,1.25,1.25,0,0,0,.43.54,1.14,1.14,0,0,0,.61.17,1.25,1.25,0,0,0,.56-.14A2.56,2.56,0,0,0,148.32,51.49Zm-.47-1.5a1.32,1.32,0,0,0-.16-.5.67.67,0,0,0-.29-.26.82.82,0,0,0-.42-.1.91.91,0,0,0-.43.1.88.88,0,0,0-.34.26,1.66,1.66,0,0,0-.24.5Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M151.35,48.87l1.41-.06V51.2a1.37,1.37,0,0,0,.08.5.61.61,0,0,0,.27.28.82.82,0,0,0,.42.11,1.85,1.85,0,0,0,.67-.16,3.15,3.15,0,0,0,.75-.48V49.73a.9.9,0,0,0,0-.36.27.27,0,0,0-.14-.14.87.87,0,0,0-.33,0h-.27v-.31l1.41-.06v2.77a1,1,0,0,0,0,.36.28.28,0,0,0,.13.15.94.94,0,0,0,.35,0h.12v.31L155,52.5v-.68a4.17,4.17,0,0,1-.89.56,2.07,2.07,0,0,1-.77.15,1.5,1.5,0,0,1-.5-.08,1.1,1.1,0,0,1-.37-.23,1,1,0,0,1-.25-.38,1.39,1.39,0,0,1-.08-.46V49.69a1,1,0,0,0,0-.31.4.4,0,0,0-.14-.15.6.6,0,0,0-.26,0h-.34Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M156.73,49.4v-.31a2,2,0,0,0,.49-.09,2.55,2.55,0,0,0,.47-.22H158v.7a3.67,3.67,0,0,1,.84-.56,1.9,1.9,0,0,1,.7-.14,1.25,1.25,0,0,1,.53.1,1,1,0,0,1,.38.28.91.91,0,0,1,.2.39,3.35,3.35,0,0,1,0,.42V51.6a1,1,0,0,0,0,.37.22.22,0,0,0,.13.12,1.49,1.49,0,0,0,.46,0v.31h-1.9v-.31h.1a.91.91,0,0,0,.36-.05.27.27,0,0,0,.14-.14,1.06,1.06,0,0,0,0-.38V50.2a1.53,1.53,0,0,0-.07-.58.54.54,0,0,0-.23-.27.77.77,0,0,0-.42-.1,1.6,1.6,0,0,0-.64.15,2.43,2.43,0,0,0-.65.46v1.7a1.13,1.13,0,0,0,0,.39.27.27,0,0,0,.14.14.81.81,0,0,0,.35,0h.11v.31h-1.91v-.31h.09a.88.88,0,0,0,.38-.05.28.28,0,0,0,.14-.16,2.57,2.57,0,0,0,0-.41V49.4Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M164.06,47.63v-.31a2.08,2.08,0,0,0,.46-.09A3.75,3.75,0,0,0,165,47h.28V51.5a2.37,2.37,0,0,0,0,.38.31.31,0,0,0,.12.2.88.88,0,0,0,.35.05H166v.31l-1.28.06v-.59a2.55,2.55,0,0,1-.69.48,1.73,1.73,0,0,1-.65.14,1.4,1.4,0,0,1-1.12-.54,2,2,0,0,1-.44-1.3,2.07,2.07,0,0,1,.24-1,1.58,1.58,0,0,1,.62-.68,1.53,1.53,0,0,1,.84-.24,1.75,1.75,0,0,1,.65.12,1.62,1.62,0,0,1,.55.37V47.63Zm.62,3.91V50.15a1.06,1.06,0,0,0-.11-.5,1.1,1.1,0,0,0-.37-.33,1.13,1.13,0,0,0-.53-.14,1.08,1.08,0,0,0-.6.18,1.13,1.13,0,0,0-.42.51,1.9,1.9,0,0,0-.15.8,1.86,1.86,0,0,0,.14.77,1.12,1.12,0,0,0,.38.49.88.88,0,0,0,.49.17,1.35,1.35,0,0,0,.57-.15A2.07,2.07,0,0,0,164.68,51.54Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M169,47.28h2v.31h-.14a.68.68,0,0,0-.34.06.29.29,0,0,0-.14.18,1,1,0,0,0,0,.3v2l2-2.05a1.42,1.42,0,0,0,.18-.22.3.3,0,0,0,0-.1.11.11,0,0,0-.06-.1.81.81,0,0,0-.28,0H172v-.31h1.85v.31a1.26,1.26,0,0,0-.4,0,.81.81,0,0,0-.28.14,3.05,3.05,0,0,0-.32.26l-1.39,1.42,1.58,2.1a2.5,2.5,0,0,0,.35.39.83.83,0,0,0,.25.15,1.47,1.47,0,0,0,.42,0v.31h-2.19v-.31h.38a.14.14,0,0,0,.1-.06.12.12,0,0,0,0-.09,1.12,1.12,0,0,0-.25-.44L171,50l-.67.67v1a.7.7,0,0,0,.06.35.28.28,0,0,0,.16.14,1.78,1.78,0,0,0,.44,0v.31h-2v-.31h.15a.62.62,0,0,0,.32-.05.28.28,0,0,0,.13-.15,1.1,1.1,0,0,0,0-.39V48.13a.91.91,0,0,0,0-.36.24.24,0,0,0-.12-.14,1,1,0,0,0-.37,0H169Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M174.19,48.87l1.4-.06V51.2a1.18,1.18,0,0,0,.09.5.64.64,0,0,0,.26.28.83.83,0,0,0,.43.11,1.9,1.9,0,0,0,.67-.16,3.15,3.15,0,0,0,.75-.48V49.73a1.15,1.15,0,0,0,0-.36.29.29,0,0,0-.15-.14.85.85,0,0,0-.32,0H177v-.31l1.4-.06v2.77a1,1,0,0,0,0,.36.32.32,0,0,0,.13.15,1,1,0,0,0,.36,0h.11v.31l-1.23.06v-.68a4.14,4.14,0,0,1-.88.56,2.11,2.11,0,0,1-.77.15,1.5,1.5,0,0,1-.5-.08,1,1,0,0,1-.38-.23,1,1,0,0,1-.24-.38,1.18,1.18,0,0,1-.09-.46V49.69a.76.76,0,0,0,0-.31.37.37,0,0,0-.13-.15.63.63,0,0,0-.26,0h-.34Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M179.52,47.63v-.31a2.39,2.39,0,0,0,1-.31h.28v4.56a1.27,1.27,0,0,0,0,.36.31.31,0,0,0,.15.15.85.85,0,0,0,.35.05h.12v.31h-1.91v-.31h.19a.67.67,0,0,0,.28-.05.34.34,0,0,0,.13-.16,1.51,1.51,0,0,0,0-.38V47.63Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M182.83,47.61h.26v1.26h1.1v.37h-1.1v2.23a1.28,1.28,0,0,0,0,.41.41.41,0,0,0,.17.2.5.5,0,0,0,.27.08,1.37,1.37,0,0,0,.78-.32v.36a1.74,1.74,0,0,1-1,.33,1,1,0,0,1-.51-.13.71.71,0,0,1-.32-.29,1.62,1.62,0,0,1-.08-.59V49.24h-.65v-.31a1.33,1.33,0,0,0,.51-.22,1.22,1.22,0,0,0,.31-.39A2.78,2.78,0,0,0,182.83,47.61Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M184.55,48.87l1.4-.06V51.2a1.18,1.18,0,0,0,.09.5.64.64,0,0,0,.26.28.83.83,0,0,0,.43.11,1.9,1.9,0,0,0,.67-.16,3.15,3.15,0,0,0,.75-.48V49.73a1.15,1.15,0,0,0,0-.36.29.29,0,0,0-.15-.14.85.85,0,0,0-.32,0h-.27v-.31l1.4-.06v2.77a1,1,0,0,0,0,.36.29.29,0,0,0,.14.15.87.87,0,0,0,.35,0h.11v.31l-1.23.06v-.68a4.14,4.14,0,0,1-.88.56,2.11,2.11,0,0,1-.77.15,1.5,1.5,0,0,1-.5-.08,1,1,0,0,1-.38-.23,1,1,0,0,1-.24-.38,1.18,1.18,0,0,1-.09-.46V49.69a.76.76,0,0,0,0-.31.37.37,0,0,0-.13-.15.63.63,0,0,0-.26,0h-.34Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M189.92,49.4v-.31a2.16,2.16,0,0,0,.48-.09,2.44,2.44,0,0,0,.48-.22h.28v.7a1.76,1.76,0,0,1,.61-.51,1.29,1.29,0,0,1,.62-.19.68.68,0,0,1,.44.13.4.4,0,0,1,.15.31.33.33,0,0,1-.1.24.35.35,0,0,1-.26.1l-.11,0-.14-.08a.66.66,0,0,0-.34-.13.77.77,0,0,0-.41.15,2.53,2.53,0,0,0-.44.4v1.7a1,1,0,0,0,0,.3.31.31,0,0,0,.07.14.29.29,0,0,0,.14.08,1,1,0,0,0,.29,0h.12v.31h-1.92v-.31h.16a.68.68,0,0,0,.3,0,.4.4,0,0,0,.14-.15,1,1,0,0,0,.05-.36V49.4Z"
                    transform="translate(-4.02 -0.92)"
                />
            </svg>
        </div>
    );
};
export default LogoDe;
