import React from "react";

const LogoLv = () => {
    return (
        <div className="LogoLv">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.42 59.19">
                <path
                    d="M64.14,43.77a3.78,3.78,0,0,1-1.64-1.53,3.5,3.5,0,0,1-.34-1.35A9.73,9.73,0,0,1,63,37.53q.84-2.12,2.25-4.85c1-1.83,2-3.73,3.23-5.71-.89-.07-1.74-.11-2.54-.14s-1.83,0-3.09,0h-.69a4,4,0,0,1,1.91-2.34,6.65,6.65,0,0,1,3.07-.68,11.66,11.66,0,0,1,1.53.1l1.65.18q2.16-3.34,4.24-6.31c1.39-2,2.7-3.77,4-5.36s2.36-2.91,3.33-3.93c1.38-1.48,2.38-2.23,3-2.23a2.5,2.5,0,0,1,1.35.52A11.45,11.45,0,0,1,87.77,8a6,6,0,0,0-1.1,1.9Q86,11.46,85.26,13.8t-1.51,5.13Q83,21.72,82.4,24.6t-1,5.5a34.5,34.5,0,0,0-.36,4.62,8.24,8.24,0,0,0,.34,2.76,1.2,1.2,0,0,0,1.16.9c.66,0,1.48-.54,2.45-1.62a24.73,24.73,0,0,0,3.27-5.29l.77.88a19.13,19.13,0,0,1-3.46,6.27,6,6,0,0,1-4.53,2.46,3,3,0,0,1-2.92-1.93,13.06,13.06,0,0,1-.86-5.27c0-.71,0-1.51.12-2.41s.23-2,.43-3.34c-1.26-.22-2.37-.39-3.35-.51l-2.79-.38q-2.07,4-4,8.17T64.14,43.77ZM78.27,25.85c.38-1.82.81-3.67,1.31-5.55s1-3.66,1.59-5.38,1.11-3.26,1.66-4.62A21.39,21.39,0,0,0,80,13.5q-1.59,2.1-3.33,4.92T73.07,24.6c.86.18,1.72.39,2.58.6Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M90.35,41.12A2.74,2.74,0,0,1,88,39.66,6.18,6.18,0,0,1,87,36.3a14.53,14.53,0,0,1,.39-3.07,23.71,23.71,0,0,1,1-3.43A16,16,0,0,1,89.73,27c.44-.74.85-1.11,1.22-1.11a2.09,2.09,0,0,1,.92.35,4.37,4.37,0,0,1,1.1.85,16.92,16.92,0,0,0-1.14,2.28,27.67,27.67,0,0,0-1.18,3.3,11.62,11.62,0,0,0-.52,3.2c0,.12,0,.42.07.88a4.28,4.28,0,0,0,.32,1.3.91.91,0,0,0,.9.6A2.3,2.3,0,0,0,93,37.8a14.2,14.2,0,0,0,1.72-2.22c.59-.92,1.16-1.9,1.72-2.95s1.06-2.07,1.5-3.06.81-1.83,1.1-2.51a6.75,6.75,0,0,1,1,.23,4.19,4.19,0,0,1,1.09.49,1,1,0,0,1,.48.86,6.88,6.88,0,0,1-.41,1.13c-.27.67-.59,1.47-1,2.4S99.58,34,99.31,35a9.79,9.79,0,0,0-.41,2.58,2.51,2.51,0,0,0,.24,1.13.91.91,0,0,0,.92.49,3.93,3.93,0,0,0,2.15-.88,13.35,13.35,0,0,0,2.58-2.55,18.85,18.85,0,0,0,2.36-4l.64.56a18,18,0,0,1-2.44,4.5A13.42,13.42,0,0,1,102.12,40a6,6,0,0,1-3.35,1.13,2.53,2.53,0,0,1-1.87-.62,3.3,3.3,0,0,1-.84-1.63,11.38,11.38,0,0,1-.25-2v-.93c-.49.84-1,1.65-1.59,2.44a10.61,10.61,0,0,1-1.83,2A3.16,3.16,0,0,1,90.35,41.12Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M111.06,42.1a5.79,5.79,0,0,1-3.46-1,5.28,5.28,0,0,1-1.87-2.42,8.34,8.34,0,0,1-.56-3,5.09,5.09,0,0,1,.35-2.09,2.55,2.55,0,0,1,.86-1.09,5.2,5.2,0,0,1,1-.53,2,2,0,0,0,.67-.42,35,35,0,0,0,2.21-2.81,18.63,18.63,0,0,0,2-3.55v-.47a4.45,4.45,0,0,1,.58-2.62,1.68,1.68,0,0,1,1.35-.77.93.93,0,0,1,.75.28,1.23,1.23,0,0,1,.28.65,2.34,2.34,0,0,1-.1.65,3.51,3.51,0,0,0-.11,1,9.66,9.66,0,0,0,.62,3.18c.41,1.2.83,2.46,1.25,3.79a13.78,13.78,0,0,1,.62,4.08c0,.37,0,.71,0,1s-.08.61-.13.89a6.31,6.31,0,0,0,2.62-1.61,12.47,12.47,0,0,0,2.45-3.64l.51.42a10.43,10.43,0,0,1-2.36,4.25,6.58,6.58,0,0,1-3.61,2,5.23,5.23,0,0,1-2.25,2.83A6.84,6.84,0,0,1,111.06,42.1Zm-.3-2a2.92,2.92,0,0,0,1.59-.47,3.25,3.25,0,0,0,1.16-1.34,8.53,8.53,0,0,1-3.18-1.49,9.61,9.61,0,0,1-2.19-2.18c-.4.09-.6.51-.6,1.25a5.51,5.51,0,0,0,.81,2.88A2.65,2.65,0,0,0,110.76,40.06Zm3.09-3.21a3.22,3.22,0,0,0,.11-.76c0-.3,0-.52,0-.68a20.55,20.55,0,0,0-.51-4.13c-.32-1.36-.63-2.75-1-4.18-.51,1-1.12,2-1.8,3.11a19.93,19.93,0,0,1-1.94,2.7,8.57,8.57,0,0,0,2,2.37A8,8,0,0,0,113.85,36.85Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M126.1,41.77a5.47,5.47,0,0,1-4.23-1.6,5.67,5.67,0,0,1-1.49-4,11.3,11.3,0,0,1,1-4.34A17.93,17.93,0,0,1,124,27.5a14.74,14.74,0,0,1,3.61-3.27A7.32,7.32,0,0,1,131.51,23a3.94,3.94,0,0,1,2.13.63,2.71,2.71,0,0,1,1,2.48,6.16,6.16,0,0,1-.94,3.3,10.24,10.24,0,0,1-2.54,2.74,15.18,15.18,0,0,1-3.5,2,15.52,15.52,0,0,1-3.85,1,2.28,2.28,0,0,0-.06.39,6,6,0,0,0,0,.68q0,.23.06.81a3.9,3.9,0,0,0,.37,1.23,2.79,2.79,0,0,0,1,1.11,3.34,3.34,0,0,0,1.89.47,7.12,7.12,0,0,0,3.64-1.09,15,15,0,0,0,3.43-2.9,17.56,17.56,0,0,0,2.69-4l.86.74a17.41,17.41,0,0,1-3,4.87,13.69,13.69,0,0,1-4,3.25A9.54,9.54,0,0,1,126.1,41.77Zm-1.81-8.4a7.78,7.78,0,0,0,2.5-.83,14.51,14.51,0,0,0,2.64-1.77,10.35,10.35,0,0,0,2.08-2.34,4.7,4.7,0,0,0,.82-2.58,1,1,0,0,0-.17-.69.72.72,0,0,0-.56-.19,3.5,3.5,0,0,0-2.06.77,11.41,11.41,0,0,0-2.11,2,17.1,17.1,0,0,0-1.85,2.72A18,18,0,0,0,124.29,33.37Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M149.39,41.5a3.27,3.27,0,0,1-2.32-.93,6.93,6.93,0,0,1-1.59-2.32,14.6,14.6,0,0,1-.9-2.86,12.82,12.82,0,0,1-.3-2.53q.6-.13,1.5-.42a11.3,11.3,0,0,0,1.85-.76,5.34,5.34,0,0,0,1.57-1.21,2.59,2.59,0,0,0,.62-1.74,1.9,1.9,0,0,0-.49-1.35,1.87,1.87,0,0,0-1.4-.51,3.63,3.63,0,0,0-2.28.86,9.66,9.66,0,0,0-2,2.25A23.54,23.54,0,0,0,142,33c-.5,1.08-.95,2.14-1.34,3.16s-.7,1.9-1,2.64a6.15,6.15,0,0,1-.9,1.88,1.58,1.58,0,0,1-1.25.49,1.68,1.68,0,0,1-.71-.19,1.56,1.56,0,0,1-.69-.92,7.51,7.51,0,0,1-.28-2.37,30.16,30.16,0,0,1,.73-6.22,57.39,57.39,0,0,1,2-6.89q1.23-3.51,2.68-6.71a63.72,63.72,0,0,1,3-5.76,26.81,26.81,0,0,1,2.81-4c.88-1,1.6-1.48,2.17-1.48s.9.31,1.23,1a5.24,5.24,0,0,1,.49,2.44,11.08,11.08,0,0,1-.64,3.5,20.06,20.06,0,0,1-1.83,3.9,22.58,22.58,0,0,1-2.81,3.74,18.11,18.11,0,0,1-3.61,3,38.5,38.5,0,0,0-1.85,6,39.22,39.22,0,0,0-.82,5.22q.78-1.91,1.77-3.9a29.81,29.81,0,0,1,2.19-3.74,12.17,12.17,0,0,1,2.6-2.8,4.82,4.82,0,0,1,3-1.07,4.09,4.09,0,0,1,3,1.3,4.43,4.43,0,0,1,1.29,3.25,4.19,4.19,0,0,1-.69,2.41,6.22,6.22,0,0,1-1.69,1.7,8,8,0,0,1-2,1,7.55,7.55,0,0,1-1.77.38c.09.4.21.91.37,1.53a10.74,10.74,0,0,0,.62,1.81,4.8,4.8,0,0,0,1,1.48,1.91,1.91,0,0,0,1.42.61,3.34,3.34,0,0,0,2.21-1.07,14.4,14.4,0,0,0,2.24-2.83,25.34,25.34,0,0,0,1.95-3.9l.6.79a20.86,20.86,0,0,1-2,4.45,13.46,13.46,0,0,1-2.81,3.46A4.88,4.88,0,0,1,149.39,41.5ZM143.24,21a26.43,26.43,0,0,0,2.45-2.77,20.07,20.07,0,0,0,2.22-3.52,8.81,8.81,0,0,0,1-3.6V11a5,5,0,0,0-1.35,1.6,38.52,38.52,0,0,0-2,3.6C144.73,17.6,144,19.21,143.24,21Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M159.32,41.12a2.92,2.92,0,0,1-2.82-1.46,7.13,7.13,0,0,1-.79-3.46,22,22,0,0,1,.77-5.33,61.26,61.26,0,0,1,2-6.43c.85-2.25,1.78-4.44,2.79-6.6s2-4.09,3.08-5.82a31.88,31.88,0,0,1,2.85-4.16c.88-1,1.59-1.55,2.13-1.55a1.13,1.13,0,0,1,.95.62,6.18,6.18,0,0,1,.62,1.49,6,6,0,0,1,.24,1.6,12.67,12.67,0,0,1-.58,3.27A37.13,37.13,0,0,1,169,17.73a50.84,50.84,0,0,1-2.49,4.94,42.58,42.58,0,0,1-3.18,4.81,25.09,25.09,0,0,1-3.72,3.94c-.11.78-.22,1.54-.32,2.3a16,16,0,0,0-.15,2.07,4.39,4.39,0,0,0,.52,2.43A1.54,1.54,0,0,0,161,39a3.33,3.33,0,0,0,2.21-1.09,14.26,14.26,0,0,0,2.19-2.79,27.12,27.12,0,0,0,1.83-3.5l1,.6a22.81,22.81,0,0,1-4.13,6.52A6.56,6.56,0,0,1,159.32,41.12Zm1-12.58a37.6,37.6,0,0,0,2.77-3.73q1.31-2,2.41-4.11c.73-1.39,1.36-2.74,1.91-4a36.21,36.21,0,0,0,1.27-3.51,11.32,11.32,0,0,0,.49-2.48v-.14c-.23,0-.6.35-1.1,1.05a32.47,32.47,0,0,0-1.78,2.85q-1,1.82-2.13,4.13t-2.1,4.9Q161.07,26,160.35,28.54Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M169.58,41.12a3.38,3.38,0,0,1-2.83-1.2A4.62,4.62,0,0,1,165.8,37a13.47,13.47,0,0,1,.35-2.69,29.16,29.16,0,0,1,1-3.41,27.76,27.76,0,0,1,1.44-3.37A11.31,11.31,0,0,1,170.32,25a2.66,2.66,0,0,1,1.89-1,1.28,1.28,0,0,1,.9.35,1.25,1.25,0,0,1,.39,1,4.35,4.35,0,0,1-.67,1.81c-.45.81-1,1.74-1.55,2.79a27.12,27.12,0,0,0-1.54,3.27,8.73,8.73,0,0,0-.67,3.23,2.82,2.82,0,0,0,.49,2,2,2,0,0,0,1.44.49,4.92,4.92,0,0,0,3.18-1.58,17.92,17.92,0,0,0,3.57-5.52l.64.74a17,17,0,0,1-3.82,6.36A7,7,0,0,1,169.58,41.12Zm5.08-20.93a2.68,2.68,0,0,1-1.49-.44,1.54,1.54,0,0,1-.66-1.37,2.3,2.3,0,0,1,1.11-1.91,4,4,0,0,1,2.32-.78,2.25,2.25,0,0,1,1.32.37,1.52,1.52,0,0,1,.53,1.34,2.57,2.57,0,0,1-1,1.88A3.09,3.09,0,0,1,174.66,20.19Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M181.66,42.1a5.79,5.79,0,0,1-3.46-1,5.28,5.28,0,0,1-1.87-2.42,8.34,8.34,0,0,1-.56-3,5.09,5.09,0,0,1,.35-2.09,2.55,2.55,0,0,1,.86-1.09,5.2,5.2,0,0,1,1-.53,2.15,2.15,0,0,0,.67-.42,35,35,0,0,0,2.21-2.81,18.63,18.63,0,0,0,2-3.55v-.47a4.45,4.45,0,0,1,.58-2.62,1.66,1.66,0,0,1,1.35-.77,1,1,0,0,1,.75.28,1.23,1.23,0,0,1,.28.65,2.06,2.06,0,0,1-.11.65,4,4,0,0,0-.1,1,9.66,9.66,0,0,0,.62,3.18c.41,1.2.83,2.46,1.25,3.79A13.78,13.78,0,0,1,188,35c0,.37,0,.71,0,1s-.08.61-.13.89a6.31,6.31,0,0,0,2.62-1.61,12.47,12.47,0,0,0,2.45-3.64l.51.42a10.43,10.43,0,0,1-2.36,4.25,6.58,6.58,0,0,1-3.61,2,5.2,5.2,0,0,1-2.26,2.83A6.78,6.78,0,0,1,181.66,42.1Zm-.3-2a2.92,2.92,0,0,0,1.59-.47,3.25,3.25,0,0,0,1.16-1.34,8.46,8.46,0,0,1-3.18-1.49,9.61,9.61,0,0,1-2.19-2.18c-.4.09-.6.51-.6,1.25a5.51,5.51,0,0,0,.81,2.88A2.65,2.65,0,0,0,181.36,40.06Zm3.09-3.21a3.84,3.84,0,0,0,.11-.76c0-.3,0-.52,0-.68a20.55,20.55,0,0,0-.51-4.13c-.32-1.36-.63-2.75-.95-4.18-.52,1-1.12,2-1.8,3.11a19.93,19.93,0,0,1-1.94,2.7,8.57,8.57,0,0,0,2,2.37A8,8,0,0,0,184.45,36.85Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M4,20.65H22.28l7.31,7.89H11.32L4,20.65Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M4,40.38l7.3-7.89H29.59l-7.31,7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,32.49H51.5l7.31,7.89H40.55l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M40.55.92V20.65l-7.31,7.89V8.81L40.55.92Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M29.59,32.49V52.22l-7.31,7.89V40.38l7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,28.54l7.31-7.89H58.81L51.5,28.54Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M22.28.92l7.31,7.89V28.54l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,32.49l7.31,7.89V60.11l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M43.78,47.21h2v.33h-.18a.73.73,0,0,0-.31.05.27.27,0,0,0-.13.13.74.74,0,0,0,0,.3v3.9a1.78,1.78,0,0,0,0,.33.16.16,0,0,0,.1.1.91.91,0,0,0,.3,0h.28a2.12,2.12,0,0,0,.83-.15,1.74,1.74,0,0,0,.61-.43,3.15,3.15,0,0,0,.49-.86l.33.12-.58,1.75H43.78v-.33H44a.78.78,0,0,0,.32,0,.33.33,0,0,0,.12-.14,1,1,0,0,0,0-.36v-3.8a1.4,1.4,0,0,0,0-.39.28.28,0,0,0-.12-.14.77.77,0,0,0-.33-.05h-.17Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M51.53,50.48V50a.76.76,0,0,0-.23-.63,1,1,0,0,0-.65-.19,1.25,1.25,0,0,0-.36.05.81.81,0,0,0-.26.15.6.6,0,0,0-.14.21s0,.15-.08.35-.18.36-.35.36a.3.3,0,0,1-.24-.11.44.44,0,0,1-.09-.29.91.91,0,0,1,.18-.52,1.31,1.31,0,0,1,.56-.42,2.16,2.16,0,0,1,.88-.16,2,2,0,0,1,.81.15,1.07,1.07,0,0,1,.5.46,1.78,1.78,0,0,1,.11.74v1.55a3.4,3.4,0,0,0,0,.43.44.44,0,0,0,.14.25.4.4,0,0,0,.27.09,1.46,1.46,0,0,0,.41-.08v.35a2,2,0,0,1-.56.09,1.15,1.15,0,0,1-.44-.08.76.76,0,0,1-.3-.23.92.92,0,0,1-.14-.37,3.35,3.35,0,0,1-.81.53,1.87,1.87,0,0,1-.73.15,1.16,1.16,0,0,1-.83-.3,1,1,0,0,1-.32-.7A1,1,0,0,1,49,51.4a1.08,1.08,0,0,1,.35-.39,2.59,2.59,0,0,1,.76-.3A12.77,12.77,0,0,1,51.53,50.48Zm0,.35c-.58.09-.93.15-1.07.19a2,2,0,0,0-.58.22.87.87,0,0,0-.25.26.59.59,0,0,0-.07.29.68.68,0,0,0,.08.31.76.76,0,0,0,.24.23.72.72,0,0,0,.32.08,1.52,1.52,0,0,0,.55-.12,4.39,4.39,0,0,0,.78-.49Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M54.84,47.57h.26v1.36h1.1v.4H55.1v2.42a1.41,1.41,0,0,0,0,.44.46.46,0,0,0,.17.22.44.44,0,0,0,.27.09,1.33,1.33,0,0,0,.78-.35v.39a1.65,1.65,0,0,1-1,.36,1,1,0,0,1-.51-.14.73.73,0,0,1-.31-.32,1.61,1.61,0,0,1-.08-.64V49.33h-.65V49a1.23,1.23,0,0,0,.5-.24,1.21,1.21,0,0,0,.31-.42A2.89,2.89,0,0,0,54.84,47.57Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M57.06,48.93h1.7v.34h-.11a.73.73,0,0,0-.26,0,.18.18,0,0,0-.11.07.2.2,0,0,0,0,.13,1.3,1.3,0,0,0,.08.32l.85,2.28L60,49.81a1.2,1.2,0,0,0,.08-.32.19.19,0,0,0-.07-.16.45.45,0,0,0-.27-.06h-.18v-.34H61v.34a.67.67,0,0,0-.26,0,.42.42,0,0,0-.16.13,2.08,2.08,0,0,0-.17.36l-1.19,3H58.8l-1.2-3.06a1.13,1.13,0,0,0-.16-.31.4.4,0,0,0-.16-.12.51.51,0,0,0-.22,0Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M61.84,49.51v-.34a2.14,2.14,0,0,0,.52-.11,3.21,3.21,0,0,0,.48-.22h.27v3.21a.59.59,0,0,0,0,.26.3.3,0,0,0,.13.12.85.85,0,0,0,.29,0h.17v.33h-1.9v-.33H62a.83.83,0,0,0,.34,0,.33.33,0,0,0,.12-.14,1.64,1.64,0,0,0,0-.41V49.51ZM62.78,47a.42.42,0,0,1,.31.13.51.51,0,0,1,0,.66.42.42,0,0,1-.31.13.39.39,0,0,1-.3-.13.48.48,0,0,1,0-.66A.39.39,0,0,1,62.78,47Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M67.91,51.77l.24.21a3.49,3.49,0,0,1-.52.55,1.8,1.8,0,0,1-.48.29,1.75,1.75,0,0,1-.54.08,1.68,1.68,0,0,1-.92-.24,2,2,0,0,1-.68-.72,2.32,2.32,0,0,1-.23-1A2.41,2.41,0,0,1,65,49.8a1.8,1.8,0,0,1,.63-.71,1.72,1.72,0,0,1,.94-.25,1.6,1.6,0,0,1,.69.13,1.23,1.23,0,0,1,.5.41,1.74,1.74,0,0,1,.3.61,3.25,3.25,0,0,1,.06.53H65.5c0,.13,0,.23,0,.29a2.38,2.38,0,0,0,.16.91,1.34,1.34,0,0,0,.44.58,1.08,1.08,0,0,0,.61.19,1.15,1.15,0,0,0,.56-.15A2.75,2.75,0,0,0,67.91,51.77Zm-.47-1.62a1.65,1.65,0,0,0-.16-.55.71.71,0,0,0-.29-.28.82.82,0,0,0-.42-.1,1,1,0,0,0-.44.1,1.05,1.05,0,0,0-.34.29,1.53,1.53,0,0,0-.23.54Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M71.79,48.93h.26l.33,1.23-.33.12a1.83,1.83,0,0,0-.56-.78,1.24,1.24,0,0,0-.74-.28.69.69,0,0,0-.46.15.45.45,0,0,0-.18.35.48.48,0,0,0,.13.32,1.76,1.76,0,0,0,.57.3l.72.28a2.09,2.09,0,0,1,.84.51.92.92,0,0,1,.22.62,1.16,1.16,0,0,1-.16.56,1.19,1.19,0,0,1-.48.43,1.46,1.46,0,0,1-.71.16,1.57,1.57,0,0,1-1.07-.39l-.08.29h-.26l-.46-1.31.33-.14a3.19,3.19,0,0,0,.64.82,1.45,1.45,0,0,0,.91.34.8.8,0,0,0,.53-.19.54.54,0,0,0,.21-.41.56.56,0,0,0-.13-.35,1.38,1.38,0,0,0-.52-.3l-.85-.33a2,2,0,0,1-.77-.48.87.87,0,0,1-.22-.59.92.92,0,0,1,.35-.71,1.46,1.46,0,0,1,1.84,0ZM71,48.25l-1.08-.76.11-.21,1,.38,1-.38.1.21Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M73.62,48.93l1.4-.06v2.59a1.38,1.38,0,0,0,.09.54.74.74,0,0,0,.27.31.82.82,0,0,0,.42.11,1.7,1.7,0,0,0,.67-.17,2.94,2.94,0,0,0,.76-.52V49.86a1.52,1.52,0,0,0,0-.39.29.29,0,0,0-.15-.14.69.69,0,0,0-.33-.06h-.27v-.34l1.41-.06v3a1.17,1.17,0,0,0,0,.39.28.28,0,0,0,.14.16.82.82,0,0,0,.35,0h.11v.33l-1.23.07v-.74a3.84,3.84,0,0,1-.89.6,1.91,1.91,0,0,1-.77.17,1.5,1.5,0,0,1-.5-.08,1.09,1.09,0,0,1-.62-.66,1.44,1.44,0,0,1-.09-.51V49.83a1,1,0,0,0,0-.35.37.37,0,0,0-.13-.15.5.5,0,0,0-.26-.06h-.34Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M82.32,48.93H84v.34h-.11a.76.76,0,0,0-.26,0,.18.18,0,0,0-.11.07.2.2,0,0,0,0,.13,1.3,1.3,0,0,0,.08.32l.85,2.28.83-2.28a1.09,1.09,0,0,0,.07-.32.19.19,0,0,0-.07-.16.45.45,0,0,0-.27-.06h-.18v-.34h1.47v.34a.58.58,0,0,0-.25,0,.35.35,0,0,0-.16.13,1.88,1.88,0,0,0-.18.36l-1.18,3h-.45l-1.19-3.06a1.49,1.49,0,0,0-.17-.31.44.44,0,0,0-.15-.12.59.59,0,0,0-.23,0Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M89.81,50.48V50a.79.79,0,0,0-.23-.63,1,1,0,0,0-.66-.19,1.3,1.3,0,0,0-.36.05.76.76,0,0,0-.25.15.6.6,0,0,0-.14.21l-.09.35c-.06.24-.17.36-.35.36a.3.3,0,0,1-.23-.11.39.39,0,0,1-.1-.29.86.86,0,0,1,.19-.52,1.28,1.28,0,0,1,.55-.42,2.16,2.16,0,0,1,.88-.16,2,2,0,0,1,.82.15,1,1,0,0,1,.49.46,1.6,1.6,0,0,1,.12.74v1.55a4.26,4.26,0,0,0,0,.43.44.44,0,0,0,.15.25.37.37,0,0,0,.26.09,1.4,1.4,0,0,0,.41-.08v.35a2,2,0,0,1-.55.09,1.17,1.17,0,0,1-.45-.08.73.73,0,0,1-.29-.23.81.81,0,0,1-.15-.37,3.35,3.35,0,0,1-.81.53,1.86,1.86,0,0,1-.72.15,1.18,1.18,0,0,1-.84-.3.94.94,0,0,1-.31-.7,1,1,0,0,1,.11-.47,1.11,1.11,0,0,1,.36-.39,2.51,2.51,0,0,1,.75-.3A13,13,0,0,1,89.81,50.48Zm0,.35c-.57.09-.93.15-1.06.19a1.89,1.89,0,0,0-.58.22.76.76,0,0,0-.25.26.6.6,0,0,0-.08.29.59.59,0,0,0,.09.31.63.63,0,0,0,.23.23.76.76,0,0,0,.33.08,1.47,1.47,0,0,0,.54-.12,3.78,3.78,0,0,0,.78-.49Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M92.09,47.59v-.33a2.18,2.18,0,0,0,1-.34h.28v4.94a1,1,0,0,0,0,.39.3.3,0,0,0,.14.17.88.88,0,0,0,.35,0H94v.33H92.09v-.33h.19a.55.55,0,0,0,.28-.06.37.37,0,0,0,.13-.17,1.76,1.76,0,0,0,0-.41V47.59Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M96.87,48.84a1.81,1.81,0,0,1,.95.24,1.75,1.75,0,0,1,.63.71,2.48,2.48,0,0,1,.23,1.08,2.34,2.34,0,0,1-.24,1.07,1.7,1.7,0,0,1-.64.71,1.8,1.8,0,0,1-1,.25,1.56,1.56,0,0,1-1.35-.61A2.29,2.29,0,0,1,95,50.87a2.27,2.27,0,0,1,.23-1.06,1.71,1.71,0,0,1,.66-.72A1.77,1.77,0,0,1,96.87,48.84Zm0,.39a.91.91,0,0,0-.72.36,2,2,0,0,0-.37,1.3,2.09,2.09,0,0,0,.32,1.26.91.91,0,0,0,.78.39.94.94,0,0,0,.56-.18,1.24,1.24,0,0,0,.4-.58,2.59,2.59,0,0,0,.15-.93,2.75,2.75,0,0,0-.16-.93,1.18,1.18,0,0,0-.38-.51A1,1,0,0,0,96.84,49.23Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M102.15,47.6v-.34a2.17,2.17,0,0,0,.47-.1,4.29,4.29,0,0,0,.52-.24h.28v4.87a2.5,2.5,0,0,0,0,.4.31.31,0,0,0,.12.22.7.7,0,0,0,.35.06h.16v.33l-1.29.07v-.64a2.68,2.68,0,0,1-.7.52,1.51,1.51,0,0,1-.65.15,1.35,1.35,0,0,1-1.12-.58,2.3,2.3,0,0,1-.44-1.41,2.37,2.37,0,0,1,.24-1.07,1.72,1.72,0,0,1,.62-.75,1.5,1.5,0,0,1,.84-.25,1.59,1.59,0,0,1,.66.13,1.72,1.72,0,0,1,.55.4V47.6Zm.63,4.23V50.32a1,1,0,0,0-.12-.53.92.92,0,0,0-.37-.36.94.94,0,0,0-.53-.16,1.06,1.06,0,0,0-.59.19,1.26,1.26,0,0,0-.43.55,2.26,2.26,0,0,0-.15.87,2.09,2.09,0,0,0,.14.83,1.25,1.25,0,0,0,.38.54.85.85,0,0,0,.5.18,1.34,1.34,0,0,0,.56-.15A2.45,2.45,0,0,0,102.78,51.83Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M107.82,50.48V50a.79.79,0,0,0-.23-.63,1,1,0,0,0-.66-.19,1.25,1.25,0,0,0-.36.05.66.66,0,0,0-.25.15.49.49,0,0,0-.14.21l-.09.35c-.06.24-.17.36-.35.36a.3.3,0,0,1-.24-.11.44.44,0,0,1-.09-.29.86.86,0,0,1,.19-.52,1.28,1.28,0,0,1,.55-.42,2.16,2.16,0,0,1,.88-.16,2,2,0,0,1,.82.15,1,1,0,0,1,.49.46,1.6,1.6,0,0,1,.12.74v1.55a4.26,4.26,0,0,0,0,.43.44.44,0,0,0,.15.25.36.36,0,0,0,.26.09,1.4,1.4,0,0,0,.41-.08v.35a2,2,0,0,1-.55.09,1.17,1.17,0,0,1-.45-.08.66.66,0,0,1-.29-.23.81.81,0,0,1-.15-.37,3.35,3.35,0,0,1-.81.53,1.86,1.86,0,0,1-.72.15,1.18,1.18,0,0,1-.84-.3,1,1,0,0,1-.32-.7,1,1,0,0,1,.12-.47,1.11,1.11,0,0,1,.36-.39,2.51,2.51,0,0,1,.75-.3A13,13,0,0,1,107.82,50.48Zm0,.35c-.57.09-.93.15-1.06.19a1.89,1.89,0,0,0-.58.22.76.76,0,0,0-.25.26.6.6,0,0,0-.08.29.59.59,0,0,0,.09.31.63.63,0,0,0,.23.23.76.76,0,0,0,.33.08,1.47,1.47,0,0,0,.54-.12,3.78,3.78,0,0,0,.78-.49Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M112.65,48.93h.26l.34,1.23-.34.12a1.83,1.83,0,0,0-.56-.78,1.24,1.24,0,0,0-.73-.28.72.72,0,0,0-.47.15.45.45,0,0,0-.18.35.48.48,0,0,0,.13.32,1.76,1.76,0,0,0,.57.3l.73.28a2,2,0,0,1,.83.51.92.92,0,0,1,.22.62,1.06,1.06,0,0,1-.16.56,1.19,1.19,0,0,1-.48.43,1.46,1.46,0,0,1-.71.16,1.59,1.59,0,0,1-1.07-.39l-.07.29h-.27l-.46-1.31.33-.14a3.19,3.19,0,0,0,.64.82,1.47,1.47,0,0,0,.91.34.78.78,0,0,0,.53-.19.54.54,0,0,0,.21-.41.51.51,0,0,0-.13-.35,1.41,1.41,0,0,0-.51-.3l-.86-.33a2,2,0,0,1-.77-.48.91.91,0,0,1-.21-.59.89.89,0,0,1,.35-.71,1.49,1.49,0,0,1,1.39-.23,1.31,1.31,0,0,1,.44.25Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M117.5,48.93l1.4-.06v2.59A1.38,1.38,0,0,0,119,52a.74.74,0,0,0,.27.31.82.82,0,0,0,.42.11,1.7,1.7,0,0,0,.67-.17,2.94,2.94,0,0,0,.76-.52V49.86a1.52,1.52,0,0,0,0-.39.29.29,0,0,0-.15-.14.69.69,0,0,0-.33-.06h-.27v-.34l1.41-.06v3a1.1,1.1,0,0,0,0,.39.24.24,0,0,0,.13.16.82.82,0,0,0,.35,0h.11v.33l-1.23.07v-.74a3.84,3.84,0,0,1-.89.6,1.91,1.91,0,0,1-.77.17,1.5,1.5,0,0,1-.5-.08,1,1,0,0,1-.37-.26,1.09,1.09,0,0,1-.25-.4,1.44,1.44,0,0,1-.09-.51V49.83a1,1,0,0,0,0-.35.31.31,0,0,0-.13-.15.5.5,0,0,0-.26-.06h-.34Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M123.46,49.51v-.34a2.72,2.72,0,0,0,.49-.09,3.47,3.47,0,0,0,.48-.24h.26v.76a3.35,3.35,0,0,1,.84-.6,1.59,1.59,0,0,1,.7-.16,1.19,1.19,0,0,1,.54.11.94.94,0,0,1,.38.3.9.9,0,0,1,.19.42,2.49,2.49,0,0,1,0,.45v1.77a1.11,1.11,0,0,0,0,.41.27.27,0,0,0,.13.13,1.84,1.84,0,0,0,.46,0v.33H126.1v-.33h.1a1,1,0,0,0,.37,0,.36.36,0,0,0,.14-.16,1.19,1.19,0,0,0,0-.41V50.38a1.89,1.89,0,0,0-.07-.64.53.53,0,0,0-.23-.28.69.69,0,0,0-.42-.11,1.47,1.47,0,0,0-.64.16,2.46,2.46,0,0,0-.66.49v1.85a1.3,1.3,0,0,0,0,.42.31.31,0,0,0,.14.16,1,1,0,0,0,.35,0h.11v.33h-1.91v-.33h.09a1.07,1.07,0,0,0,.38,0,.37.37,0,0,0,.14-.18,2.9,2.9,0,0,0,0-.44V49.51Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M132,47.59v-.33a2.39,2.39,0,0,0,1-.34h.28v4l1.37-1.17c.22-.19.32-.31.32-.37a.09.09,0,0,0-.07-.09.82.82,0,0,0-.22,0h-.19v-.34h1.88v.34a1.36,1.36,0,0,0-.5.07,1.69,1.69,0,0,0-.44.27l-1.07.9L135.63,52a1.79,1.79,0,0,0,.3.31.64.64,0,0,0,.23.12,2.27,2.27,0,0,0,.36,0v.33h-2v-.33h.16a.7.7,0,0,0,.28,0,.12.12,0,0,0,.06-.1.35.35,0,0,0-.11-.19l0,0-1-1.22-.61.5v.52a1.09,1.09,0,0,0,0,.39.29.29,0,0,0,.13.14.83.83,0,0,0,.34,0h.13v.33H132v-.33a2.13,2.13,0,0,0,.4,0,.31.31,0,0,0,.19-.16,1,1,0,0,0,.06-.43V47.59Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M137.18,48.93l1.41-.06v2.59a1.38,1.38,0,0,0,.09.54.65.65,0,0,0,.26.31.82.82,0,0,0,.42.11,1.74,1.74,0,0,0,.68-.17,3.08,3.08,0,0,0,.75-.52V49.86a1.17,1.17,0,0,0,0-.39.29.29,0,0,0-.15-.14.62.62,0,0,0-.32-.06H140v-.34l1.4-.06v3a1.1,1.1,0,0,0,.05.39.27.27,0,0,0,.13.16.88.88,0,0,0,.35,0h.12v.33l-1.24.07v-.74a3.82,3.82,0,0,1-.88.6,2,2,0,0,1-.77.17,1.56,1.56,0,0,1-.51-.08,1,1,0,0,1-.37-.26,1.09,1.09,0,0,1-.25-.4,1.7,1.7,0,0,1-.08-.51V49.83a1.27,1.27,0,0,0,0-.35.4.4,0,0,0-.14-.15.48.48,0,0,0-.26-.06h-.34Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M143.1,47.59v-.33a2.18,2.18,0,0,0,1-.34h.28v4.94a1,1,0,0,0,0,.39.3.3,0,0,0,.14.17.88.88,0,0,0,.35,0H145v.33H143.1v-.33h.19a.55.55,0,0,0,.28-.06.37.37,0,0,0,.13-.17,1.76,1.76,0,0,0,0-.41V47.59Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M147,47.57h.26v1.36h1.1v.4h-1.1v2.42a1.41,1.41,0,0,0,.05.44.46.46,0,0,0,.17.22.44.44,0,0,0,.27.09,1.33,1.33,0,0,0,.78-.35v.39a1.65,1.65,0,0,1-1,.36,1,1,0,0,1-.51-.14.68.68,0,0,1-.31-.32,1.57,1.57,0,0,1-.09-.64V49.33H146V49a1.23,1.23,0,0,0,.5-.24,1.21,1.21,0,0,0,.31-.42A2.89,2.89,0,0,0,147,47.57Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M149.28,48.93l1.41-.06v2.59a1.62,1.62,0,0,0,.08.54.74.74,0,0,0,.27.31.82.82,0,0,0,.42.11,1.74,1.74,0,0,0,.68-.17,3.08,3.08,0,0,0,.75-.52V49.86a1.17,1.17,0,0,0,0-.39.29.29,0,0,0-.15-.14.67.67,0,0,0-.32-.06h-.27v-.34l1.4-.06v3a1.1,1.1,0,0,0,.05.39.27.27,0,0,0,.13.16.85.85,0,0,0,.35,0h.12v.33l-1.24.07v-.74a3.82,3.82,0,0,1-.88.6,2,2,0,0,1-.77.17,1.56,1.56,0,0,1-.51-.08,1,1,0,0,1-.37-.26,1.09,1.09,0,0,1-.25-.4,1.44,1.44,0,0,1-.08-.51V49.83a.92.92,0,0,0-.05-.35.31.31,0,0,0-.13-.15.48.48,0,0,0-.26-.06h-.34Zm3.57-1.36V48H150.6v-.41Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M155.24,49.51v-.34a2.16,2.16,0,0,0,.48-.09,2.67,2.67,0,0,0,.48-.24h.28v.75a2.12,2.12,0,0,1,.61-.55,1.21,1.21,0,0,1,.63-.2.6.6,0,0,1,.43.14.41.41,0,0,1,.16.34.36.36,0,0,1-.11.25.31.31,0,0,1-.25.11.25.25,0,0,1-.12,0,.51.51,0,0,1-.14-.09.66.66,0,0,0-.34-.14.74.74,0,0,0-.41.17,2.23,2.23,0,0,0-.44.42v1.85a1.21,1.21,0,0,0,0,.33.28.28,0,0,0,.08.15.3.3,0,0,0,.13.09,1,1,0,0,0,.29,0h.12v.33h-1.92v-.33h.16a.64.64,0,0,0,.3,0,.36.36,0,0,0,.14-.16,1.1,1.1,0,0,0,0-.39V49.51Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M161.84,50.48V50a.76.76,0,0,0-.23-.63,1,1,0,0,0-.65-.19,1.25,1.25,0,0,0-.36.05.66.66,0,0,0-.25.15.49.49,0,0,0-.14.21,1.67,1.67,0,0,0-.09.35c-.06.24-.17.36-.35.36a.3.3,0,0,1-.24-.11.44.44,0,0,1-.09-.29.91.91,0,0,1,.18-.52,1.31,1.31,0,0,1,.56-.42,2.16,2.16,0,0,1,.88-.16,2,2,0,0,1,.81.15,1.07,1.07,0,0,1,.5.46,1.63,1.63,0,0,1,.11.74v1.55a3.4,3.4,0,0,0,0,.43.5.5,0,0,0,.14.25.4.4,0,0,0,.27.09,1.46,1.46,0,0,0,.41-.08v.35a2,2,0,0,1-.56.09,1.15,1.15,0,0,1-.44-.08.66.66,0,0,1-.29-.23.81.81,0,0,1-.15-.37,3.35,3.35,0,0,1-.81.53,1.87,1.87,0,0,1-.73.15,1.16,1.16,0,0,1-.83-.3,1,1,0,0,1-.32-.7,1,1,0,0,1,.12-.47,1.11,1.11,0,0,1,.36-.39,2.51,2.51,0,0,1,.75-.3A13.18,13.18,0,0,1,161.84,50.48Zm0,.35c-.57.09-.93.15-1.06.19a1.79,1.79,0,0,0-.58.22.76.76,0,0,0-.25.26.6.6,0,0,0-.08.29.59.59,0,0,0,.09.31.63.63,0,0,0,.23.23.72.72,0,0,0,.32.08,1.48,1.48,0,0,0,.55-.12,4.39,4.39,0,0,0,.78-.49Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M166.68,48.93h.26l.34,1.23-.34.12a1.83,1.83,0,0,0-.56-.78,1.24,1.24,0,0,0-.73-.28.72.72,0,0,0-.47.15.45.45,0,0,0-.18.35.48.48,0,0,0,.13.32,1.76,1.76,0,0,0,.57.3l.72.28a2.09,2.09,0,0,1,.84.51.92.92,0,0,1,.22.62,1.16,1.16,0,0,1-.16.56,1.19,1.19,0,0,1-.48.43,1.46,1.46,0,0,1-.71.16,1.59,1.59,0,0,1-1.07-.39l-.08.29h-.26l-.46-1.31.33-.14a3.19,3.19,0,0,0,.64.82,1.45,1.45,0,0,0,.91.34.8.8,0,0,0,.53-.19.54.54,0,0,0,.21-.41.56.56,0,0,0-.13-.35,1.41,1.41,0,0,0-.51-.3l-.86-.33a2,2,0,0,1-.77-.48.92.92,0,0,1-.22-.59.9.9,0,0,1,.36-.71,1.27,1.27,0,0,1,.89-.31,1.42,1.42,0,0,1,.94.33Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M174.24,48.93h.26l.33,1.23-.33.12a1.83,1.83,0,0,0-.56-.78,1.24,1.24,0,0,0-.74-.28.69.69,0,0,0-.46.15.45.45,0,0,0-.18.35.44.44,0,0,0,.13.32,1.56,1.56,0,0,0,.57.3l.72.28a2.09,2.09,0,0,1,.84.51,1,1,0,0,1,.22.62,1.07,1.07,0,0,1-.17.56,1.16,1.16,0,0,1-.47.43,1.49,1.49,0,0,1-.71.16,1.57,1.57,0,0,1-1.07-.39l-.08.29h-.26l-.47-1.31.34-.14a3,3,0,0,0,.64.82,1.45,1.45,0,0,0,.91.34.8.8,0,0,0,.53-.19.54.54,0,0,0,.21-.41.56.56,0,0,0-.13-.35,1.38,1.38,0,0,0-.52-.3l-.85-.33a2,2,0,0,1-.77-.48.87.87,0,0,1-.22-.59.92.92,0,0,1,.35-.71,1.3,1.3,0,0,1,.9-.31,1.35,1.35,0,0,1,.5.08,1.31,1.31,0,0,1,.44.25Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M176.21,47.59v-.33a2.35,2.35,0,0,0,1-.34h.29v4l1.36-1.17c.22-.19.33-.31.33-.37a.09.09,0,0,0-.07-.09.82.82,0,0,0-.22,0h-.2v-.34h1.89v.34a1.46,1.46,0,0,0-.51.07,2.09,2.09,0,0,0-.44.27l-1.07.9L179.82,52a1.79,1.79,0,0,0,.3.31.6.6,0,0,0,.22.12,2.28,2.28,0,0,0,.37,0v.33h-2v-.33h.15a.77.77,0,0,0,.29,0,.12.12,0,0,0,.06-.1s0-.11-.12-.19l0,0-1-1.22-.61.5v.52a1.09,1.09,0,0,0,0,.39.25.25,0,0,0,.13.14.77.77,0,0,0,.33,0h.14v.33h-1.91v-.33a2.13,2.13,0,0,0,.4,0,.29.29,0,0,0,.18-.16,1,1,0,0,0,.06-.43V47.59Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M183.44,48.84a1.81,1.81,0,0,1,.95.24,1.75,1.75,0,0,1,.63.71,2.48,2.48,0,0,1,.23,1.08,2.34,2.34,0,0,1-.24,1.07,1.7,1.7,0,0,1-.64.71,1.8,1.8,0,0,1-1,.25,1.56,1.56,0,0,1-1.35-.61,2.29,2.29,0,0,1-.45-1.42,2.39,2.39,0,0,1,.23-1.06,1.77,1.77,0,0,1,.66-.72A1.8,1.8,0,0,1,183.44,48.84Zm0,.39a.88.88,0,0,0-.71.36,2,2,0,0,0-.37,1.3,2.09,2.09,0,0,0,.31,1.26,1,1,0,0,0,1.34.21,1.24,1.24,0,0,0,.4-.58,2.59,2.59,0,0,0,.15-.93,2.52,2.52,0,0,0-.16-.93,1.11,1.11,0,0,0-.38-.51A1,1,0,0,0,183.41,49.23Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M186.37,47.59v-.33a2.21,2.21,0,0,0,1-.34h.27v4.94a1,1,0,0,0,0,.39.34.34,0,0,0,.14.17.91.91,0,0,0,.36,0h.11v.33h-1.91v-.33h.19a.51.51,0,0,0,.28-.06.37.37,0,0,0,.13-.17,1.59,1.59,0,0,0,0-.41V47.59Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M192,50.48V50a.79.79,0,0,0-.23-.63,1,1,0,0,0-.65-.19,1.25,1.25,0,0,0-.36.05.81.81,0,0,0-.26.15.6.6,0,0,0-.14.21l-.09.35c-.05.24-.17.36-.34.36a.3.3,0,0,1-.24-.11.44.44,0,0,1-.09-.29.91.91,0,0,1,.18-.52,1.31,1.31,0,0,1,.56-.42,2.16,2.16,0,0,1,.88-.16A2,2,0,0,1,192,49a1.07,1.07,0,0,1,.5.46,1.78,1.78,0,0,1,.11.74v1.55a3.4,3.4,0,0,0,0,.43.44.44,0,0,0,.14.25.4.4,0,0,0,.27.09,1.46,1.46,0,0,0,.41-.08v.35a2,2,0,0,1-.56.09,1.15,1.15,0,0,1-.44-.08.76.76,0,0,1-.3-.23,1.11,1.11,0,0,1-.15-.37,3.14,3.14,0,0,1-.8.53,1.87,1.87,0,0,1-.73.15,1.16,1.16,0,0,1-.83-.3,1,1,0,0,1-.32-.7,1,1,0,0,1,.12-.47,1.08,1.08,0,0,1,.35-.39,2.47,2.47,0,0,1,.76-.3A12.77,12.77,0,0,1,192,50.48Zm0,.35c-.58.09-.93.15-1.07.19a2,2,0,0,0-.58.22.87.87,0,0,0-.25.26.59.59,0,0,0-.07.29.68.68,0,0,0,.08.31.76.76,0,0,0,.24.23.72.72,0,0,0,.32.08,1.52,1.52,0,0,0,.55-.12,4.39,4.39,0,0,0,.78-.49Z"
                    transform="translate(-4.02 -0.92)"
                />
            </svg>
        </div>
    );
};
export default LogoLv;
