import React from "react";
import { Switch, Route } from "react-router-dom";
import AppLayout from "./components/AppLayout/AppLayout";
import AppRoute from "./components/AppRoute/AppRoute";
import { useTranslation } from "react-i18next";
import Home from "./pages/Home/Home";
import Erwachsene from "./pages/Erwachsene/Erwachsene";
import Ubersetzung from "./pages/Ubersetzung/Ubersetzung";
import Kontakt from "./pages/Kontakt/Kontakt";
import Downloads from "./pages/Downloads/Downloads";
import Links from "./pages/Links/Links";
import Kinder from "./pages/Kinder/Kinder";
import Geneve from "./pages/Geneve/Geneve";
import Shop from "./pages/Shop/Shop";
import Aktuell from "./pages/Aktuell/Aktuell";
import Fotos from "./pages/Fotos/Fotos";

function App() {
    const { t } = useTranslation();
    return (
        <Switch>
            <AppRoute
                exact
                path="/erwachsene"
                component={Erwachsene}
                layout={AppLayout}
                page={Erwachsene}
                title={`${t("page-erwachsene.title-erwachsene")}`}
            />
            <AppRoute
                exact
                path="/ubersetzung"
                component={Ubersetzung}
                layout={AppLayout}
                page={Ubersetzung}
                title={`${t("page-ubersetzung.title-ubersetzung")}`}
            />
            <AppRoute
                exact
                path="/kontakt"
                component={Kontakt}
                layout={AppLayout}
                page={Kontakt}
                title={`${t("page-kontakt.title-kontakt")}`}
            />
            <AppRoute
                exact
                path="/downloads"
                component={Downloads}
                layout={AppLayout}
                page={Downloads}
                title={`${t("page-downloads.title-downloads")}`}
            />
            <AppRoute
                exact
                path="/links"
                component={Links}
                layout={AppLayout}
                page={Links}
                title={`${t("page-links.title-links")}`}
            />
            <AppRoute
                exact
                path="/kinder"
                component={Kinder}
                layout={AppLayout}
                page={Kinder}
                title={`${t("page-kinder.title-kinder")}`}
            />
            <AppRoute
                exact
                path="/geneve"
                component={Geneve}
                layout={AppLayout}
                page={Geneve}
                title={`${t("page-geneve.title-geneve")}`}
            />
            <AppRoute
                exact
                path="/shop"
                component={Shop}
                layout={AppLayout}
                page={Shop}
                title={`${t("page-shop.title-shop")}`}
            />
            <AppRoute
                exact
                path="/aktuell"
                component={Aktuell}
                layout={AppLayout}
                page={Aktuell}
                title={`${t("page-aktuell.title-aktuell")}`}
            />
            <AppRoute
                exact
                path="/fotos"
                component={Fotos}
                layout={AppLayout}
                page={Fotos}
                title={`${t("page-fotos.title-fotos")}`}
            />
            <Route
                path="/bibliothek"
                component={() => {
                    window.location.href = "https://www.auseklis.ch/lbib_.php";
                    return null;
                }}
            />
            <AppRoute
                component={Home}
                layout={AppLayout}
                page={Home}
                title={`${t("page-home.title-home")}`}
            />
        </Switch>
    );
}

export default App;
