import React from "react";
import { Route } from "react-router-dom";

const AppRoute = ({
    component: Component,
    layout: Layout,
    page: Page,
    title: Title,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props) => (
            <Layout title={Title}>
                <Component {...props}>
                    <Page />
                </Component>
            </Layout>
        )}
    />
);
export default AppRoute;
