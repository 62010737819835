import React, { useEffect } from "react";
import "./Erwachsene.scss";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function Erwachsene() {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={"page-erwachsene"}>
            <div className={"erwachsene-container"}>
                <div className={"erwachsene-content"}>
                    <h1 className={"title"}>
                        {t("page-erwachsene.title-erwachsene")}
                    </h1>
                    <p>
                        <Trans i18nKey="page-erwachsene.p1" />
                    </p>
                    <p>
                        <Trans i18nKey="page-erwachsene.p2" />
                        <a
                            href={"mailto:ilscho@vtxnet.ch"}
                            title={"ilscho@vtxnet.ch"}
                        >
                            Email
                        </a>
                        .
                    </p>
                    <p>
                        <Trans i18nKey="page-erwachsene.p3" />
                    </p>
                    <ul>
                        <li>
                            <Trans i18nKey="page-erwachsene.li3" />
                        </li>
                        <li>
                            <Trans i18nKey="page-erwachsene.li4" />
                        </li>
                        <li>
                            <Trans i18nKey="page-erwachsene.li6">
                                <strong>Kulturkurse</strong> an: Ihre Fragen zu
                                <Link to="/">Land</Link> und
                                <Link to="/">Leben</Link> werden nicht
                                unbeantwortet bleiben.
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="page-erwachsene.li7">
                                <strong>Unverbindliche Anfragen oder Anmeldung</strong> bitte per
                                <a
                                    href={
                                        "mailto:ilscho@vtxnet.ch?subject=Auseklis"
                                    }
                                >
                                    E-Mail
                                </a>
                                direkt an uns.
                            </Trans>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
