import React from "react";
import "./Header.scss";
import LogoChanger from "../LogoChanger/LogoChanger";
import Menu from "../Menu/Menu";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { useTranslation } from "react-i18next";

export default function Header() {
    const { t } = useTranslation();

    return (
        <header className={"app-header"}>
            <div className={"header-content"}>
                <div className={"logo"}>
                    <LogoChanger />
                </div>
                <div className={"slogan"}>
                    <h1>{t("header-slogan")}</h1>
                </div>
                <LanguageSelector />
            </div>
            <Menu />
        </header>
    );
}
