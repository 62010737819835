import React, { useEffect } from "react";
import "./Downloads.scss";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function Downloads() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={"page-downloads"}>
            <div className={"downloads-container"}>
                <div className={"downloads-content"}>
                    <h1 className={"title"}>
                        {t("page-downloads.title-downloads")}
                    </h1>
                    {i18n.language === "de" ? (
                        <p>
                            <Trans i18nKey="page-downloads.p1">
                                Als,
                                <strong>
                                    Einstieg in die lettische Sprache
                                </strong>
                                finden Sie hier
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/Lettischkurs%20Castagnola%2016.Okt%202009%20Auseklis.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    (Download)
                                </a>
                                eine wissenschaftliche Einführung in einer
                                PDF-Datei. Sie enthält auch eine gute
                                <strong>Bibliografie</strong>. Aufgepasst: der
                                Schwerpunkt dieser Einführung liegt auf der
                                alten Sprache der Volkslieder. Ganz so schwierig
                                ist es mit der Umgangssprache nicht.
                            </Trans>
                        </p>
                    ) : (
                        ""
                    )}
                    {i18n.language === "de" ? (
                        <p>
                            <Trans i18nKey="page-downloads.p2">
                                Lettland, hat
                                <strong>
                                    hervorragende Dichter und Schriftsteller
                                </strong>
                                . Übersetzungen sind nicht immer leicht zu
                                finden. Eine Liste von lettischer Literatur in
                                deutscher und französischer Sprache finden Sie
                                hier
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/Biblio%20Latvija.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    (Download)
                                </a>
                                .
                            </Trans>
                        </p>
                    ) : (
                        ""
                    )}
                    {i18n.language === "de" ? (
                        <p>
                            <Trans i18nKey="page-downloads.p3">
                                In, Riga existiert ein grosses Kinostudio. Heute
                                werden noch regelmässig Filme produziert. In
                                diesem Formular finden Sie eine
                                <strong>
                                    Liste klassischer Filmwerke aus Lettland
                                </strong>
                                . Auseklis organisiert Filmvorführungen: dieses
                                Formular
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/Filmu%20vakars,%20aptauja.doc"
                                    }
                                    target={"_blank"}
                                >
                                    (Download)
                                </a>
                                können Sie uns ausgefüllt zuschicken, damit wir
                                Ihre Präferenzen kennen lernen und Sie über
                                Filmvorführungen informieren können. Auseklis
                                dankt dem nationalen Kinozentrum Lettlands für
                                die Bereitstellung der Filme.
                            </Trans>
                        </p>
                    ) : (
                        ""
                    )}
                    {i18n.language === "fr" ? (
                        <p>
                            <Trans i18nKey="page-downloads.p1">
                                La Lettonie possède d'excellents
                                <strong>
                                    poètes et auteurs.
                                </strong>
                                Les traductions ne sont pas toujours faciles à trouver. Une liste de littérature lettone en langue allemande et française peut être
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/Lettischkurs%20Castagnola%2016.Okt%202009%20Auseklis.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    (téléchargée ici)
                                </a>
                            </Trans>
                        </p>
                    ) : (
                        ""
                    )}
                    <p>
                        <Trans i18nKey="page-downloads.p4">
                            Les <strong>Jrapports annuels</strong>strong>J d' Auseklis  sont téléchargeables ici:
                        </Trans>
                    </p>
                    <ul>
                        <li>
                            <Trans i18nKey="page-downloads.li1">
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/Gada%20atskaite%202016.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    Download
                                </a>
                                , Jahresbericht 2016 (lettische Version)
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="page-downloads.li2">
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/2015_Jahresbericht.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    Download
                                </a>
                                , Jahresbericht 2015 (deutsche Version)
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="page-downloads.li3">
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/2014_Auseklis_Jahresbericht.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    Download
                                </a>
                                , Jahresbericht 2014 (deutsche Version)
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="page-downloads.li4">
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/Auseklis%20Jahresbericht%202013.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    Download
                                </a>
                                , Jahresbericht 2013 (deutsche Version)
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="page-downloads.li5">
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/%20Jahresbericht%202012.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    Download
                                </a>
                                , Jahresbericht 2012 (deutsche Version)
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="page-downloads.li6">
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/2011%20Auseklis%20Jahresbericht.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    Download
                                </a>
                                , Jahresbericht 2011 (deutsche Version)
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="page-downloads.li7">
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/2010%20Auseklis%20Jahresbericht.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    Download
                                </a>
                                , Jahresbericht 2010 (deutsche Version)
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="page-downloads.li8">
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/Gada%20atskaite%2009.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    Download
                                </a>
                                , Jahresbericht 2009 (lettische Version)
                            </Trans>
                        </li>
                    </ul>
                    <p>
                        <Trans i18nKey="page-downloads.p5">
                            <strong>Auszug des Interviews</strong> mit Ilze
                            Schorderet aus der Zeitschrift Brīvā Latvija 6
                            (Jan./Feb. 2017) über ihre Arbeit als Sprachcoach in
                            lettischer Sprache mit der Schweizer Schauspielerin
                            Sabine Timoteo:
                            <a
                                href={
                                    "https://www.auseklis.ch/docs/Interview_Sprachcoaching_Auseklis_Melanies_Chronik.pdf"
                                }
                                target={"_blank"}
                            >
                                Download
                            </a>
                            .
                        </Trans>
                    </p>
                    <p>
                        <Trans i18nKey="page-downloads.p6">
                            <strong>Publikationen</strong> über Auseklis:
                        </Trans>
                    </p>
                    <ul>
                        <li>
                            <Trans i18nKey="page-downloads.li9">
                                I. Schorderet, "Cittautieši apgūst latviešu
                                valodu Šveicē", Latvians Online, Mai 2013 (nur
                                lettisch):
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/Ilze_Schorderet_Mai_2013pdf.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    Spiest šeit
                                </a>
                            </Trans>
                        </li>
                        <li>
                            <Trans i18nKey="page-downloads.li10">
                                Jolanta Derkevica-Pilskunga, "Par skolotājiem,
                                kas kopj un uztur mūsu latvietību svešumā",
                                Skolas vārds, 2013. g., 38. numurs (nur
                                lettisch):
                                <a
                                    href={
                                        "https://www.auseklis.ch/docs/2013%2011%2021%20Skolas_Vards_Nr_38_2013%20iss.pdf"
                                    }
                                    target={"_blank"}
                                >
                                    Spiest šeit
                                </a>
                            </Trans>
                        </li>
                        {i18n.language === "lv" ? (
                            <li>
                                <Trans i18nKey="page-downloads.li11_lv">
                                    Zane Pudule. Intervija ar Ilzi Šorderetu
                                    2020. gada 19. aprīlī.
                                    <a
                                        href={
                                            "https://www.lsm.lv/raksts/zinas/arzemes/gramatu-slepni-virtualie-novusa-turniri-pargerbsanas—latviesi-arzemes-radosi-uztur-optimismu.a356445/?fbclid=IwAR2Beh3lyO1yByMszeJUgWQqN0FaQKeCBPaupGrWbc8dfGR5r8Va2uovKoo"
                                        }
                                        target={"_blank"}
                                    >
                                        Spiest šeit
                                    </a>
                                </Trans>
                            </li>
                        ) : (
                            ""
                        )}
                        {i18n.language === "lv" ? (
                            <li>
                                <Trans i18nKey="page-downloads.li12_lv">
                                    Inga Ābele. Šveices aplis. Fragments. Visu
                                    Ingas Ābeles rakstu lasiet žurnālā
                                    „Domuzīme“ 2019. gada 3. Nr.
                                    <a
                                        href={
                                            "https://auseklis.ch/docs/sveices_aplis.pdf"
                                        }
                                        target={"_blank"}
                                    >
                                        Spiest šeit
                                    </a>
                                </Trans>
                            </li>
                        ) : (
                            ""
                        )}
                        {i18n.language === "lv" ? (
                            <li>
                                <Trans i18nKey="page-downloads.li13_lv">
                                    Ligita Kovtuna. Brīvā Latvija 6/2017
                                    „Latvietes Ilzes un šveicietes Sabīnes
                                    tikšanās Melānijas hronikā
                                    <a
                                        href={
                                            "https://www.brivalatvija.lv/aktuala-intervija/latvietes-ilzes-un-sveicietes-sabines-tiksanas-quotmelanijas-chronikaquot?pcversion=ok&gads=2017&pp=&page=2"
                                        }
                                        target={"_blank"}
                                    >
                                        Spiest šeit
                                    </a>
                                </Trans>
                            </li>
                        ) : (
                            ""
                        )}
                    </ul>
                </div>
            </div>
        </section>
    );
}
