import React, { useEffect } from "react";
import "./Ubersetzung.scss";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function Ubersetzung() {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={"page-ubersetzung"}>
            <div className={"ubersetzung-container"}>
                <div className={"ubersetzung-content"}>
                    <h1 className={"title"}>
                        {t("page-ubersetzung.title-ubersetzung")}
                    </h1>
                    <p>
                        <Trans i18nKey="page-ubersetzung.p1" />
                    </p>
                    <p>
                        <Trans i18nKey="page-ubersetzung.p2" />
                    </p>
                    <p>
                        <Trans i18nKey="page-ubersetzung.p3">
                            Fragen Sie uns unverbindlich für eine Offerte an:
                            <Link to={"/kontakt"}>Kontakt</Link>.
                        </Trans>
                    </p>
                </div>
            </div>
        </section>
    );
}
