import React, { useEffect } from "react";
import "./Geneve.scss";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function Geneve() {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={"page-geneve"}>
            <div className={"geneve-container"}>
                <div className={"geneve-content"}>
                    <h1 className={"title"}>{t("page-geneve.title-geneve")}</h1>
                    <p>
                        <Trans i18nKey="page-geneve.p1">
                            <a
                                href={
                                    "https://www.auseklis.ch/docs/zeneva2021_3-4.pdf.pdf"
                                }
                                target={"_blank"}
                            >
                                Spiest šeit (no 3 līdz 4 gadu vecumam)
                            </a>
                            <a
                                href={
                                    "https://www.auseklis.ch/docs/zeneva2021_5-12.pdf.pdf"
                                }
                                target={"_blank"}
                            >
                                Spiest šeit (no 5 līdz 12 gadu vecumam)
                            </a>
                        </Trans>
                    </p>
                </div>
            </div>
        </section>
    );
}
