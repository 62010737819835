import React, { useEffect } from "react";
import "./Home.scss";
import Card from "../../components/Card/Card";
import { useTranslation, Trans } from "react-i18next";

export default function Home() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={"page-home"}>
            <div className={"home-layout"}>
                <div className={"home-text-container"}>
                    <h1 className={"title"}>
                        {t("page-home.home-main-title")}
                    </h1>
                    <p>
                        <Trans i18nKey="page-home.p1" />
                    </p>
                    <p>
                        <Trans i18nKey="page-home.p2" />
                    </p>
                    <p>
                        <Trans i18nKey="page-home.p3" />
                    </p>
                    <p>
                        <Trans i18nKey="page-home.p4" />
                    </p>
                    {i18n.language === "lv" ? (
                        <>
                            <p>
                                <Trans i18nKey="page-home.poem" />
                            </p>
                        </>
                    ) : ("")}
                    <h1 className={"title"}>
                        <Trans i18nKey="page-home.cv-title" />
                    </h1>
                    <p>
                        <Trans i18nKey="page-home.cv">
                            <a
                                href={"https://www.biblioteka.ch"}
                                title={"www.biblioteka.ch"}
                            >
                                www.biblioteka.ch
                            </a>
                            <a
                                href={"https://www.edubs.ch/unterstuetzung/sprachen/hsk/Anmeldung"}
                                title={"Anmeldung"}
                            >
                                Anmeldung
                            </a>
                        </Trans>
                    </p>
                </div>
                <div className={"home-card-container"}>
                    <Card />
                </div>
            </div>
        </section>
    );
}
