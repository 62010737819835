import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import translationDE from "./assets/locales/de/translation.json";
import translationFR from "./assets/locales/fr/translation.json";
import translationLV from "./assets/locales/lv/translation.json";

// the translations
const resources = {
  de: {
    translation: translationDE,
  },
  fr: {
    translation: translationFR,
  },
  lv: {
    translation: translationLV,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "de",
    fallbackLng: "de", // use de if detected lng is not available
    whitelist: ["de", "fr", "lv"],

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
