import React from "react";
import "./Menu.scss";
import "./MobileMenu.scss";
import i18next from "i18next";
import { Link } from "react-router-dom";

export default function Menu() {
    const MenuItems = i18next.t("menu-items", { returnObjects: true });

    const toggleMobileMenu = () => {
        console.log("hello");
        document.getElementById("toggleCheckbox").checked = false;
    };

    return (
        <div className={"header-menu"}>
            <nav className={"header-menu-content"}>
                <ul className={"header-menu-list"}>
                    {MenuItems.map((menu) => (
                        <li key={menu.link}>
                            <Link to={menu.link} title={menu.name}>
                                {menu.name}
                            </Link>
                        </li>
                    ))}
                </ul>
                <div id="MobileMenuToggle">
                    <input type="checkbox" id={"toggleCheckbox"} />
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul id="menu">
                        {MenuItems.map((menu) => (
                            <li key={menu.link}>
                                <Link
                                    to={menu.link}
                                    title={menu.name}
                                    onClick={toggleMobileMenu}
                                >
                                    {menu.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
        </div>
    );
}
