import React from "react";
import "./Links.scss";
import "antd/dist/antd.css";
import { List, Avatar } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function Links() {
    const { t } = useTranslation();
    const data = [
        {
            title: "Māci un mācies latviešu valodu",
            link: "https://maciunmacies.valoda.lv/",
            avatarImage: false,
            avatar: "M",
            description:
                "Māci un mācies latviešu valodu! Interesanti mācību materiāli, video klipi un cita nozīmīga informācija latviešu valodas apguvei.",
        },
        {
            title: "Biblioteka.ch",
            link: "https://www.biblioteka.ch",
            avatarImage: true,
            avatar: "https://www.auseklis.ch/lbib_Bibliotekas_logo.png",
            description:
                "Latviešu bibliotēka Šveicē – Lettische Bibliothek Schweiz",
        },
        {
            title: "Literatura.ch",
            link: "https://www.literatura.ch/",
            avatarImage: false,
            avatar: "L",
            description: "Literatūras vakari",
        },
        {
            title: "Gesellschaft Schweiz-Lettland",
            link: "http://www.schweiz-lettland.ch/",
            avatarImage: false,
            avatar: "G",
            description:
                "Die Gesellschaft Schweiz-Lettland (GSL) bezweckt die Förderung und die Pflege der Beziehungen zwischen natürlichen Personen, juristischen Personen sowie privaten und öffentlichen Institutionen Lettlands und der Schweiz.",
        },
    ];

    return (
        <section className={"page-links"}>
            <div className={"links-container"}>
                <div className={"links-content"}>
                    <h1 className={"title"}>{t("page-links.title-links")}</h1>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        item.avatarImage ? (
                                            <Avatar src={item.avatar} />
                                        ) : (
                                            <Avatar>{item.avatar}</Avatar>
                                        )
                                    }
                                    title={
                                        <a href={item.link} target="_blank">
                                            {item.title}
                                        </a>
                                    }
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        </section>
    );
}
