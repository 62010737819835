import React, { useEffect } from "react";
import "./Fotos.scss";
import { useTranslation, Trans } from "react-i18next";
import ImageGallery from "react-image-gallery";
import "../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss"

export default function Fotos() {
    const { t } = useTranslation();
    const importAll = (r) => {
        return r.keys().map(r);
    };
    const listOfImages = importAll(require.context('../../assets/pics/', false, /\.(png|jpe?g|svg)$/));

    const images = [];

    listOfImages.map(
            (image) => (
                images.push(
                    {
                        original: image.default,
                        thumbnail: image.default
                    }
                )
            )
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={"page-fotos"}>
            <div className={"fotos-container"}>
                <div className={"fotos-content"}>
                    <h1 className={"title"}>{t("page-fotos.title-fotos")}</h1>
                    <div className={"gallery-container"}>
                        <ImageGallery items={images} autoPlay={true} />
                    </div>
                </div>
            </div>
        </section>
    );
}
