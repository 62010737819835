import React, { useEffect } from "react";
import "./Kinder.scss";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function Kinder() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={"page-kinder"}>
            <div className={"kinder-container"}>
                <div className={"kinder-content"}>
                    <h1 className={"title"}>{t("page-kinder.title-kinder")}</h1>
                    <p>
                        <Trans i18nKey="page-kinder.p1" />
                    </p>
                    {i18n.language === "lv" ? (
                        <>
                            <p>
                                <Trans i18nKey="page-kinder.autumn_title" />
                            </p>
							<ul>
								<li>
                                    <Trans i18nKey="page-kinder.li1_lv">
                                       <a
                                            href={
                                                "https://www.auseklis.ch/docs/Cirihe_bernudarzs_2024.pdf"
                                            }
                                            target={"_blank"}
                                        >
                                            Spiest šeit (no 3 līdz 5 gadu
                                            vecumam)
                                        </a>
                                        <a
                                            href={
                                                "https://www.auseklis.ch/docs/Cirihe_klases_2024.pdf"
                                            }
                                            target={"_blank"}
                                        >
                                            Spiest šeit (no 6 līdz 12 gadu
                                            vecumam)
                                        </a>
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="page-kinder.li3_lv">
                                        <a
                                            href={
                                                "https://www.edubs.ch/unterstuetzung/sprachen/hsk/Anmeldung/anmeldeformulare/downloads/HSKInfoLettisch2018.pdf"
                                            }
                                            target={"_blank"}
                                        >
                                            Spiest šeit
                                        </a>
                                    </Trans>
                                </li>
                                <li>
                                    {i18n.language === "lv" ? (
                                        <>
                                        <Trans i18nKey="page-kinder.price">
                                            <a
                                                href={
                                                    "https://www.auseklis.ch/docs/Skolas_Auseklis_cenu_politika.pdf"
                                                }
                                                target={"_blank"}
                                            >
                                                Mūsu skolas cenu politika
                                            </a>
                                        </Trans>
                                        </>
                                    ) : ("")}
                                </li>
							</ul>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </section>
    );
}
