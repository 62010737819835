import React, { useEffect } from "react";
import "./Kontakt.scss";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function Kontakt() {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={"page-kontakt"}>
            <div className={"kontakt-container"}>
                <div className={"kontakt-content"}>
                    <h1 className={"title"}>
                        {t("page-kontakt.title-kontakt")}
                    </h1>
                    <p>
                        <Trans i18nKey="page-kontakt.p1" />
                    </p>
                    <p>
                        <Trans i18nKey="page-kontakt.p2">
                            E-mail:
                            <a href={"mailto:ilscho@vtxnet.ch"}>
                                ilscho (at) vtxnet.ch
                            </a>
                            oder
                            <a href={"mailto:schorderet@inbox.lv"}>
                                schorderet (at) inbox.lv
                            </a>
                        </Trans>
                    </p>
                </div>
            </div>
        </section>
    );
}
