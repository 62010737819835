import React, { useEffect } from "react";
import "./Shop.scss";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import itemImage1 from "../../assets/images/shop/item-1.jpg";
import itemImage2 from "../../assets/images/shop/item-2.jpg";
import itemImage3 from "../../assets/images/shop/item-3.jpg";

export default function Shop() {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={"page-shop"}>
            <div className={"shop-container"}>
                <div className={"shop-content"}>
                    <h1 className={"title"}>{t("page-shop.title-shop")}</h1>
                    <p>
                        <Trans i18nKey="page-shop.p1" />
                    </p>
                    <ul>
                        <li>
                            <Trans i18nKey="page-shop.li1" />
                        </li>
                        <li>
                            <Trans i18nKey="page-shop.li2" />
                        </li>
                        <li>
                            <Trans i18nKey="page-shop.li3" />
                        </li>
                    </ul>
                    <p>
                        <Trans i18nKey="page-shop.p2" />
                    </p>
                    <h1 className={"title"}>{t("page-shop.item-1-title")}</h1>
                    <div className={"item-image"}>
                        <img
                            src={itemImage1}
                            alt={t("page-shop.item-1-title")}
                        />
                    </div>
                    <p>
                        <Trans i18nKey="page-shop.item-1-desc" />
                    </p>
                    <h1 className={"title"}>{t("page-shop.item-2-title")}</h1>
                    <div className={"item-image"}>
                        <img
                            src={itemImage2}
                            alt={t("page-shop.item-2-title")}
                        />
                    </div>
                    <p>
                        <Trans i18nKey="page-shop.item-2-desc" />
                    </p>
                    <h1 className={"title"}>{t("page-shop.item-3-title")}</h1>
                    <div className={"item-image"}>
                        <img
                            src={itemImage3}
                            alt={t("page-shop.item-3-title")}
                        />
                    </div>
                    <p>
                        <Trans i18nKey="page-shop.item-3-desc" />
                    </p>
                    <p>
                        <Trans i18nKey="page-shop.item-3-order">
                            <strong>Bestellen:</strong> Handgebundene
                            Notizbüchlein aus Lettland in Leder,
                            <a
                                href={
                                    "mailto:ilscho@vtxnet.ch?subject=Bestellung%20lettische%20Buechlein%20in%20Leder"
                                }
                            >
                                hier klicken
                            </a>
                            (bitte geben Sie Ihre Adresse an, die gewünschte
                            Anzahl und die gewünschten Farben). Danke!
                        </Trans>
                    </p>
                </div>
            </div>
        </section>
    );
}
