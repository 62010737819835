import React from "react";

const Logo = () => {
    return (
        <div className="Logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.42 59.19">
                <path
                    d="M64.14,43.77a3.78,3.78,0,0,1-1.64-1.53,3.5,3.5,0,0,1-.34-1.35A9.73,9.73,0,0,1,63,37.53q.84-2.12,2.25-4.85c1-1.83,2-3.73,3.23-5.71-.89-.07-1.74-.11-2.54-.14s-1.83,0-3.09,0h-.69a4,4,0,0,1,1.91-2.34,6.65,6.65,0,0,1,3.07-.68,11.66,11.66,0,0,1,1.53.1l1.65.18q2.16-3.34,4.24-6.31c1.39-2,2.7-3.77,4-5.36s2.36-2.91,3.33-3.93c1.38-1.48,2.38-2.23,3-2.23a2.5,2.5,0,0,1,1.35.52A11.45,11.45,0,0,1,87.77,8a6,6,0,0,0-1.1,1.9Q86,11.46,85.26,13.8t-1.51,5.13Q83,21.72,82.4,24.6t-1,5.5a34.5,34.5,0,0,0-.36,4.62,8.24,8.24,0,0,0,.34,2.76,1.2,1.2,0,0,0,1.16.9c.66,0,1.48-.54,2.45-1.62a24.73,24.73,0,0,0,3.27-5.29l.77.88a19.13,19.13,0,0,1-3.46,6.27,6,6,0,0,1-4.53,2.46,3,3,0,0,1-2.92-1.93,13.06,13.06,0,0,1-.86-5.27c0-.71,0-1.51.12-2.41s.23-2,.43-3.34c-1.26-.22-2.37-.39-3.35-.51l-2.79-.38q-2.07,4-4,8.17T64.14,43.77ZM78.27,25.85c.38-1.82.81-3.67,1.31-5.55s1-3.66,1.59-5.38,1.11-3.26,1.66-4.62A21.39,21.39,0,0,0,80,13.5q-1.59,2.1-3.33,4.92T73.07,24.6c.86.18,1.72.39,2.58.6Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M90.35,41.12A2.74,2.74,0,0,1,88,39.66,6.18,6.18,0,0,1,87,36.3a14.53,14.53,0,0,1,.39-3.07,23.71,23.71,0,0,1,1-3.43A16,16,0,0,1,89.73,27c.44-.74.85-1.11,1.22-1.11a2.09,2.09,0,0,1,.92.35,4.37,4.37,0,0,1,1.1.85,16.92,16.92,0,0,0-1.14,2.28,27.67,27.67,0,0,0-1.18,3.3,11.62,11.62,0,0,0-.52,3.2c0,.12,0,.42.07.88a4.28,4.28,0,0,0,.32,1.3.91.91,0,0,0,.9.6A2.3,2.3,0,0,0,93,37.8a14.2,14.2,0,0,0,1.72-2.22c.59-.92,1.16-1.9,1.72-2.95s1.06-2.07,1.5-3.06.81-1.83,1.1-2.51a6.75,6.75,0,0,1,1,.23,4.19,4.19,0,0,1,1.09.49,1,1,0,0,1,.48.86,6.88,6.88,0,0,1-.41,1.13c-.27.67-.59,1.47-1,2.4S99.58,34,99.31,35a9.79,9.79,0,0,0-.41,2.58,2.51,2.51,0,0,0,.24,1.13.91.91,0,0,0,.92.49,3.93,3.93,0,0,0,2.15-.88,13.35,13.35,0,0,0,2.58-2.55,18.85,18.85,0,0,0,2.36-4l.64.56a18,18,0,0,1-2.44,4.5A13.42,13.42,0,0,1,102.12,40a6,6,0,0,1-3.35,1.13,2.53,2.53,0,0,1-1.87-.62,3.3,3.3,0,0,1-.84-1.63,11.38,11.38,0,0,1-.25-2v-.93c-.49.84-1,1.65-1.59,2.44a10.61,10.61,0,0,1-1.83,2A3.16,3.16,0,0,1,90.35,41.12Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M111.06,42.1a5.79,5.79,0,0,1-3.46-1,5.28,5.28,0,0,1-1.87-2.42,8.34,8.34,0,0,1-.56-3,5.09,5.09,0,0,1,.35-2.09,2.55,2.55,0,0,1,.86-1.09,5.2,5.2,0,0,1,1-.53,2,2,0,0,0,.67-.42,35,35,0,0,0,2.21-2.81,18.63,18.63,0,0,0,2-3.55v-.47a4.45,4.45,0,0,1,.58-2.62,1.68,1.68,0,0,1,1.35-.77.93.93,0,0,1,.75.28,1.23,1.23,0,0,1,.28.65,2.34,2.34,0,0,1-.1.65,3.51,3.51,0,0,0-.11,1,9.66,9.66,0,0,0,.62,3.18c.41,1.2.83,2.46,1.25,3.79a13.78,13.78,0,0,1,.62,4.08c0,.37,0,.71,0,1s-.08.61-.13.89a6.31,6.31,0,0,0,2.62-1.61,12.47,12.47,0,0,0,2.45-3.64l.51.42a10.43,10.43,0,0,1-2.36,4.25,6.58,6.58,0,0,1-3.61,2,5.23,5.23,0,0,1-2.25,2.83A6.84,6.84,0,0,1,111.06,42.1Zm-.3-2a2.92,2.92,0,0,0,1.59-.47,3.25,3.25,0,0,0,1.16-1.34,8.53,8.53,0,0,1-3.18-1.49,9.61,9.61,0,0,1-2.19-2.18c-.4.09-.6.51-.6,1.25a5.51,5.51,0,0,0,.81,2.88A2.65,2.65,0,0,0,110.76,40.06Zm3.09-3.21a3.22,3.22,0,0,0,.11-.76c0-.3,0-.52,0-.68a20.55,20.55,0,0,0-.51-4.13c-.32-1.36-.63-2.75-1-4.18-.51,1-1.12,2-1.8,3.11a19.93,19.93,0,0,1-1.94,2.7,8.57,8.57,0,0,0,2,2.37A8,8,0,0,0,113.85,36.85Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M126.1,41.77a5.47,5.47,0,0,1-4.23-1.6,5.67,5.67,0,0,1-1.49-4,11.3,11.3,0,0,1,1-4.34A17.93,17.93,0,0,1,124,27.5a14.74,14.74,0,0,1,3.61-3.27A7.32,7.32,0,0,1,131.51,23a3.94,3.94,0,0,1,2.13.63,2.71,2.71,0,0,1,1,2.48,6.16,6.16,0,0,1-.94,3.3,10.24,10.24,0,0,1-2.54,2.74,15.18,15.18,0,0,1-3.5,2,15.52,15.52,0,0,1-3.85,1,2.28,2.28,0,0,0-.06.39,6,6,0,0,0,0,.68q0,.23.06.81a3.9,3.9,0,0,0,.37,1.23,2.79,2.79,0,0,0,1,1.11,3.34,3.34,0,0,0,1.89.47,7.12,7.12,0,0,0,3.64-1.09,15,15,0,0,0,3.43-2.9,17.56,17.56,0,0,0,2.69-4l.86.74a17.41,17.41,0,0,1-3,4.87,13.69,13.69,0,0,1-4,3.25A9.54,9.54,0,0,1,126.1,41.77Zm-1.81-8.4a7.78,7.78,0,0,0,2.5-.83,14.51,14.51,0,0,0,2.64-1.77,10.35,10.35,0,0,0,2.08-2.34,4.7,4.7,0,0,0,.82-2.58,1,1,0,0,0-.17-.69.72.72,0,0,0-.56-.19,3.5,3.5,0,0,0-2.06.77,11.41,11.41,0,0,0-2.11,2,17.1,17.1,0,0,0-1.85,2.72A18,18,0,0,0,124.29,33.37Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M149.39,41.5a3.27,3.27,0,0,1-2.32-.93,6.93,6.93,0,0,1-1.59-2.32,14.6,14.6,0,0,1-.9-2.86,12.82,12.82,0,0,1-.3-2.53q.6-.13,1.5-.42a11.3,11.3,0,0,0,1.85-.76,5.34,5.34,0,0,0,1.57-1.21,2.59,2.59,0,0,0,.62-1.74,1.9,1.9,0,0,0-.49-1.35,1.87,1.87,0,0,0-1.4-.51,3.63,3.63,0,0,0-2.28.86,9.66,9.66,0,0,0-2,2.25A23.54,23.54,0,0,0,142,33c-.5,1.08-.95,2.14-1.34,3.16s-.7,1.9-1,2.64a6.15,6.15,0,0,1-.9,1.88,1.58,1.58,0,0,1-1.25.49,1.68,1.68,0,0,1-.71-.19,1.56,1.56,0,0,1-.69-.92,7.51,7.51,0,0,1-.28-2.37,30.16,30.16,0,0,1,.73-6.22,57.39,57.39,0,0,1,2-6.89q1.23-3.51,2.68-6.71a63.72,63.72,0,0,1,3-5.76,26.81,26.81,0,0,1,2.81-4c.88-1,1.6-1.48,2.17-1.48s.9.31,1.23,1a5.24,5.24,0,0,1,.49,2.44,11.08,11.08,0,0,1-.64,3.5,20.06,20.06,0,0,1-1.83,3.9,22.58,22.58,0,0,1-2.81,3.74,18.11,18.11,0,0,1-3.61,3,38.5,38.5,0,0,0-1.85,6,39.22,39.22,0,0,0-.82,5.22q.78-1.91,1.77-3.9a29.81,29.81,0,0,1,2.19-3.74,12.17,12.17,0,0,1,2.6-2.8,4.82,4.82,0,0,1,3-1.07,4.09,4.09,0,0,1,3,1.3,4.43,4.43,0,0,1,1.29,3.25,4.19,4.19,0,0,1-.69,2.41,6.22,6.22,0,0,1-1.69,1.7,8,8,0,0,1-2,1,7.55,7.55,0,0,1-1.77.38c.09.4.21.91.37,1.53a10.74,10.74,0,0,0,.62,1.81,4.8,4.8,0,0,0,1,1.48,1.91,1.91,0,0,0,1.42.61,3.34,3.34,0,0,0,2.21-1.07,14.4,14.4,0,0,0,2.24-2.83,25.34,25.34,0,0,0,1.95-3.9l.6.79a20.86,20.86,0,0,1-2,4.45,13.46,13.46,0,0,1-2.81,3.46A4.88,4.88,0,0,1,149.39,41.5ZM143.24,21a26.43,26.43,0,0,0,2.45-2.77,20.07,20.07,0,0,0,2.22-3.52,8.81,8.81,0,0,0,1-3.6V11a5,5,0,0,0-1.35,1.6,38.52,38.52,0,0,0-2,3.6C144.73,17.6,144,19.21,143.24,21Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M159.32,41.12a2.92,2.92,0,0,1-2.82-1.46,7.13,7.13,0,0,1-.79-3.46,22,22,0,0,1,.77-5.33,61.26,61.26,0,0,1,2-6.43c.85-2.25,1.78-4.44,2.79-6.6s2-4.09,3.08-5.82a31.88,31.88,0,0,1,2.85-4.16c.88-1,1.59-1.55,2.13-1.55a1.13,1.13,0,0,1,.95.62,6.18,6.18,0,0,1,.62,1.49,6,6,0,0,1,.24,1.6,12.67,12.67,0,0,1-.58,3.27A37.13,37.13,0,0,1,169,17.73a50.84,50.84,0,0,1-2.49,4.94,42.58,42.58,0,0,1-3.18,4.81,25.09,25.09,0,0,1-3.72,3.94c-.11.78-.22,1.54-.32,2.3a16,16,0,0,0-.15,2.07,4.39,4.39,0,0,0,.52,2.43A1.54,1.54,0,0,0,161,39a3.33,3.33,0,0,0,2.21-1.09,14.26,14.26,0,0,0,2.19-2.79,27.12,27.12,0,0,0,1.83-3.5l1,.6a22.81,22.81,0,0,1-4.13,6.52A6.56,6.56,0,0,1,159.32,41.12Zm1-12.58a37.6,37.6,0,0,0,2.77-3.73q1.31-2,2.41-4.11c.73-1.39,1.36-2.74,1.91-4a36.21,36.21,0,0,0,1.27-3.51,11.32,11.32,0,0,0,.49-2.48v-.14c-.23,0-.6.35-1.1,1.05a32.47,32.47,0,0,0-1.78,2.85q-1,1.82-2.13,4.13t-2.1,4.9Q161.07,26,160.35,28.54Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M169.58,41.12a3.38,3.38,0,0,1-2.83-1.2A4.62,4.62,0,0,1,165.8,37a13.47,13.47,0,0,1,.35-2.69,29.16,29.16,0,0,1,1-3.41,27.76,27.76,0,0,1,1.44-3.37A11.31,11.31,0,0,1,170.32,25a2.66,2.66,0,0,1,1.89-1,1.28,1.28,0,0,1,.9.35,1.25,1.25,0,0,1,.39,1,4.35,4.35,0,0,1-.67,1.81c-.45.81-1,1.74-1.55,2.79a27.12,27.12,0,0,0-1.54,3.27,8.73,8.73,0,0,0-.67,3.23,2.82,2.82,0,0,0,.49,2,2,2,0,0,0,1.44.49,4.92,4.92,0,0,0,3.18-1.58,17.92,17.92,0,0,0,3.57-5.52l.64.74a17,17,0,0,1-3.82,6.36A7,7,0,0,1,169.58,41.12Zm5.08-20.93a2.68,2.68,0,0,1-1.49-.44,1.54,1.54,0,0,1-.66-1.37,2.3,2.3,0,0,1,1.11-1.91,4,4,0,0,1,2.32-.78,2.25,2.25,0,0,1,1.32.37,1.52,1.52,0,0,1,.53,1.34,2.57,2.57,0,0,1-1,1.88A3.09,3.09,0,0,1,174.66,20.19Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M181.66,42.1a5.79,5.79,0,0,1-3.46-1,5.28,5.28,0,0,1-1.87-2.42,8.34,8.34,0,0,1-.56-3,5.09,5.09,0,0,1,.35-2.09,2.55,2.55,0,0,1,.86-1.09,5.2,5.2,0,0,1,1-.53,2.15,2.15,0,0,0,.67-.42,35,35,0,0,0,2.21-2.81,18.63,18.63,0,0,0,2-3.55v-.47a4.45,4.45,0,0,1,.58-2.62,1.66,1.66,0,0,1,1.35-.77,1,1,0,0,1,.75.28,1.23,1.23,0,0,1,.28.65,2.06,2.06,0,0,1-.11.65,4,4,0,0,0-.1,1,9.66,9.66,0,0,0,.62,3.18c.41,1.2.83,2.46,1.25,3.79A13.78,13.78,0,0,1,188,35c0,.37,0,.71,0,1s-.08.61-.13.89a6.31,6.31,0,0,0,2.62-1.61,12.47,12.47,0,0,0,2.45-3.64l.51.42a10.43,10.43,0,0,1-2.36,4.25,6.58,6.58,0,0,1-3.61,2,5.2,5.2,0,0,1-2.26,2.83A6.78,6.78,0,0,1,181.66,42.1Zm-.3-2a2.92,2.92,0,0,0,1.59-.47,3.25,3.25,0,0,0,1.16-1.34,8.46,8.46,0,0,1-3.18-1.49,9.61,9.61,0,0,1-2.19-2.18c-.4.09-.6.51-.6,1.25a5.51,5.51,0,0,0,.81,2.88A2.65,2.65,0,0,0,181.36,40.06Zm3.09-3.21a3.84,3.84,0,0,0,.11-.76c0-.3,0-.52,0-.68a20.55,20.55,0,0,0-.51-4.13c-.32-1.36-.63-2.75-.95-4.18-.52,1-1.12,2-1.8,3.11a19.93,19.93,0,0,1-1.94,2.7,8.57,8.57,0,0,0,2,2.37A8,8,0,0,0,184.45,36.85Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M4,20.65H22.28l7.31,7.89H11.32L4,20.65Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M4,40.38l7.3-7.89H29.59l-7.31,7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,32.49H51.5l7.31,7.89H40.55l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M40.55.92V20.65l-7.31,7.89V8.81L40.55.92Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M29.59,32.49V52.22l-7.31,7.89V40.38l7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,28.54l7.31-7.89H58.81L51.5,28.54Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M22.28.92l7.31,7.89V28.54l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
                <path
                    d="M33.24,32.49l7.31,7.89V60.11l-7.31-7.89Z"
                    transform="translate(-4.02 -0.92)"
                />
            </svg>
        </div>
    );
};
export default Logo;
