import React from "react";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import LogoLv from "../Logos/LogoLv";
import LogoDe from "../Logos/LogoDe";
import LogoFr from "../Logos/LogoFr";
import FacebookLogo from "../../assets/images/icon-facebook.png";

export default function Footer() {
    const { i18n } = useTranslation();

    const currentLanguage = (lang) => {
        switch (lang) {
            case "de":
                return <LogoDe />;
            case "fr":
                return <LogoFr />;
            case "lv":
                return <LogoLv />;
            default:
                return <LogoDe />;
        }
    };

    return (
        <footer>
            <div className={"footer-container"}>
                <div className={"austraskoks"}></div>
                <div className={"footer-divider"}>&nbsp;</div>
                <div className={"footer-content"}>
                    <div className={"footer-logo"}>
                        {currentLanguage(i18n.language)}
                    </div>
                    <div className={"footer-text"}>
                        <p>
                            <a href={"mailto:ilscho@vtxnet.ch"}>
                                ilscho@vtxnet.ch
                            </a>
                            <a href={"mailto:schorderet@inbox.lv"}>
                                schorderet@inbox.lv
                            </a>
                            078 648 86 37
                            <a
                                href={
                                    "https://www.facebook.com/Auseklis-Latvian-language-school-Sprachkurse-lettische-Sprache-Kultur-108158179203743/"
                                }
                                target={"_blank"}
                                rel="noreferrer"
                                className={"facebook-link"}
                            >
                                <img
                                    src={FacebookLogo}
                                    alt={"Visit our Facebook page"}
                                />
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
