import React from "react";
import useWindowDimensions from "../../Hooks/UseWindowDimensions";
import Logo from "../Logos/Logo";
import LogoLv from "../Logos/LogoLv";
import LogoDe from "../Logos/LogoDe";
import LogoFr from "../Logos/LogoFr";
import { useTranslation } from "react-i18next";

export default function LogoChanger() {
    const { height, width } = useWindowDimensions();
    const { i18n } = useTranslation();

    const currentLogo = (windowWidth) => {
        let currentLanguage = i18n.language;

        if (width > 850) {
            return <Logo />;
        } else {
            switch (currentLanguage) {
                case "de":
                    return <LogoDe />;
                case "fr":
                    return <LogoFr />;
                case "lv":
                    return <LogoLv />;
                default:
                    return <LogoDe />;
            }
        }
    };

    return <div>{currentLogo()}</div>;
}
