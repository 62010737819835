import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./AppLayout.scss";
import ImageSlider from "../ImageSlider/ImageSlider";

export default function AppLayout({ children, title }) {
    return (
        <div className={"app-layout"}>
            <Header />
            <ImageSlider image={"random"} title={title} />
            <div className={"content-container"}>
                <div className={"content-content"}>{children}</div>
            </div>
            <Footer />
        </div>
    );
}
